import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Upload, Icon, Modal } from 'antd';
import { notification } from 'antd';


const ImageUploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
`;
  
const ImagePreviewWrapper = styled.div`
  border-radius: 2px;
  position: relative;
  width: 112px;
  height: 112px;
  padding-left:5px;
  &:hover {
    .delete-btn {
      display: flex;
    }
  }
`;

const ImageThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #d5d3d3;
`;

const DeleteButton = styled.div`
  position: absolute;
  top: -10%;
  right: -10%;
  background: black;
  border-radius: 56%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 2px;

  .anticon {
    font-size: 13px;
  }
`;

const UploadButton = styled(Upload)`
  .ant-upload {
    width: 112px;
    height: 112px;
    border: 1px dashed #d9d9d9;
    display: flex;
    align-items: center; 
    justify-content: center;
    flex-direction: column;

    .ant-upload-text {
      text-align: center;
    }

    .anticon-plus {
      font-size: 13px; 
    }
  }
`;

const UploadAreaContainer = styled.div`
  border: 1px solid #d9d9d9;
  padding: 16px;
  border-radius: 4px;
  margin-top: 10px;
  background-color: #fff;
`;

const UploadButtonWrapper = styled.div`
  width: 112px;
  height: 112px;
  padding-left: 5px;
`;

const ImagePreviewModal = styled(Modal)`
  &&& {
    height: 100%;
    width: 70vw !important;

    @media (max-width: 768px) {
      width: 100vw !important;
    }
  }

  .ant-modal-content {
    height: 100%;
    width: 100%;
  }

  .ant-modal-body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;


// eslint-disable-next-line react/prop-types
const ImageUploader = ({onImageUploaded, wo_files, childRef}) => {
  const [fileList, setFileList] = useState([]);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState('');

  useEffect(() => {
    if (wo_files.wo_images && wo_files.wo_images.length > 0) {
      const initialFileList = wo_files.wo_images.map((url, index) => ({
        uid: `old-${index}`, 
        name: `Image ${index}`,
        status: 'done',
        url,
        thumbUrl: url,
      }));
  
      setFileList(initialFileList);
    }
  }, [wo_files.wo_images]); 


  const handlePreview = (file) => {
    const isGreaterThan2M = file.size / 1024 / 1024 > 10;

    if (isGreaterThan2M) {      
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        file.preview = e.target.result;
        setFileList([...fileList, file]);
      };
      reader.readAsDataURL(file);
    }
    
    onImageUploaded(file);
  };

  if (childRef) {
    childRef({
      getImages: () => fileList,
      handlePreview
    });
  }

  const handleChange = ({ file, fileList: newFileList }) => {
    console.log('newFileList', newFileList);
    const filteredList = newFileList.filter(f => !f.size || f.size / 1024 / 1024 <= 10);
    console.log('filteredList', filteredList);
    if (newFileList.length > filteredList.length) {
      notification.open({
          message: <div style={{ color: 'red' }}>Error</div>,
          description: 'File size must be less than 10MB.',
      });
    }
  
    setFileList(filteredList);
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
    onImageUploaded(file, 'removed');
  };

  const uploadButton = (
    <div style={{ width: '92px', height: '92px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Icon type="plus" />
      <div style={{fontSize: '13px'}}>Upload</div>
    </div>
  );

  return (
    <UploadAreaContainer>
      <div><b>Add images:</b></div>
      <ImageUploadContainer>
        {fileList.map((file) => (
          <ImagePreviewWrapper key={file.uid} style={{ cursor: 'pointer' }} onClick={() => {
            // open preview modal
            setCurrentImageUrl(file.preview || file.thumbUrl);
            setIsPreviewModalVisible(true);
          }}>
            <ImageThumbnail src={file.preview || file.thumbUrl} alt={file.name} />
            <DeleteButton
              className="delete-btn"
              onClick={() => handleRemove(file)}
            >
              <Icon type="close" />
            </DeleteButton>
          </ImagePreviewWrapper>
        ))}
        {fileList.length < 18 && (
          <UploadButtonWrapper>
            <UploadButton
              accept  ='.png,.jpg,.jpeg'
              listType="picture-card"
              fileList={fileList}
              onChange={handleChange}
              onRemove={handleRemove}
              beforeUpload={(file) => {
                handlePreview(file);
                return false;
              }}
              showUploadList={false}
            >
              {uploadButton}
            </UploadButton>
          </UploadButtonWrapper>
        )}
      </ImageUploadContainer>

      <ImagePreviewModal
        visible={isPreviewModalVisible}
        footer={null}
        onCancel={() => setIsPreviewModalVisible(false)}
      >
        <img alt="preview" style={{ width: '100%', height: '100%' }} src={currentImageUrl} />
      </ImagePreviewModal>
    </UploadAreaContainer>
  );
};

export default ImageUploader;
