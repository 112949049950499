import React from 'react';
import { Link } from 'react-router-dom';
import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import _ from 'lodash';

import { Badge, Col, Icon, Row, Spin, Typography, Tag, Button } from 'antd';
import {
  customFleets,
  PitstopButton,
  prescriptiveMaintenanceMessages,
} from 'shared';

import { WorkOrderStatusEnum } from 'stores/Classes/WorkOrderObject';

import {
  AppStore,
  CarStore,
  IssueStore,
  CurrentUserStore,
  ShopStore,
} from 'stores';
import { UserRoles } from 'stores/Classes/UserObject';

import {
  getRoutineDueEngineHours,
  getRoutineDueMileage,
} from 'containers/IssueProfile/share/utils';

import { getPriorityTagColor } from 'stores/Classes/IssueObject';
import { calcMileage, convertSecondsToHours } from 'helpers/unitCalculations';

const { Text } = Typography;

const CarIndicator = styled.div`
  height: 100%;
  width: 8px;
  position: absolute;
  top: 0;
  padding: 0;
  background-color: ${(props) => props.color || 'initial'};
`;

const CarIDSection = styled.div`
  p {
    margin-bottom: 0px;
  }
`;

const ScannerSection = styled.div`
  p {
    margin-bottom: 0px;
  }
`;

const IssueCategorySection = styled.span`
  white-space: pre-wrap;
`;

const NextPMWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const NextPMPercentageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .percentage {
    font-size: 20px;
  }
  .description {
    font-size: 12px;
  }
  .ant-tag {
    border-radius: 100px;
    background-color: white;
    margin-top: 5px;
    font-size: 12px;
  }
`;

export const renderIndicator = (car) => {
  const priority = car.issueCounts;
  if (priority?.critical > 0) {
    return <CarIndicator color={getPriorityTagColor('Critical')} />;
  }
  if (priority?.major > 0) {
    return <CarIndicator color={getPriorityTagColor('Major')} />;
  }
  return <CarIndicator />;
};

export const renderSuggestedAction = (car) => {
  //maxPriority is returning wrong, so temporarily overriding it
  //const maxPriority = car.maxPriority;
  const maxPriority = car.issueCounts.critical > 0 ? '4' : '2';
  //TODO Find some way to make adjPriority accessible in the car object
  //const maxAdjPriority = car.maxAdjPriority;

  //maxMajorIssueDate is returning wrong, so temporarily overriding it
  //const maxMajorIssueDate = car.maxMajorIssueDate;
  let now = new Date();
  //Temp maxMajorIssueDate to always trigger the stale major message
  let maxMajorIssueDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 8
  );
  const routineServicePresent = car.routineServicePresent;
  const issueCounts = null; // car.issueCounts;
  return (
    <p>
      {prescriptiveMaintenanceMessages(
        maxPriority,
        maxMajorIssueDate,
        routineServicePresent,
        issueCounts
      )}
    </p>
  );
};

export const renderVehicleId = (car) => {
  // console.log(car);
  return (
    <CarIDSection>
      <strong data-test="table-asset-column-vehicle-name">
        {car.carName || car.mmy}
      </strong>
      {car.carName && <p>{car.mmy}</p>}
      {customFleets.motiveFleets.includes(ShopStore.currentShop.id) ? (
        <></>
      ) : (
        <>
          <p>
            {`${Number(calcMileage(car.totalMileage).toFixed(1)).toReadable()}
              ${CurrentUserStore.user.displayedOdometer}`}
          </p>
          {!customFleets.wolfline.includes(ShopStore.currentShop.id) &&
            !customFleets.bmc.includes(ShopStore.currentShop.id) && (
              <p>
                {Math.round(
                  car?.getEngineHours
                    ? car?.getEngineHours()
                    : car.engineHours
                    ? Number(convertSecondsToHours(car.engineHours)).toFixed(1)
                    : 0
                ).toReadable()}{' '}
                engine hours
              </p>
            )}
        </>
      )}
      {car.carType && String(car.carType).toLowerCase() === 'trailer' ? (
        <p>
          <Link to={`/trailer/${car.id}`}>View Details</Link>
        </p>
      ) : (
        <p>
          <Link to={`/car/${car.id}`}>View Details</Link>
        </p>
      )}
    </CarIDSection>
  );
};

const StyledBadge = ({ count, backgroundColor }) => (
  <Badge
    showZero={true}
    count={String(count)}
    style={{ backgroundColor }}
    overflowCount={1000000}
  />
);

StyledBadge.propTypes = {
  count: PropTypes.any,
  backgroundColor: PropTypes.string,
};

const StyledBadgeV2 = ({ style = {}, count, borderColor, color }) => (
  <Badge
    showZero={true}
    count={String(count)}
    style={{
      ...style,
      backgroundColor: '#ffffff',
      borderColor,
      color,
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }}
    overflowCount={1000000}
  />
);

StyledBadgeV2.propTypes = {
  style: PropTypes.any,
  count: PropTypes.any,
  borderColor: PropTypes.string,
  color: PropTypes.string,
};

export const renderIssues = (car) => {
  let criticalCount = car.issueCounts.critical || 0;
  let majorCount = car.issueCounts.major || 0;
  let minorCount = car.issueCounts.minor || 0;

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xl={8} xs={6}>
          <StyledBadge
            count={criticalCount}
            backgroundColor={getPriorityTagColor('Critical')}
          />
        </Col>

        <Col xl={16} xs={18}>
          Critical
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xl={8} xs={6}>
          <StyledBadge
            count={majorCount}
            backgroundColor={getPriorityTagColor('Major')}
          />
        </Col>

        <Col xl={16} xs={18}>
          Major
        </Col>
      </Row>

      <Row gutter={[8, 8]}>
        <Col xl={8} xs={6}>
          <StyledBadge
            count={minorCount}
            backgroundColor={getPriorityTagColor('Minor')}
          />
        </Col>

        <Col xl={16} xs={18}>
          Minor
        </Col>
      </Row>
    </>
  );
};

export const renderIssuesV2 = (car) => {
  let criticalCount = car?.issueCounts?.critical || 0;
  let majorCount = car?.issueCounts?.major || 0;
  let minorCount = car?.issueCounts?.minor || 0;

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <StyledBadgeV2
            count={criticalCount}
            borderColor={getPriorityTagColor('Critical')}
            color={getPriorityTagColor('Critical')}
          />
          <StyledBadgeV2
            style={{ marginLeft: 5 }}
            count={majorCount}
            borderColor={getPriorityTagColor('Major')}
            color={getPriorityTagColor('Major')}
          />
          <StyledBadgeV2
            style={{ marginLeft: 5 }}
            count={minorCount}
            borderColor={getPriorityTagColor('Minor')}
            color={getPriorityTagColor('Minor')}
          />
        </Col>
      </Row>
    </>
  );
};

export const renderIssuesWithShowIssuesLink = (
  car,
  onExpandClick = () => {},
  currentExpandedCarIds = []
) => {
  let criticalCount = car.issueCounts.critical || 0;
  let majorCount = car.issueCounts.major || 0;
  let minorCount = car.issueCounts.minor || 0;

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <StyledBadgeV2
            count={criticalCount}
            borderColor={getPriorityTagColor('Critical')}
            color={getPriorityTagColor('Critical')}
          />
          <StyledBadgeV2
            style={{ marginLeft: 5 }}
            count={majorCount}
            borderColor={getPriorityTagColor('Major')}
            color={getPriorityTagColor('Major')}
          />
          <StyledBadgeV2
            style={{ marginLeft: 5 }}
            count={minorCount}
            borderColor={getPriorityTagColor('Minor')}
            color={getPriorityTagColor('Minor')}
          />
        </Col>
        <Col span={24}>
          <Button type="link" onClick={() => onExpandClick(car, 'issue')}>
            <Icon
              type={currentExpandedCarIds.indexOf(car.id) > -1 ? 'up' : 'down'}
            />
            {currentExpandedCarIds.indexOf(car.id) > -1 ? 'Hide Details' : 'Show Issues'}
            <Icon
              type={currentExpandedCarIds.indexOf(car.id) > -1 ? 'up' : 'down'}
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export const renderIssuesCat = (car) => {
  const categories = car.issues.active.data
    .concat(car.issues.upcoming.data)
    .reduce((prev, id) => {
      if (IssueStore.data.has(id)) {
        const issueDetail = IssueStore.data.get(id).issueDetail;
        if (
          prev.length < 3 &&
          issueDetail &&
          issueDetail.item &&
          issueDetail.item !== 'unknown' &&
          issueDetail.item.length > 0
        ) {
          prev.push(issueDetail.item);
        }
        if (prev.length === 3) {
          prev.push('...');
        }
      }
      return prev;
    }, []);
  return <IssueCategorySection>{categories.join(', ')}</IssueCategorySection>;
};

export const renderScanner = (scanner, car) => {
  let scannerId = _.get(scanner, 'scannerId');
  let lastConnectionTimestamp = _.get(car, 'lastConnectionTimestamp');
  let timestampText = 'Check device connection';
  let monthDiff = 0;

  if (lastConnectionTimestamp) {
    let status = moment
      .tz(
        lastConnectionTimestamp * 1000,
        CurrentUserStore.user.settings.timezone
      )
      .fromNow();

    monthDiff = moment().diff(
      moment.tz(
        lastConnectionTimestamp * 1000,
        CurrentUserStore.user.settings.timezone
      ),
      'M'
    );

    timestampText = `Last Active ${status}`;
  }

  if (
    (scannerId && lastConnectionTimestamp && monthDiff < 1) ||
    _.isBoolean(CarStore.demo)
  ) {
    return (
      <ScannerSection>
        <Badge status="success" text="Active" />
        <p>{scannerId}</p>
        <p>{timestampText}</p>
      </ScannerSection>
    );
  } else if (
    (scannerId && !lastConnectionTimestamp) ||
    (scannerId && lastConnectionTimestamp && monthDiff >= 1)
  ) {
    return (
      <ScannerSection>
        <Badge status="warning" text="Inactive" />
        <p>{scannerId}</p>
        <p>{timestampText}</p>
      </ScannerSection>
    );
  } else if (!scannerId && lastConnectionTimestamp) {
    return (
      <ScannerSection>
        <Badge status="error" text="Disconnected" />
        <p>{timestampText}</p>
      </ScannerSection>
    );
  } else {
    return (
      <ScannerSection>
        <Badge status="error" text="No Device" />
      </ScannerSection>
    );
  }
};

export const renderDriver = (view, editable = true) => (carObj) => {
  return (
    <Observer>
      {() => {
        const car = CarStore.data.get(carObj.id);

        let displayButton = (
          <PitstopButton
            style={{ width: '100%' }}
            onClick={() => {
              view.setState({ selectedCarId: car.id }, () => {
                AppStore.openModals.set(view.modalId, true);
              });
            }}
          >
            <Icon type="edit" />
            {car.user ? 'Edit' : 'Assign'}
          </PitstopButton>
        );

        let displayProfileLink = <></>;

        if (car.user) {
          displayProfileLink = (
            <div style={{ textAlign: 'center' }}>
              <Link to={`/user/${car.user.id}`}>{car.user.name}</Link>
            </div>
          );
        }

        if (!editable || CurrentUserStore.user.role === UserRoles.customer) {
          displayButton = <></>;
        }

        return (
          <>
            <Col span={24}>{displayButton}</Col>
            <Col span={24}>{displayProfileLink}</Col>
          </>
        );
      }}
    </Observer>
  );
};

export const renderNextPMVisit = (nextPmCar) => {
  return (
    <Observer>
      {() => {
        if (!nextPmCar || !nextPmCar.nextPm) {
          return (
            <>
              <Spin />
            </>
          );
        }
        if (_.isEmpty(nextPmCar.nextPm)) {
          return (
            <>
              <Col span={24}>
                <Link to="/maintenance">
                  <Icon type="edit" />
                  <span className="marginLeft5">Setup PM</span>
                </Link>
              </Col>
            </>
          );
        }
        let dueMessages = [];
        for (const nextPm of nextPmCar.nextPm) {
          if (getRoutineDueEngineHours(nextPm))
            dueMessages.push(
              <>
                <b>On Engine Hours:</b>{' '}
                {`${Number(getRoutineDueEngineHours(nextPm)).toReadable()}`}{' '}
                engine hours
              </>
            );

          if (getRoutineDueMileage(nextPm))
            dueMessages.push(
              <>
                <b>On Mileage: </b>{' '}
                {`${Number(getRoutineDueMileage(nextPm)).toReadable()} ${
                  CurrentUserStore.user.displayedOdometer
                }`}
              </>
            );
        }
        if (dueMessages.length === 0) {
          return (
            <>
              <Col span={24}>
                <Link to="/maintenance">
                  <Icon type="edit" />
                  <span className="marginLeft5">Setup PM</span>
                </Link>
              </Col>
            </>
          );
        }
        return (
          <>
            <Col span={24}>
              {_.map(dueMessages, (msg) => (
                <>
                  {msg}
                  <br />
                </>
              ))}
            </Col>
          </>
        );
      }}
    </Observer>
  );
};

export const renderNextPMDueInPercentage = (
  car,
  onExpandClick = () => {},
  currentExpandedCarIds = []
) => {
  const { due_engine_hours, due_mileage, due_time_in_s, rule_type, pm_percent, pm_status, engineHours, totalMileage, pm_description} = car;
  if (pm_percent === null || pm_percent === undefined) {
    return (
      <>
        <Col span={24}>
          <Link to="/maintenance">
            <Icon type="edit" />
            <span className="marginLeft5">Setup PM</span>
          </Link>
        </Col>
      </>
    );
  }
  let displayValue;
  let displayText;
  
  if(rule_type === 'time') {
    // Calc of remaining Time
    const now = new Date(); // Current date
    const dueAt = moment.unix(due_time_in_s);

    // Calculate the difference in days
    const diffInDays = dueAt.diff(now, 'days');

    // Calculate the difference in months
    // const diffInMonths = dueAt.diff(now, 'months');
    const diffInMonths = Math.ceil(diffInDays / 30.4375);

    if (diffInMonths < 1) {
      displayValue =  Math.abs(diffInDays);
      displayText = 'day(s)';
    } else {
      displayValue =  Math.abs(diffInMonths);
      displayText = 'month(s)';
    }
  } else {
    // Calc of remaining mileage and engine hours
    const hoursRemainingValue = rule_type === 'engine_hours'
      ? Math.ceil((isNaN(Number(due_engine_hours)) && isNaN(Number(engineHours))) ? undefined : Math.abs((Number(due_engine_hours) - Number(engineHours))/3600))
      : undefined

    const kmRemaining =  rule_type === 'mileage'
      ? Math.ceil((isNaN(Number(due_mileage)) && isNaN(Number(totalMileage))) ? undefined : Math.abs(Number(due_mileage) - Number(totalMileage)))
      : undefined
    
    const milesRemaining = kmRemaining ? Math.ceil(kmRemaining / 1.60934) : undefined;

    const userSettings = CurrentUserStore.user.settings;
    const milesOrKm =
      userSettings?.odometer === 'km' ? kmRemaining : milesRemaining;
    const milesOrKmText = userSettings?.odometer === 'km' ? 'km' : 'miles';
    displayText =
      milesOrKm !== undefined ? milesOrKmText : 'engine hours';
    const milesOrEngineHours =
      milesOrKm !== undefined ? milesOrKm : hoursRemainingValue;
    // force it to always display positive numbers
    displayValue = Math.abs(milesOrEngineHours);
  }

  const getColor = () => {
    switch (pm_status) {
      case 'Overdue':
        return '#FF202F';
      case 'Due soon':
        return '#FF812A';
      case 'Upcoming':
        return '#0086FA';
      default:
        return '#333333';
    }
  };
  /* const getIcon = () => {
    switch (pm_status) {
      case 'Overdue':
        return 'alert';
      case 'Due soon':
        return 'calendar';
      case 'Upcoming':
        return 'clock-circle';
      default:
        return 'minus-circle';
    }
  }; */

  return (
    <>
      <Col span={24}>
        <NextPMPercentageWrapper>
          <span className="percentage" style={{ color: getColor() }}>
            {Math.max(0, Math.floor(pm_percent))}%
          </span>
          <span className="description" style={{ color: getColor() }}>
             {pm_description === 'PM' || pm_description === 'Preventive Maintenance' ? 'Basic Vehicle Maintenance' :  pm_description }
          </span>
          <span className="description" style={{ color: getColor() }}>
            {pm_status} 
          </span>
          <span className="description">
            {pm_status === 'Overdue' ? 'In' : 'In'} {displayValue}{' '}
            {displayText}
          </span>
          {/* <Tag
            key={1}
            style={{
              color: getColor(),
              borderColor: getColor(),
            }}
          >
            <Icon type={getIcon()} style={{ marginRight: '0.5rem' }} />
            {pm_status}
          </Tag> */}
        </NextPMPercentageWrapper>
      </Col>
      <Col span={24}>
        <Button type="link" onClick={() => onExpandClick(car, 'pm')}>
          <Icon
            type={currentExpandedCarIds.indexOf(car.id) > -1 ? 'up' : 'down'}
          />
          {currentExpandedCarIds.indexOf(car.id) > -1 ? 'Hide Details' : 'Show PMs'}
          <Icon
            type={currentExpandedCarIds.indexOf(car.id) > -1 ? 'up' : 'down'}
          />
        </Button>
      </Col>
    </>
  );
};

export const renderNextPMVisitV2 = ({
  nextPm = null,
  engineHours,
  totalMileage,
}) => {
  return (
    <Observer>
      {() => {
        if (!nextPm || !nextPm?.nextPm) {
          return (
            <>
              <Spin />
            </>
          );
        }
        if (_.isEmpty(nextPm?.nextPm)) {
          return (
            <>
              <Col span={24}>
                <Link to="/maintenance">
                  <Icon type="edit" />
                  <span className="marginLeft5">Setup PM</span>
                </Link>
              </Col>
            </>
          );
        }
        let dueMessages = [];
        for (const currentNextPm of nextPm?.nextPm || []) {
          if (getRoutineDueEngineHours(currentNextPm)) {
            const routineDueEngineHours = Number(
              getRoutineDueEngineHours(currentNextPm)
            );
            const engineHoursInHours = convertSecondsToHours(engineHours);
            const dueSoon = engineHoursInHours < routineDueEngineHours;
            if (dueSoon) {
              dueMessages.push({
                message: (
                  <>
                    <Text>
                      <Icon
                        type="clock-circle"
                        style={{ marginRight: '0.5rem' }}
                      />
                      In{' '}
                      {Math.round(
                        Number(routineDueEngineHours - engineHoursInHours)
                      )}{' '}
                      Engine Hours
                    </Text>
                  </>
                ),
                dueSoon,
              });
            } else {
              // overdue
              dueMessages.push({
                message: (
                  <>
                    <Text type="danger">
                      <Icon type="alert" style={{ marginRight: '0.5rem' }} />
                      Overdue by{' '}
                      {Math.round(
                        Number(engineHoursInHours - routineDueEngineHours)
                      )}{' '}
                      Engine Hours
                    </Text>
                  </>
                ),
                dueSoon,
              });
            }
          }

          if (getRoutineDueMileage(currentNextPm)) {
            const dueSoon =
              calcMileage(totalMileage) < getRoutineDueMileage(currentNextPm);
            if (dueSoon) {
              dueMessages.push({
                message: (
                  <>
                    <Text>
                      <Icon
                        type="dashboard"
                        style={{ marginRight: '0.5rem' }}
                      />
                      Due in{' '}
                      {Math.round(
                        Number(
                          getRoutineDueMileage(currentNextPm) -
                            calcMileage(totalMileage)
                        )
                      )}{' '}
                      {CurrentUserStore.user.displayedOdometer}
                    </Text>
                  </>
                ),
                dueSoon,
              });
            } else {
              // overdue
              dueMessages.push({
                message: (
                  <>
                    <Text type="danger">
                      <Icon type="alert" style={{ marginRight: '0.5rem' }} />
                      Overdue by{' '}
                      {Math.round(
                        Number(
                          calcMileage(totalMileage) -
                            getRoutineDueMileage(currentNextPm)
                        )
                      )}{' '}
                      {CurrentUserStore.user.displayedOdometer}
                    </Text>
                  </>
                ),
                dueSoon,
              });
            }
          }
        }
        if (dueMessages.length === 0) {
          return (
            <>
              <Col span={24}>
                <Link to="/maintenance">
                  <Icon type="edit" />
                  <span className="marginLeft5">Setup PM</span>
                </Link>
              </Col>
            </>
          );
        }
        return (
          <>
            <Col span={24}>
              <NextPMWrapper>
                <Text strong>Routine Service</Text>
                {_.map(dueMessages, (msg) => (
                  <>
                    {msg.message}
                    <Tag key={msg.message} color={msg.dueSoon ? 'blue' : 'red'}>
                      {msg.dueSoon ? 'Due Soon' : 'Overdue'}
                    </Tag>
                  </>
                ))}
              </NextPMWrapper>
            </Col>
          </>
        );
      }}
    </Observer>
  );
};

renderNextPMVisitV2.propTypes = {
  nextPm: PropTypes.any,
  engineHours: PropTypes.any,
  totalMileage: PropTypes.any,
};

export const renderWorkOrderAssigned = (workOrdersForCar) => {
  workOrdersForCar.sort((a, b) =>
    moment(b.created_at).diff(moment(a.created_at))
  );
  return (
    <Observer>
      {() => {
        if (!workOrdersForCar) {
          return (
            <>
              <Spin />
            </>
          );
        }
        if (_.isEmpty(workOrdersForCar)) {
          return (
            <>
              <Col span={24}>N/A</Col>
            </>
          );
        }
        let workOrdersForCarMessages = [];
        for (const workOrder of workOrdersForCar) {
          workOrdersForCarMessages.push(
            <>
              <b>#{workOrder.invoice_number}</b>
              {' - '}
              {WorkOrderStatusEnum[workOrder.status]}
              <br />
              <Link to={`/work-order/${workOrder.id}/edit`}>
                <Icon type="book" />
                <span className="marginLeft5">View</span>
              </Link>
            </>
          );
        }
        return (
          <>
            <Col span={24}>
              {_.map(workOrdersForCarMessages, (msg) => (
                <>
                  {msg}
                  <br />
                </>
              ))}
            </Col>
          </>
        );
      }}
    </Observer>
  );
};
