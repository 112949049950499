import { Button, Card, Col, Row, Table } from 'antd';
import { PropTypes } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledHeaderRow = styled(Row)`
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: 1rem;
      margin: 0;
      color: #1137ab;
      font-weight: 600;

      span {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }
  }

  .date-range-wrapper {
    span {
      font-size: 0.9rem;
      font-weight: 400;
      margin-right: 10px;
    }
  }
`;

const StyledTableRow = styled(Row)`
  tbody tr:nth-child(odd) {
    background-color: #f7f7f7;
  }

  .ant-table-small {
    border: 0;
  }

  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    padding-bottom: 0;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin-top: 20;
  }

  .ant-table-thead > tr > th .ant-table-header-column {
    font-weight: 600;
  }

  .ant-table-small > .ant-table-content > .ant-table-scroll {
    .link {
      color: #000000;
      font-weight: 400;
      &:hover {
        border-bottom: solid 1px #ccc;
        border-radius: 0;
      }
    }

    .status-open {
      color: #123fbf;

      .ant-btn-link {
        color: #123fbf;
        font-size: 1.1rem;
      }
    }

    .status-in-progress {
      color: #218730;

      .ant-btn-link {
        color: #218730;
        font-size: 1.1rem;
      }
    }

    .status-pending {
      color: #fe8f44;

      .ant-btn-link {
        color: #fe8f44;
        font-size: 1.1rem;
      }
    }

    .status-blocked {
      color: #ff202f;

      .ant-btn-link {
        color: #ff202f;
        font-size: 1.1rem;
      }
    }

    .status-completed {
      color: #5a5a5a;

      .ant-btn-link {
        color: #5a5a5a;
        font-size: 1.1rem;
      }
    }

    .status-waiting {
      color: #ff202f;

      .ant-btn-link {
        color: #ff202f;
        font-size: 1.1rem;
      }
    }

    .status-resolved {
      color: #333333;

      .ant-btn-link {
        color: #333333;
        font-size: 1.1rem;
      }
    }
  }
`;

const WorkOrderStatusByAssignedToWidget = ({
  onClickElement,
  workOrdersGroupedByStatusAndAssignedUser,
}) => {
  const columns = [
    {
      title: '',
      dataIndex: 'assignedToName',
      key: 'assignedToName',
      render: (_, row) => {
        return (
          <Button
            onClick={onClickElement('user', row.assignedToName, row)}
            type="link"
            className="link"
          >
            {// if assignedToName lenght is greater than 12, then truncate it
            row.assignedToName.length > 12
              ? `${row.assignedToName.substring(0, 12)}...`
              : row.assignedToName}
          </Button>
        );
      },
    },
    {
      title: 'Open',
      dataIndex: 'open',
      key: 'open',
      align: 'center',
      className: 'status-open',
      render: (_, row) => {
        return (
          <Button
            onClick={onClickElement('status', 'open', row)}
            type="link"
            className="link"
          >
            {row.open || 0}
          </Button>
        );
      },
    },
    // {
    //   title: 'In Progress',
    //   dataIndex: 'inProgress',
    //   key: 'inProgress',
    //   align: 'center',
    //   className: 'status-in-progress',
    //   render: (_, row) => {
    //     return (
    //       <Button
    //         onClick={onClickElement('status', 'in_progress', row)}
    //         type="link"
    //         className="link"
    //       >
    //         {row.inProgress}
    //       </Button>
    //     );
    //   },
    // },
    // {
    //   title: 'Blocked',
    //   dataIndex: 'blocked',
    //   key: 'blocked',
    //   align: 'center',
    //   className: 'blocked_for_parts',
    //   render: (_, row) => {
    //     return (
    //       <Button
    //         onClick={onClickElement('status', 'blocked_for_parts', row)}
    //         type="link"
    //         className="link"
    //       >
    //         {row.blocked_for_parts}
    //       </Button>
    //     );
    //   },
    // },
    {
      title: 'Blocked',
      dataIndex: 'blocked_for_parts',
      key: 'blocked_for_parts',
      align: 'center',
      className: 'status-blocked',
      render: (_, row) => {
        return (
          <Button
            onClick={onClickElement('status', 'blocked_for_parts', row)}
            type="link"
            className="link"
          >
            {row.blocked_for_parts || 0}
          </Button>
        );
      },
    },
    {
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed',
      align: 'center',
      className: 'status-completed',
      render: (_, row) => {
        return (
          <Button
            onClick={onClickElement('status', 'completed', row)}
            type="link"
            className="link"
          >
            {row.completed || 0}
          </Button>
        );
      },
    },
    // {
    //   title: 'Waiting',
    //   dataIndex: 'waiting_for_approval',
    //   key: 'waiting_for_approval',
    //   align: 'center',
    //   className: 'status-waiting',
    //   render: (_, row) => {
    //     return (
    //       <Button
    //         onClick={onClickElement('status', 'waiting_for_approval', row)}
    //         type="link"
    //         className="link"
    //       >
    //         {row.waiting_for_approval}
    //       </Button>
    //     );
    //   },
    // },
    {
      title: 'Resolved',
      dataIndex: 'resolved',
      key: 'resolved',
      align: 'center',
      className: 'status-resolved',
      render: (_, row) => {
        return (
          <Button
            onClick={onClickElement('status', 'resolved', row)}
            type="link"
            className="link"
          >
            {row.resolved || 0}
          </Button>
        );
      },
    },
  ];

  return (
    <Card>
      <StyledHeaderRow gutter={[16, 16]}>
        <Col className="card-header" span={24}>
          <h2>
            Work Order Assigned{' '}
            <span>(Click on any name or number to filter by)</span>
          </h2>
        </Col>
      </StyledHeaderRow>
      <StyledTableRow gutter={[16, 16]}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={workOrdersGroupedByStatusAndAssignedUser}
            pagination={false}
            size="small"
            scroll={{ y: 200 }}
          />
        </Col>
      </StyledTableRow>
    </Card>
  );
};

WorkOrderStatusByAssignedToWidget.propTypes = {
  onClickElement: PropTypes.func,
  workOrdersGroupedByStatusAndAssignedUser: PropTypes.arrayOf(PropTypes.object)
    .isRequired,
};

WorkOrderStatusByAssignedToWidget.defaultProps = {
  onClickElement: () => {},
  workOrdersGroupedByStatusAndAssignedUser: [],
};

export default WorkOrderStatusByAssignedToWidget;
