import React, { useContext } from 'react';
import { Row, Col, DatePicker, InputNumber, Select } from 'antd';
import FormLegend from '../Forms/FormLegend';
import WorkOrderDetailsContext from '../../containers/WorkOrder/WorkOrderDetailsContext';
import ImageUploader from 'containers/WorkOrder/ImageUploadContainer';
import DocumentUploader from 'containers/WorkOrder/DocumentUploader';

const equalHeightStyle = {
  minHeight: '321px',
};

const documentUploaderStyle = {
  border: '1px solid #d9d9d9',
  borderRadius: '2px',
  padding: '24px',
  marginTop: '15px',
  marginBottom: '24px',
  ...equalHeightStyle,
  maxHeight: '321px',
  overflow: 'auto',
};

const WorkOrderOptionalInformation = () => {
  const {
    state: { initialFormValues, isDisabled, currentWorkOrder },
    setInitialFormValue,
    vendorOptions,
    setImageUploaderContainerRef,
    handleImageUpload,
    handleFileUpload,
  } = useContext(WorkOrderDetailsContext);

  const filterOption = (input, option) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  return (
    <>
      <FormLegend>Optional Details</FormLegend>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <label>Work Order Start:</label>
          <DatePicker
            format={'MM/DD/YYYY HH:mm:ss'}
            value={initialFormValues?.woStartDate}
            onChange={(date) => {
              setInitialFormValue('woStartDate', date);
            }}
            style={{ width: '100%' }}
            showTime
            placeholder="Select date and time"
            disabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <label>Work Order Completed:</label>
          <DatePicker
            format={'MM/DD/YYYY HH:mm:ss'}
            value={initialFormValues?.woCompletedDate}
            onChange={(date) => {
              setInitialFormValue('woCompletedDate', date);
            }}
            style={{ width: '100%' }}
            showTime
            placeholder="Select date and time"
            disabled={isDisabled}
          />
        </Col>
        <Col span={12}>
          <label>Mileage:</label>
          <InputNumber
            value={initialFormValues?.mileage}
            onChange={(value) => {
              setInitialFormValue('mileage', value);
            }}
            style={{ width: '100%' }}
            placeholder="Mileage"
            disabled={isDisabled}
            inputMode="tel"
          />
        </Col>
        <Col span={12}>
          <label>Engine Hours:</label>
          <InputNumber
            value={initialFormValues?.engineHours}
            onChange={(value) => {
              setInitialFormValue('engineHours', value);
            }}
            style={{ width: '100%' }}
            placeholder="Engine Hours"
            disabled={isDisabled}
            inputMode="tel"
          />
        </Col>
        <Col span={12}>
          <label>Vendor:</label>
          <Select
            value={initialFormValues?.vendor_id}
            onChange={(value) => {
              setInitialFormValue('vendor_id', value);
            }}
            style={{ width: '100%' }}
            placeholder="Select a User"
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
          >
            {vendorOptions}
          </Select>
        </Col>
      </Row>

      <Row className="limit-size-tablets" style={{ marginTop: '20px' }}>
        <Col xs={24} sm={24} lg={24}>
          <FormLegend>Upload Content</FormLegend>
          <ImageUploader
            childRef={(ref) => {
              setImageUploaderContainerRef(ref);
            }}
            onImageUploaded={handleImageUpload}
            wo_files={
              currentWorkOrder?.wo_files || {
                wo_images: [],
                wo_documents: [],
              }
            }
          />
          <div style={documentUploaderStyle}>
            <DocumentUploader
              onFileUploaded={handleFileUpload}
              wo_files={
                currentWorkOrder?.wo_files || {
                  wo_images: [],
                  wo_documents: [],
                }
              }
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default WorkOrderOptionalInformation;
