import React from 'react';

interface MediaPreviewProps {
  media: string;
}

/**
 * @param props
 * @param props.media - The base64 media to preview
 */
const MediaPreview: React.FC<MediaPreviewProps> = ({ media }) => {
  return (
    <div>
      {media.includes('application/pdf') ? (
        <embed
          src={media}
          type="application/pdf"
          width="100%"
          style={{
            height: '1024px',
          }}
        />
      ) : (
        <img
          src={media}
          alt="Media Preview"
          style={{ width: '100%', height: 'auto' }}
        />
      )}
    </div>
  );
};

export default MediaPreview;
