import styled from 'styled-components';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, decorate, observable, observe } from 'mobx';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PitstopTable } from 'shared';
import CustomHeaderCell from 'shared/PitstopUI/PitstopTable/CustomHeaderCell';
import { getWorkOrderTotalCostWithTaxAndMisc } from 'shared/utils';
import moment from 'moment-timezone';
import {
  AppStore,
  WorkOrderStore,
  TableStore,
  ShopStore,
  UserStore,
  CurrentUserStore,
  CarStore,
} from 'stores';
import {
  List,
  Row,
  Col,
  Dropdown,
  Menu,
  Icon,
  Select,
  Typography,
  Spin,
  notification,
  Button,
  Input,
  Checkbox,
  Popconfirm,
  message,
} from 'antd';

import {
  WorkOrderStatusEnum,
  WorkOrderRepairPriorityEnum,
  filteredWorkOrderStatusKeys,
} from 'stores/Classes/WorkOrderObject';
import DealershipChooser from 'components/DealershipChooser';
import CurrentFilterBadges from 'components/WorkOrder/CurrentFilterBadges';
import { Logger } from 'stores/Classes';
import WorkOrderStatusByAssignedToWidget from 'components/WorkOrder/WorkOrderStatusByAssignedToWidget';
import FlexRow from 'components/General/FlexRow';

const { Option } = Select;
const { Text } = Typography;
const { Search } = Input;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
`;

const Container = styled.div`
  padding-right: 2vw;
`;

const DropdownButtonContainer = styled.div`
  .more-button-dropdown {
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;

    i {
      font-size: 1.3rem;
      font-weight: bold;
      color: #000;
    }
  }
`;

const DeleteButton = styled(Button)`
  &&& {
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;

    i {
      font-size: 1rem;
      font-weight: bold;
      color: #000;
    }
  }
`;

const FilterDropdown = styled.div`
  .filter-dropdown-list {
    padding: 8px 12px;
    border-bottom: solid 1px #e8e8e8;

    .filter-dropdown-list-item {
      margin-bottom: 5px;
    }
  }

  .filter-dropdown-actions {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
  }

  &.assigned-to-dropdown {
    min-width: 300px;
    width: 300px;
  }
`;

const StyledTableRow = styled.div`
  .ant-table-row {
    cursor: default !important;
  }

  .clickable-cell:hover {
    cursor: pointer;
  }

  .status-select {
    font-weight: 500;

    &.open {
      color: #123fbf;
    }

    &.in_progress {
      color: #218730;
    }

    &.pending {
      color: #fe8f44;
    }

    &.blocked_for_parts {
      color: #ff202f;
    }

    &.waiting_for_approval {
      color: #ff202f;
    }
  }
`;

class WorkOrderTable extends Component {
  static propTypes = {
    history: PropTypes.object,
    shopId: PropTypes.number,
    isSelectionEnabled: PropTypes.bool,
    getCurrentSelectedPriorities: PropTypes.func,
    columnKeys: PropTypes.array,
    isEditable: PropTypes.bool,
    showSetupButton: PropTypes.bool,
  };

  tableStore = new TableStore();
  usersTableStore = new TableStore();
  carsTableStore = new TableStore();

  disposer = observe(ShopStore.currentShop, 'id', async () => {
    // reset all filters
    this.setState({
      currentFilters: [],
      currentAssignedToFilters: [],
      workOrdersGroupedByStatusAndAssignedUser: [],
    });
    await this.loadShopUsers();
    await this.loadWorkOrdersGroupedByStatusAndAssignedUser();
  });

  componentWillUnmount() {
    this.disposer();
  }

  get shopCars() {
    const cars = _.map(this.carsTableStore.data, (id) => CarStore.data.get(id));
    return cars;
  }

  state = {
    workOrdersGroupedByStatusAndAssignedUser: [],
    selectedWorkOrders: undefined,
    selectedWorkOrdersIds: [],
    timezone: 'America/New_York',
    loadingInitial: true,
    currentSearch: '',
    currentFilters: [],
    filterByStatusDropdownVisible: false,
    shopUsers: [],
    filterByAssignedToDropdownVisible: false,
    currentAssignedToFilters: [],
    currentAssignedToFilterOptions: [],
  };

  get shopId() {
    return ShopStore.currentShop.id;
  }

  get currentFiltersApplied() {
    return [
      ...this.state.currentFilters,
      ...this.state.currentAssignedToFilters,
    ];
  }

  loadShopUsers = async () => {
    const shopUsers = await UserStore.fetchUsersForTable(
      {
        offset: 0,
        limit: 100,
        filter: null,
      },
      this.usersTableStore
    );

    const filterOptions = shopUsers.map((user) => ({
      text:
        `${user.firstName || ''} ${user.lastName || ''}`.trim() || user.email,
      value: user.userId,
    }));

    this.setState({
      shopUsers,
      currentAssignedToFilterOptions: _.uniqBy(filterOptions, 'value'),
    });
  };

  convertWorkOrdersGroupedToDesiredFormat = (workOrderGrouped) => {
    const formattedData = _.map(
      workOrderGrouped,
      (workOrdersAssignedToUser, userId) => {
        const user = _.find(this.state.shopUsers, { userId: parseInt(userId) });
        const fullName = user
          ? `${user.firstName} ${user.lastName}`
          : 'Unknown';

        const statusCounts = workOrdersAssignedToUser.reduce(
          (acc, workOrder) => {
            if (!acc[workOrder.status]) {
              acc[workOrder.status] = 0;
            }
            acc[workOrder.status] += parseInt(workOrder.count);
            return acc;
          },
          {}
        );

        const defaultStatusCounts = filteredWorkOrderStatusKeys.map(
          (status) => {
            return {
              [status]: 0,
            };
          }
        );

        const combinedCounts = { ...defaultStatusCounts, ...statusCounts };

        const total = _.reduce(
          combinedCounts,
          (acc, value) => {
            return acc + value;
          },
          0
        );

        return {
          key: _.uniqueId(),
          assignedToId: parseInt(userId),
          assignedToName: fullName,
          ...combinedCounts,
          total: total,
        };
      }
    );

    // Sorting based on the total number of tasks
    return formattedData.sort((a, b) => b.total - a.total);
  };

  loadWorkOrdersGroupedByStatusAndAssignedUser = async () => {
    const workOrdersGroupedByStatusAndAssignedUser = await WorkOrderStore.workOrdersGroupedByStatusAndAssignedUser(
      ShopStore.currentShop.id
    );

    const workOrdersGroupedByUser = _.groupBy(
      workOrdersGroupedByStatusAndAssignedUser,
      'assignedTo'
    );

    const formatedWorkOrdersGrouped = this.convertWorkOrdersGroupedToDesiredFormat(
      workOrdersGroupedByUser
    );

    this.setState({
      workOrdersGroupedByStatusAndAssignedUser: formatedWorkOrdersGrouped,
    });
  };

  loadShopCars = async () => {
    await CarStore.getShopCarsForTable(
      {
        offset: 0,
        limit: 500,
        filter: null,
      },
      this.carsTableStore
    );
  };

  async componentDidMount() {
    this.filterOptions = filteredWorkOrderStatusKeys.map((status) => {
      return {
        text: WorkOrderStatusEnum[status],
        value: status,
      };
    });
    await this.load();
    this.loadWorkOrdersGroupedByStatusAndAssignedUser();
  }

  async componentDidUpdate(prevProps, prevState) {
    let { currentSearch, currentAssignedToFilterOptions } = this.state;
    if (currentSearch !== prevState.currentSearch) {
      this.handleSearchChange();
    }

    if (
      currentAssignedToFilterOptions !==
      prevState.currentAssignedToFilterOptions
    ) {
      this.allColumns = this.allColumns.map((column) => {
        if (column.key === 'assigned_to') {
          return {
            ...column,
            filters: currentAssignedToFilterOptions,
          };
        }
        return column;
      });
    }
  }

  searchDebouncer = null;

  // filterOptions = [
  //   {
  //     text: 'Pending',
  //     value: 'pending',
  //   },
  //   {
  //     text: 'Open',
  //     value: 'open',
  //   },
  //   {
  //     text: 'In Progress',
  //     value: 'in_progress',
  //   },
  //   {
  //     text: 'Blocked for Parts',
  //     value: 'blocked_for_parts',
  //   },
  //   {
  //     text: 'Waiting for Invoice',
  //     value: 'waiting_for_approval',
  //   },
  //   {
  //     text: 'Resolved',
  //     value: 'resolved',
  //   },
  // ];
  filterOptions = [];

  handleSearchChange = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.searchDebouncer);
    this.searchDebouncer = setTimeout(() => {
      this.tableStore.reset();
    }, 500);
  };

  load = async () => {
    this.setState({ loadingInitial: true });
    await this.loadShopUsers();
    // await this.loadShopCars();
    this.setState({ loadingInitial: false });
  };

  fetchData = (filters, table) => {
    let { currentSearch } = this.state;
    if (_.isNil(filters)) {
      let { offset, limit, sort, filter } = this.tableStore;

      filters = { offset, limit, sort, filter };
    }

    // Set Sort by last created as default
    filters.sort = filters.sort ? filters.sort : '-created_by';

    if (currentSearch && currentSearch.length > 0) {
      filters.filter = {
        ...filters.filter,
        search: currentSearch,
      };
    }

    if (this.state.currentFilters.length > 0) {
      filters.filter = {
        ...filters.filter,
        status: this.state.currentFilters.map((filter) => filter.key),
      };
    }

    if (this.state.currentAssignedToFilters.length > 0) {
      filters.filter = {
        ...filters.filter,
        assignedTo: this.state.currentAssignedToFilters.map(
          (filter) => filter.key
        ),
      };
    }

    WorkOrderStore.fetchWorkOrdersForTable(
      filters,
      table,
      ShopStore.currentShop.id,
      false
    );
  };

  handleMenuClick = async ({ key, domEvent }, row) => {
    domEvent.stopPropagation();
    domEvent.preventDefault();

    switch (key) {
      case '1':
        try {
          AppStore.addLoading('Getting Work Order Report');
          await WorkOrderStore.printWorkOrder(row.id);
        } catch (error) {
          Logger.error(error);
          AppStore.addError(
            'Unknown error to generate the Work Order. Please try again later'
          );
        } finally {
          AppStore.removeLoading('Getting Work Order Report');
        }
        break;
      case '2':
        try {
          AppStore.addLoading('Getting Work Order Report');
          await WorkOrderStore.downloadWorkOrder(row.id);
        } catch (error) {
          Logger.error(error);
          AppStore.addError(
            'Unknown error to generate the Work Order. Please try again later'
          );
        } finally {
          AppStore.removeLoading('Getting Work Order Report');
        }
        break;
      default:
        break;
    }
  };

  handleStatusChange = async (newStatus, row) => {
    try {
      const response = await WorkOrderStore.updateWorkOrderOnCustomEndpoint({
        id: row.id,
        status: newStatus,
      });

      // Check if we need to Resolve a DVIR
      if (
        newStatus === 'resolved' &&
        row.type === 'driver_identified' &&
        row.work_order_dvirs.length > 0
      ) {
        if (!CurrentUserStore.user.email) {
          throw new Error('An user is required to resolve the DVIR');
        }

        const samsaraUsers = await CarStore.fetchSamsaraUsers(
          ShopStore.currentShop.id
        );
        // Select the current user email if it's in the list
        const currentUserEmail = CurrentUserStore.user.email;
        const samsaraUserResolving = samsaraUsers.find(
          (user) =>
            String(user.email).toLowerCase() ===
            String(currentUserEmail).toLowerCase()
        );

        let defects = [];
        let dvirId = row.work_order_dvirs[0].dvir_id;
        row.work_order_dvirs.forEach((dvirDefects) => {
          defects.push({ id: dvirDefects.defect_id.toString() });
        });

        if (dvirId && defects.length > 0) {
          const defectsResolved = await CarStore.resolveDVIRDefects({
            shopId: ShopStore.currentShop.id,
            dvirId: dvirId,
            defects: defects,
            mechanicNotes: 'Resolved by Pitstop',
            resolvedBySamsaraUserId:
              samsaraUserResolving?.id || currentUserEmail,
          });
          AppStore.addNotification(
            `${defectsResolved.length} defects resolved successfully`
          );
        }
      }

      notification.success({
        message: 'Status updated',
        key: `statusUpdated${moment().format('x')}`,
        description: <Container>The work order status was updated.</Container>,
      });
      this.loadWorkOrdersGroupedByStatusAndAssignedUser();
      return response;
    } catch (error) {
      Logger.error(error);
      let friendlyMsg =
        "We couldn't update the Work Order status at this moment, please try again later";
      return notification.error({
        message: 'Oops!',
        key: `statusUpdateError${moment().format('x')}`,
        description: <Container>{friendlyMsg}</Container>,
      });
    }
  };

  openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  get data() {
    let data = _.map(this.tableStore.data, (id) => WorkOrderStore.data.get(id));

    return _.filter(data, Boolean);
  }

  filteredInfo = {};

  onChangeCurrentFilters = () => {
    // update table filter based on the filters selected
    const filters = {
      status: [],
      assignedTo: [],
    };
    this.state.currentFilters.forEach((filter) => {
      filters['status'].push(filter.key);
    });
    this.state.currentAssignedToFilters.forEach((filter) => {
      filters['assignedTo'].push(filter.key);
    });
    this.setState({ filteredInfo: filters });
    // update the allColumns
    this.allColumns = this.allColumns.map((column) => {
      if (column.key === 'status') {
        return {
          ...column,
          filters: this.filterOptions,
          filteredValue: filters['status'],
        };
      }
      if (column.key === 'assigned_to') {
        return {
          ...column,
          filters: this.state.currentAssignedToFilterOptions,
          filteredValue: filters['assignedTo'],
        };
      }
      return column;
    });
    this.tableStore.reset();
  };

  handleStatusFilterChange = (selectedKeys, confirm) => {
    this.setState(
      {
        currentFilters: selectedKeys.map((key) => {
          const status = this.filterOptions.find((s) => s.value === key);
          return {
            key: key,
            label: status?.text || key,
          };
        }),
        filterByStatusDropdownVisible: false,
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
      }
    );
  };

  handleAssignedToFilterChange = (selectedKeys, confirm) => {
    this.setState(
      {
        currentAssignedToFilters: selectedKeys.map((key) => {
          const user = this.state.currentAssignedToFilterOptions.find(
            (u) => u.value === key
          );
          return {
            key: key,
            label: user?.text || key,
          };
        }),
        filterByAssignedToDropdownVisible: false,
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
      }
    );
  };

  handleReset = (clearFilters, type = 'status') => {
    clearFilters();
    if (type === 'assignedTo') {
      this.setState(
        {
          currentAssignedToFilters: [],
          filterByAssignedToDropdownVisible: false,
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
      return;
    }
    this.setState(
      {
        currentFilters: [],
        filterByStatusDropdownVisible: false,
      },
      () => {
        this.onChangeCurrentFilters();
      }
    );
  };

  handleAssignedToChange = async (newAssignedTo, row) => {
    try {
      const response = await WorkOrderStore.updateWorkOrderOnCustomEndpoint({
        id: row.id,
        assigned_to: newAssignedTo,
      });
      notification.success({
        message: 'Assigned to updated',
        key: `assignedToUpdated${moment().format('x')}`,
        description: (
          <Container>The work order assigned to was updated.</Container>
        ),
      });
      return response;
    } catch (error) {
      Logger.error(error);
      let friendlyMsg =
        'We could not update the Work Order assigned to at this moment, please try again later';
      return notification.error({
        message: 'Oops!',
        key: `assignedToUpdateError${moment().format('x')}`,
        description: <Container>{friendlyMsg}</Container>,
      });
    }
  };

  renderStatusFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {this.filterOptions.map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button onClick={() => this.handleReset(clearFilters)} size="small">
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => this.handleStatusFilterChange(selectedKeys, confirm)}
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  renderAssignedToFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => {
    return (
      <FilterDropdown className="assigned-to-dropdown">
        {/* For each filterOptions display a checkbox and the name of the filterOptions */}
        <div className="filter-dropdown-list">
          {this.state.currentAssignedToFilterOptions.map((user) => {
            return (
              <div className="filter-dropdown-list-item" key={user.value}>
                <Checkbox
                  checked={selectedKeys.includes(user.value)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedKeys([...selectedKeys, user.value]);
                    } else {
                      setSelectedKeys(
                        selectedKeys.filter((key) => key !== user.value)
                      );
                    }
                  }}
                >
                  {user.text}
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className="filter-dropdown-actions">
          <Button
            onClick={() => this.handleReset(clearFilters, 'assignedTo')}
            size="small"
          >
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() =>
              this.handleAssignedToFilterChange(selectedKeys, confirm)
            }
          >
            OK
          </Button>
        </div>
      </FilterDropdown>
    );
  };

  allColumns = [
    {
      dataIndex: 'invoice_number',
      editable: false,
      title: 'Order #',
      required: true,
      render: (wo, row) => {
        return (
          <div
            className="clickable-cell"
            onClick={() =>
              this.props.history.push(`/work-order/${row.id}/edit`)
            }
          >
            <Button type="link">{`#${wo}`}</Button>
          </div>
        );
      },
    },
    {
      key: 'car_name',
      dataIndex: 'car_name',
      sorter: true,
      editable: false,
      title: 'Unit ID',
      required: true,
      render: (id_car, row) => {
        return <>{row.car_name || 'N/A'}</>;
      },
    },
    {
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      editable: false,
      title: 'Status',
      filters: this.filterOptions,
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ filterByStatusDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderStatusFilterDropdown,
      filteredValue: this.filteredInfo.status || '',
      // onFilter: (value, record) => record.status.includes(value),
      required: true,
      render: (status, row) => {
        const options = filteredWorkOrderStatusKeys
          .map((workOrderStatusKey) => ({
            key: workOrderStatusKey,
            value: WorkOrderStatusEnum[workOrderStatusKey],
          }))
          .map((workOrderStatus) => {
            return (
              <Option key={workOrderStatus.key} value={workOrderStatus.key}>
                {workOrderStatus.value}
              </Option>
            );
          });
        return (
          <Select
            defaultValue={status}
            className={`status-select ${status}`}
            style={{ width: 180 }}
            onChange={(evt) => this.handleStatusChange(evt, row)}
            onClick={(evt) => evt.stopPropagation()}
            disabled={row.migrate_source === 'TMT' ? true : false}
          >
            {options}
          </Select>
        );
      },
    },
    {
      key: 'priority',
      sorter: true,
      dataIndex: 'priority',
      editable: false,
      title: 'Repair Priority',
      required: true,
      render: (repairPriority) => {
        return WorkOrderRepairPriorityEnum[repairPriority]
          ? WorkOrderRepairPriorityEnum[repairPriority]
          : 'N/A';
      },
    },
    {
      dataIndex: 'work_order_lines',
      editable: false,
      title: 'Service Tasks',
      required: true,
      render: (woLines) => {
        const getTitle = (woLine) => {
          const hasVmrsInfo = woLine.vmrs_asm_code && woLine.vmrs_asm_text;
          let title;
          if (hasVmrsInfo) {
            title = `${woLine.vmrs_asm_code} - ${woLine.vmrs_asm_text}`;
          } else {
            title = _.get(woLine, 'title', '');
          }
          return title;
        };
        return woLines && woLines.length ? (
          <List
            size="small"
            dataSource={woLines ? woLines.map(getTitle) : []}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        ) : (
          'N/A'
        );
      },
    },
    {
      key: 'created_by',
      dataIndex: 'created_by',
      editable: false,
      sorter: true,
      title: 'Created At',
      required: true,
      render: (wo, row) => {
        const user = this.state.shopUsers.find((u) => u.userId === wo);
        return (
          <Row>
            <Col span={24}>
              <Text strong>{moment(row.created_at).format('ll')}</Text>
            </Col>
            <Col span={24}>
              {user
                ? `${user.firstName} ${user.lastName}`.trim() || user.email
                : wo}
            </Col>
          </Row>
        );
      },
    },
    {
      key: 'assigned_to',
      dataIndex: 'assigned_to',
      sorter: true,
      editable: false,
      title: 'Assigned To',
      filters: this.state.currentAssignedToFilterOptions,
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ filterByAssignedToDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderAssignedToFilterDropdown,
      // filterDropdownVisible: this.state.filterByAssignedToDropdownVisible,
      required: true,
      render: (wo, row) => {
        const options = this.state.shopUsers
          .map((user) => ({
            key: user.userId,
            value:
              `${user.firstName || ''} ${user.lastName || ''}`.trim() ||
              user.email,
          }))
          .map((userMapped) => {
            return (
              <Option key={userMapped.key} value={userMapped.key}>
                {userMapped.value}
              </Option>
            );
          });
        return (
          <Select
            defaultValue={wo}
            style={{ width: 180 }}
            onChange={(evt) => this.handleAssignedToChange(evt, row)}
            onClick={(evt) => evt.stopPropagation()}
          >
            {options}
          </Select>
        );
      },
    },
    {
      dataIndex: 'total_costs',
      editable: false,
      title: 'Total Cost',
      required: true,
      render: (totalCosts, row) => {
        return getWorkOrderTotalCostWithTaxAndMisc(
          {
            workOrderLines: row.work_order_lines,
            taxObj: { tax: row.tax || 0, type: row.tax_type || 'percentage' },
            miscObj: { misc: row.misc || 0, type: row.misc_type || 'percentage' },
            applyCurrency: true,
            workOrder: row,
          }
        );
      },
    },
    {
      dataIndex: 'actions',
      editable: false,
      title: '',
      required: false,
      render: (_, row) => {
        const menu = (
          <Menu onClick={(evt) => this.handleMenuClick(evt, row)}>
            <Menu.Item key="1">
              <Icon type="printer" />
              Print work order
            </Menu.Item>
            <Menu.Item key="2">
              <Icon type="download" />
              Download work order
            </Menu.Item>
          </Menu>
        );
        return (
          <FlexRow>
            <Dropdown overlay={menu}>
              <DropdownButtonContainer>
                <Button
                  shape="circle"
                  size="small"
                  className="more-button-dropdown"
                >
                  <Icon type="more" />
                </Button>
              </DropdownButtonContainer>
            </Dropdown>
            <Popconfirm
              title="Are you sure you want to delete this work order?"
              onConfirm={() => this.onDelete(row)}
            >
              <DeleteButton shape="circle">
                <Icon type="delete" />
              </DeleteButton>
            </Popconfirm>
          </FlexRow>
        );
      },
    },
  ];

  transformColumnsToNotEditable = (rows, propIsEditable) => {
    const isEditable = _.isNil(propIsEditable) ? true : propIsEditable;
    if (isEditable === false) {
      return _.map(rows, (row) => {
        return { ...row, editable: false };
      });
    } else {
      return rows;
    }
  };

  generateColumns = (propsColumnKeys) => {
    if (_.isEmpty(propsColumnKeys)) {
      return this.transformColumnsToNotEditable(
        this.allColumns,
        this.props.isEditable
      );
    }
    const filteredColumns = _.filter(this.allColumns, (column) => {
      return (
        _.includes(propsColumnKeys, column.key) ||
        _.includes(propsColumnKeys, column.dataIndex)
      );
    });
    return this.transformColumnsToNotEditable(
      filteredColumns,
      this.props.isEditable
    );
  };

  onEdit = async ({ newValue: updatedUser }) => {
    if (updatedUser) {
      await WorkOrderStore.updateWorkOrder(updatedUser.id, updatedUser);
    }

    this.setState({
      selectedWorkOrdersIds: [updatedUser.id],
    });
  };

  onDelete = async (workOrder) => {
    AppStore.addLoading('Deleting Work Order');
    await WorkOrderStore.deleteWorkOrder(workOrder.id);
    AppStore.removeLoading('Deleting Work Order');
    message.success('Work Order deleted successfully');
    this.tableStore.reset();
  };

  onSelectWorkOrders = (workOrderIds) => {
    this.setState({
      selectedWorkOrdersIds: workOrderIds,
    });
  };

  render() {
    return this.state.loadingInitial ? (
      <LoadingContainer>
        <Spin tip="Loading..." />
      </LoadingContainer>
    ) : (
      <>
        <DealershipChooser />
        {/* Add widget with WorkOrder grouped by assignedTo and status */}
        {this.state.workOrdersGroupedByStatusAndAssignedUser.length > 0 && (
          <Row style={{ marginBottom: 10, marginTop: 10 }} gutter={[16, 16]}>
            <Col xs={18} sm={15} md={14} lg={13} xl={12}>
              <WorkOrderStatusByAssignedToWidget
                workOrdersGroupedByStatusAndAssignedUser={
                  this.state.workOrdersGroupedByStatusAndAssignedUser
                }
                onClickElement={(type, value, row) => () => {
                  if (type === 'user') {
                    this.setState(
                      {
                        currentAssignedToFilters: [
                          {
                            key: row.assignedToId,
                            label: row.assignedToName,
                          },
                        ],
                        currentFilters: [],
                      },
                      () => {
                        this.onChangeCurrentFilters();
                      }
                    );
                  } else if (type === 'status') {
                    const statusObj = this.filterOptions.find(
                      (s) => s.value === value
                    );
                    this.setState(
                      {
                        currentAssignedToFilters: [
                          {
                            key: row.assignedToId,
                            label: row.assignedToName,
                          },
                        ],
                        currentFilters: [
                          {
                            key: value,
                            label: statusObj.text,
                          },
                        ],
                      },
                      () => {
                        this.onChangeCurrentFilters();
                      }
                    );
                  }
                }}
              />
            </Col>
          </Row>
        )}
        <Row style={{ marginBottom: 10, marginTop: 10 }} gutter={[16, 16]}>
          <Col span={6}>
            <Search
              placeholder="Search by Order # or unit ID"
              onChange={(event) => {
                this.setState({
                  currentSearch: event.target.value,
                });
              }}
            />
          </Col>
        </Row>

        {/* Filter badges */}
        <CurrentFilterBadges
          currentFiltersApplied={this.currentFiltersApplied}
          onClearAllFilters={() => {
            this.setState(
              {
                currentFilters: [],
                currentAssignedToFilters: [],
              },
              () => {
                this.onChangeCurrentFilters();
              }
            );
          }}
          onCloseTag={(filter) => {
            this.setState(
              {
                currentFilters: this.state.currentFilters.filter(
                  (f) => f.key !== filter.key
                ),
                currentAssignedToFilters: this.state.currentAssignedToFilters.filter(
                  (f) => f.key !== filter.key
                ),
              },
              () => {
                this.onChangeCurrentFilters();
              }
            );
          }}
        />

        <StyledTableRow>
          <PitstopTable
            id={`${ShopStore.currentShop.id}-work-order-list`}
            data={this.data}
            rowKey="id"
            onRowClick={(record, evt) => {
              if (evt.target.closest('.order-number-column')) {
                if (
                  evt.target.classList.contains(
                    'ant-select-selection__rendered'
                  ) ||
                  evt.target.classList.contains(
                    'ant-select-dropdown-menu-item'
                  ) ||
                  evt.target.classList.contains(
                    'ant-select-selection-selected-value'
                  )
                ) {
                  evt.stopPropagation();
                  return;
                }
                return this.props.history.push(`/work-order/${record.id}/edit`);
              }
            }}
            columns={this.allColumns}
            hideTopPagination={true}
            fetchData={this.fetchData}
            getTableStore={(tableStore) => (this.tableStore = tableStore)}
            components={{
              header: {
                cell: CustomHeaderCell,
              },
            }}
            onDelete={this.onDelete}
          />
        </StyledTableRow>
      </>
    );
  }
}

decorate(WorkOrderTable, {
  tableStore: observable,
  data: computed,
  shopId: computed,
  shopCars: computed,
});

export default observer(WorkOrderTable);
