import _ from 'lodash';

export const currencyOrNumber = (value, applyCurrency = true) => {
  if (!applyCurrency) return value;
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const convertToMonetary = (value, showSymbol = true) => {
  if (!showSymbol) return value.toFixed(2);
  if (value === null || value === undefined) {
    return '$0.00';
  }
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const getWorkOrderTotalCostWithoutTax = (
  workOrderLines = [],
  applyCurrency = true
) => {
  if (!workOrderLines || _.isEmpty(workOrderLines)) {
    return currencyOrNumber(0, applyCurrency);
  }
  return currencyOrNumber(
    _.sumBy(workOrderLines || [], (line) => {
      return _.sum([
        parseFloat(line.parts_cost || 0),
        parseFloat(line.labour_cost || 0),
      ]);
    }),
    applyCurrency
  );
};

export const getWorkOrderTotalCostWithTax = (
  workOrderLines = [],
  taxObj = { tax: 0, type: 'percentage' },
  applyCurrency = true
) => {
  const costWithoutTax = getWorkOrderTotalCostWithoutTax(workOrderLines, false);
  if (!taxObj) {
    throw new Error('Tax was not provided');
  }
  const taxIncrease =
    taxObj.type === 'percentage'
      ? costWithoutTax * (taxObj.tax / 100)
      : taxObj.tax;
  return currencyOrNumber(costWithoutTax + taxIncrease, applyCurrency);
};

export const getWorkOrderLaborsTotal = ({ currentWorkOrder, lineItems }) => {
  if (currentWorkOrder?.labours_total_has_manual_override) {
    return currentWorkOrder?.manual_labours_total || 0;
  }

  let laborTotal = 0;
  if (currentWorkOrder?.laborsTotal) {
    laborTotal = currentWorkOrder.laborsTotal;
  }
  // if there is at least one labour in the lineItems, it'll always use it
  if (lineItems.length) {
    const labours = lineItems.map((l) => l.labours);
    // remove all empty arrays
    const allLabours = _.flatten(labours);
    if (allLabours.length) {
      laborTotal = _.sumBy(allLabours, (labour) => {
        return parseFloat(labour.cost || 0) * parseFloat(labour.hours || 0);
      });
    }
  }

  return laborTotal;
}

export const getWorkOrderPartsTotal = ({ currentWorkOrder, lineItems }) => {
  if (currentWorkOrder?.parts_total_has_manual_override) {
    return currentWorkOrder?.manual_parts_total || 0;
  }

  let partsTotal = 0;
  if (currentWorkOrder?.partsTotal) {
    partsTotal = currentWorkOrder.partsTotal;
  }
  // if there is at least one part in the lineItems, it'll always use it
  if (lineItems.length) {
    const parts = lineItems.map((l) => l.parts);
    // remove all empty arrays
    const allParts = _.flatten(parts);
    if (allParts.length) {
      partsTotal = _.sumBy(allParts, (part) => {
        const cost = part.cost ? parseFloat(part.cost) : 0;
        const markup = part.markup ? parseFloat(part.markup) : 0;
        const amount = part.amount ? parseInt(part.amount) : 0;
        return (cost + (cost * markup) / 100) * amount;
      });
    }
  }

  return partsTotal;
}

export const getWorkOrderTotalCostWithTaxAndMisc = (
  {
    workOrderLines = [],
    taxObj = { tax: 0, type: 'percentage' },
    miscObj = { misc: 0, type: 'percentage' },
    applyCurrency = true,
    workOrder = null
  }
) => {
  if (workOrder) {
    if (workOrder?.costs_total_has_manual_override) {
      return currencyOrNumber(workOrder?.manual_costs_total || 0, applyCurrency);
    }

    let costsTotal = 0;
    if (workOrder.costsTotal) {
      costsTotal = workOrder.costsTotal;
    }
    // if there are line items, it'll always use it
    if (workOrderLines.length) {
      costsTotal = _.sum([
        getWorkOrderLaborsTotal({ currentWorkOrder: workOrder, lineItems: workOrderLines }),
        getWorkOrderPartsTotal({ currentWorkOrder: workOrder, lineItems: workOrderLines }),
      ]);
      const taxValue =
        taxObj.type === 'percentage'
          ? ((taxObj.tax || 0) / 100) * costsTotal
          : parseFloat(taxObj.tax || 0);
      costsTotal += taxValue;
      const miscValue =
        miscObj.type === 'percentage'
          ? ((miscObj.misc || 0) / 100) * costsTotal
          : parseFloat(miscObj.misc || 0);
      costsTotal += miscValue;
    }

    return currencyOrNumber(costsTotal, applyCurrency);
  }

  const costWithTax = getWorkOrderTotalCostWithTax(
    workOrderLines,
    taxObj,
    false
  );
  if (!miscObj) {
    return costWithTax;
  }
  const miscIncrease =
    miscObj.type === 'percentage'
      ? costWithTax * (miscObj.misc / 100)
      : miscObj.misc;
  return currencyOrNumber(costWithTax + miscIncrease, applyCurrency);
};

export const convertDemoVinToRealVin = (vin) => {
  const vinArr = vin.split('-');
  return vinArr[2];
};

export const showDemoVinOrRealVin = (vin) => {
  if (!vin || vin.indexOf('DEMO-') === -1) {
    return vin;
  }
  return convertDemoVinToRealVin(vin);
};

export const checkIfMobileScreenSize = () => {
  return window.innerWidth < 1024;
};

export const getScreenSize = () => {
  return window.innerWidth;
};
