import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router';
import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PitstopButton, PitstopModal } from 'shared';
import {
  Row,
  Col,
  notification,
  Spin,
  Button,
  Typography,
  InputNumber,
  Input,
  Icon,
  Checkbox,
  Collapse,
} from 'antd';
import _, { deburr, isArray, isEmpty, kebabCase } from 'lodash';
import moment from 'moment';
import {
  WorkOrderStore,
  TableStore,
  ShopStore,
  UserStore,
  CurrentUserStore,
  CarStore,
  AppStore,
  IssueStore,
} from 'stores';
import WorkOrderForm from 'components/WorkOrderDesktop/WODetailsForm';
// import WorkOrderIssuesSummary from 'components/WorkOrderDesktop/WOIssuesSummary';
import WorkOrderLineItems from 'components/WorkOrderDesktop/LineItems/WOLineItems';
import WorkOrderCostSummary from 'components/WorkOrderDesktop/WOCostSummary';
import DealershipChooser from 'components/DealershipChooser';
import ImageUploader from './ImageUploadContainer';
import DocumentUploader from './DocumentUploader';

import { PitstopTableCacheStore } from 'stores/CacheStore';
import { webServiceProvider } from 'shared';
import UploadImageModal from './modals/UploadImageModal';
import { Logger } from 'stores/Classes';
import { Link } from 'react-router-dom';
import { WorkOrderProvider } from './WorkOrderDetailsContext';

const { Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
`;

const Container = styled.div`
  padding-right: 2vw;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 25px 0 10px;
`;

const Wrapper = styled.div`
  margin-top: 15px;
  width: 100%;
`;

const ActionButtonsWrapper = styled.div`
  right: 15px;

  button {
    margin-left: 10px;
  }
`;

const UploadButton = styled(Button)`
  background-color: #fff;
  border: 1px solid #1890ff;
  color: #1890ff;
  &:hover {
    background-color: #ecf6fd;
    border-color: #1890ff;
  }
  &:focus,
  &:active {
    background-color: #fff;
    border-color: #1890ff;
  }
`;

const ImportedInformation = styled.div`
  background-color: #f0f0f0;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 4px;
  p {
    margin: 0;
  }
`;

const ADD_PART_MODAL = 'ADD_PART_MODAL';

const equalHeightStyle = {
  minHeight: '321px',
};

const documentUploaderStyle = {
  border: '1px solid #d9d9d9',
  borderRadius: '2px',
  padding: '24px',
  marginTop: '15px',
  marginBottom: '24px',
  ...equalHeightStyle,
  maxHeight: '321px',
  overflow: 'auto',
};

class WorkOrderDetails extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  currentDateTime = moment();
  randomWorkOrderId = () => _.padStart(_.random(999999), '0');

  usersTableStore = new TableStore();

  shopId = ShopStore.currentShop.id;

  get shopUsers() {
    const users = _.map(this.usersTableStore.data, (id) =>
      UserStore.data.get(id)
    );
    return users;
  }

  state = {
    lineItems: [],
    currentCarIssues: [],
    selectedCarId: undefined,
    savingWorkOrder: false,
    currentWorkOrder: null,
    loadingWorkOrder: true,
    loadingWorkOrderLines: true,
    loadingInitial: true,
    title: '',
    initialFormValues: {
      woInvoiceNumber: this.randomWorkOrderId(),
      woCreatedBy: '',
      woSelectVehicle: undefined,
      woAssetType: 'Vehicle',
      woAssignedTo: '',
      woStatus: 'open',
      woRepairType: '',
      woRepairPriority: '',
      woIssueReportDate: this.currentDateTime,
      woStartDate: undefined,
      woCompletedDate: undefined,
      mileage: undefined,
      engineHours: undefined,
      vendor_id: undefined,
      tax: {
        type: 'absolute',
        value: 0,
      },
      misc: {
        type: 'absolute',
        value: 0,
      },
      woSource: '',
      rawData: '',
      laborsTotal: 0,
      partsTotal: 0,
      costsTotal: 0,
    },
    shopUsers: [],
    shopParts: [],
    carDetails: {},
    newPart: {
      name: '',
      description: '',
      number: '',
      cost: 0,
    },
    loadingSavingNewPart: false,
    currentLinePartIsBeingAdded: null,
    uploadedFiles: [],
    uploadedImages: [],
    wo_images: [],
    wo_documents: [],
    showUploadButton: true,
    isUploadWorkOrderModalVisible: false,
    isLoadingWorkOrderUpload: false,
    importedInformation: null,
    isDisabled: false,
    uploading: false,
    progress: 0,
    jsonGPT: null,
  };

  workOrderFormRef = createRef();
  lineItemsRef = createRef();
  costSummaryRef = createRef();
  imageUploaderContainerRef = {};
  // workOrderIssuesSummary = createRef();

  async componentDidMount() {
    // the desktop version is deprecated
    // redirect to the mobile version
    // check if add page
    const id = this.props?.match?.params?.id;
    if (id) {
      return this.props.history.push(`/work-order/${id}/edit`);
    }
    return this.props.history.push('/work-order/add');
  }

  loadShopUsers = async () => {
    await UserStore.fetchUsersForTable(
      {
        offset: 0,
        limit: 100,
        filter: null,
      },
      this.usersTableStore
    );
  };

  loadShopParts = async () => {
    try {
      const shopParts = await WorkOrderStore.getShopParts(
        ShopStore.currentShop.id,
        {
          include_deleted: true,
        }
      );
      this.setState({ shopParts });
    } catch (error) {
      console.log(error);
      return notification.error({
        message: 'Oops!',
        key: 'listShopParts',
        description: (
          <Container>Sorry, we couldn't fetch your Parts.</Container>
        ),
      });
    }
  };

  hydrateLineItem = (lineItem, index) => {
    return {
      ...lineItem,
      header: lineItem.title || `New Work Order Line ${index + 1}`,
      key: lineItem.id || lineItem.key || index + 1,
      laborTotal: parseFloat(lineItem.labour_cost || 0),
      partsTotal: parseFloat(lineItem.parts_cost || 0),
      vmrs_asm_code_id: lineItem.vmrs_asm_code_id,
      vmrs_sys_code_id: lineItem.vmrs_sys_code_id,
      parts: lineItem.parts
        ? lineItem.parts.map((p, i) => ({
            ...p,
            originalAmount: parseInt(p.amount || 0),
            key: parseInt(`${i + 1}${p.id}`),
            name: `Part ${parseInt(`${i + 1}${p.id}`)}`,
            markup: parseFloat(p.markup || 0),
            cost: parseFloat(p.cost || 0),
          }))
        : [],
      labours: lineItem.labours
        ? lineItem.labours.map((p, i) => ({
            ...p,
            key: parseInt(`${i + 1}${p.id}`),
            name: `Labor ${parseInt(`${i + 1}${p.id}`)}`,
            cost: parseFloat(p.cost || 0),
          }))
        : [],
      services: lineItem.services
        ? lineItem.services.map((p, i) => ({
            ...p,
            key: parseInt(`${i + 1}${p.id}`),
          }))
        : [],
      expanded: true,
    };
  };

  hydrateLineItems = (lineItems = []) => {
    return lineItems.map(this.hydrateLineItem);
  };

  get queryAssetId() {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    return queryParams.get('assetId');
  }

  load = async () => {
    this.setState({ loadingInitial: true });
    await this.loadShopUsers();
    this.loadShopParts();
    this.setState({ loadingInitial: false });

    const id = this.props.match.params.id;
    if (id) {
      this.setState({ title: 'Edit' });
      // get work order by ID
      try {
        this.setState({ loadingWorkOrder: true });
        const workOrder = await WorkOrderStore.getWorkOrderById(id);
        if (!workOrder) {
          throw new Error('Work order not found');
        }
        // remove the cache from Issues table... to refresh the list in other components
        PitstopTableCacheStore.deleteKey(`${workOrder.id_car}-wo-issues-list`);
        this.setState({
          selectedCarId: workOrder.id_car,
          currentWorkOrder: {
            ...workOrder,
          },
          lineItems: this.hydrateLineItems(workOrder.work_order_lines),
          initialFormValues: {
            woInvoiceNumber: workOrder.invoice_number,
            woCreatedBy:
              workOrder.migrate_source !== 'PitStop'
                ? workOrder.migrate_created_by
                : workOrder.created_by,
            woSelectVehicle: workOrder.id_car,
            woAssetType: workOrder.asset_type || 'Vehicle', // default to vehicle if asset type was not provided
            woAssignedTo: workOrder.assigned_to,
            woStatus: workOrder.status,
            woRepairType: workOrder.type,
            woRepairPriority: workOrder.priority,
            woIssueReportDate: workOrder.created_at
              ? moment(workOrder.created_at)
              : null,
            woStartDate: workOrder.started_at
              ? moment(workOrder.started_at)
              : null,
            woCompletedDate: workOrder.done_at
              ? moment(workOrder.done_at)
              : null,
            mileage: workOrder.mileage,
            engineHours: workOrder.engine_hours,
            vendor_id: workOrder.vendor_id,
            tax: {
              type: workOrder.tax_type || 'absolute',
              value: workOrder.tax || 0,
            },
            misc: {
              type: workOrder.misc_type || 'absolute',
              value: workOrder.misc || 0,
            },
            woSource: workOrder.migrate_source || 'PitStop',
            rawData:
              workOrder.migrate_source !== 'PitStop' ? workOrder.raw_data : '',
            laborsTotal: workOrder.laborsTotal || 0,
            partsTotal: workOrder.partsTotal || 0,
            costsTotal: workOrder.costsTotal || 0,
          },
          loadingWorkOrder: false,
          wo_images: workOrder.wo_files?.wo_images || [],
          wo_documents: workOrder.wo_files?.wo_documents || [],
          isDisabled: workOrder.migrate_source === 'TMT' ? true : false,
        });
      } catch (error) {
        // console.log(error);
        let friendlyMsg = 'Work order not found';
        this.setState({ loadingWorkOrder: false });
        return notification.error({
          message: 'Oops!',
          key: 'orderSaveError',
          description: <Container>{friendlyMsg}</Container>,
        });
      }
    } else {
      this.setState({ title: 'Add' });
      this.setState({ loadingWorkOrder: false });
      this.setState({ loadingWorkOrderLines: false });
      // check if query contains assetId
      if (this.queryAssetId) {
        this.setState({
          selectedCarId: +this.queryAssetId,
          initialFormValues: {
            ...this.state.initialFormValues,
            woSelectVehicle: +this.queryAssetId,
          },
        });
      }
    }
  };

  goBack = () => {
    if (this.queryAssetId) {
      return this.props.history.push(
        `/trailer/${this.queryAssetId}/work-orders`
      );
    }
    return this.props.history.push('/work-order');
  };

  onChangeCurrentCarIssues = (currentCarIssues) => {
    // this.setState({ currentCarIssues: currentCarIssues });
  };

  onCarSelect = async (selectedCarId) => {
    this.setState({ selectedCarId: +selectedCarId });
    // load car issues
    try {
      const carIssues = await IssueStore.getCarIssuesList(
        {
          offset: 0,
          limit: 100,
          filter: {},
          sort: null,
          includeHistory: false,
        },
        selectedCarId
      );
      this.setState({
        currentCarIssues: carIssues,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        key: 'carIssues',
        description: <Container>{error.message}</Container>,
      });
    }
  };

  onChangeTaxType = (type) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        tax: {
          ...this.state.initialFormValues.tax,
          type,
        },
      },
    });
  };

  onChangeTaxValue = (value) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        tax: {
          ...this.state.initialFormValues.tax,
          value,
        },
      },
    });
  };

  onChangeMiscType = (type) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        misc: {
          ...this.state.initialFormValues.misc,
          type,
        },
      },
    });
  };

  onChangeMiscValue = (value) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        misc: {
          ...this.state.initialFormValues.misc,
          value,
        },
      },
    });
  };

  onAddLineItem = (override) => {
    // Logger.log('running onAddLineItem function');
    const key = this.state.lineItems.length + 1;
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    newLineItems.push({
      header: `New Work Order Line ${key}`,
      key: key,
      title: '',
      description: '',
      laborTotal: 0,
      miscTotal: 0,
      partsTotal: 0,
      labours: [],
      misc: [],
      parts: [],
      services: [],
      expanded: true,
      ...override,
    });
    this.setState({
      lineItems: newLineItems,
    });
  };

  onChangeLineItemField = (lineKey, newVal, fieldName) => {
    // Logger.log('running onChangeLineItemField');
    const indexOfLineItem = this.state.lineItems.findIndex(
      (item) => item.key === lineKey
    );
    const lineItem = this.state.lineItems.find((item) => item.key === lineKey);
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    lineItem[fieldName] = newVal;
    newLineItems.splice(indexOfLineItem, 1, {
      ...lineItem,
    });
    this.setState({
      lineItems: newLineItems,
    });
  };

  onRemoveLineItem = (itemKey) => {
    // Logger.log('running onRemoveLineItem');
    const indexOfLineItem = this.state.lineItems.findIndex(
      (item) => item.key === itemKey
    );
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    newLineItems.splice(indexOfLineItem, 1);
    this.setState({
      lineItems: newLineItems,
    });
  };

  createWorkOrder = async (payload) => {
    try {
      const response = await WorkOrderStore.createWorkOrderOnCustomEndpoint({
        workOrder: {
          id_car: +payload.woSelectVehicle,
          asset_type: payload.woAssetType,
          id_shop: ShopStore.currentShop.id,
          status: payload.woStatus,
          type: payload.woRepairType,
          priority: payload.woRepairPriority,
          invoice_number: payload.woInvoiceNumber,
          assigned_to: +payload.woAssignedTo,
          created_by: +payload.woCreatedBy,
          tax: this.state.initialFormValues?.tax?.value || null,
          tax_type: this.state.initialFormValues?.tax?.type || null,
          misc: this.state.initialFormValues?.misc?.value || null,
          misc_type: this.state.initialFormValues?.misc?.type || null,
          migrate_source: 'PitStop',
          started_at: payload.woStartDate
            ? moment(payload.woStartDate).toDate()
            : null,
          done_at: payload.woCompletedDate
            ? moment(payload.woCompletedDate).toDate()
            : null,
          mileage: payload.mileage,
          engine_hours: payload.engineHours,
          vendor_id: payload.vendor_id,
          wo_files: payload.wo_files,
          laborsTotal: this.costSummaryRef.summaryItems.find(
            (item) => item.description === 'Labors'
          ).amount,
          partsTotal: this.costSummaryRef.summaryItems.find(
            (item) => item.description === 'Parts'
          ).amount,
          costsTotal: this.costSummaryRef.state.totalAmount,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  updateWorkOrder = async (payload) => {
    try {
      const response = await WorkOrderStore.updateWorkOrderOnCustomEndpoint({
        id: this.state.currentWorkOrder.id,
        id_car: +payload.woSelectVehicle,
        asset_type: payload.woAssetType,
        status: payload.woStatus,
        type: payload.woRepairType,
        priority: payload.woRepairPriority,
        invoice_number: payload.woInvoiceNumber,
        assigned_to: +payload.woAssignedTo,
        created_by: +payload.woCreatedBy,
        tax: this.state.initialFormValues?.tax?.value || null,
        tax_type: this.state.initialFormValues?.tax?.type || null,
        misc: this.state.initialFormValues?.misc?.value || null,
        misc_type: this.state.initialFormValues?.misc?.type || null,
        started_at: payload.woStartDate
          ? moment(payload.woStartDate).toDate()
          : null,
        done_at: payload.woCompletedDate
          ? moment(payload.woCompletedDate).toDate()
          : null,
        mileage: payload.mileage,
        engine_hours: payload.engineHours,
        vendor_id: payload.vendor_id,
        wo_files: payload.wo_files,
        laborsTotal: this.costSummaryRef.summaryItems.find(
          (item) => item.description === 'Labors'
        ).amount,
        partsTotal: this.costSummaryRef.summaryItems.find(
          (item) => item.description === 'Parts'
        ).amount,
        costsTotal: this.costSummaryRef.state.totalAmount,
      });

      // Check if we need to Resolve a DVIR
      if (
        payload.woStatus === 'resolved' &&
        payload.woRepairType === 'driver_identified' &&
        this.state.currentWorkOrder.work_order_dvirs.length > 0
      ) {
        if (!CurrentUserStore.user.email) {
          throw new Error('An user is required to resolve the DVIR');
        }

        const samsaraUsers = await CarStore.fetchSamsaraUsers(
          ShopStore.currentShop.id
        );
        // Select the current user email if it's in the list
        const currentUserEmail = CurrentUserStore.user.email;
        const samsaraUserResolving = samsaraUsers.find(
          (user) =>
            String(user.email).toLowerCase() ===
            String(currentUserEmail).toLowerCase()
        );

        let defects = [];
        let dvirId = this.state.currentWorkOrder.work_order_dvirs[0].dvir_id;
        this.state.currentWorkOrder.work_order_dvirs.forEach((dvirDefects) => {
          defects.push({ id: dvirDefects.defect_id.toString() });
        });

        if (dvirId && defects.length > 0) {
          const defectsResolved = await CarStore.resolveDVIRDefects({
            shopId: ShopStore.currentShop.id,
            dvirId: dvirId,
            defects: defects,
            mechanicNotes: 'Resolved by Pitstop',
            resolvedBySamsaraUserId:
              samsaraUserResolving?.id || currentUserEmail,
          });
          AppStore.addNotification(
            `${defectsResolved.length} defects resolved successfully`
          );
        }
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  saveWorkOrder = async () => {
    return new Promise((resolve, reject) => {
      this.workOrderFormRef.props.form.validateFields(async (err, payload) => {
        if (err) {
          this.setState({ savingWorkOrder: false });
          AppStore.removeLoading('Saving Work Order...');
          let friendlyMsg = '';
          const errorKeys = Object.keys(err);
          if (
            errorKeys &&
            errorKeys[0] &&
            err[errorKeys[0]] &&
            err[errorKeys[0]].errors &&
            err[errorKeys[0]].errors.length &&
            err[errorKeys[0]].errors[0].message
          ) {
            friendlyMsg = err[errorKeys[0]].errors[0].message;
          } else {
            friendlyMsg = 'Error on saving Work Order';
          }
          return notification.error({
            message: 'Oops!',
            key: 'orderSaveError',
            description: <Container>{friendlyMsg}</Container>,
          });
        }
        try {
          let workOrder;
          await this.uploadWorkOrderImages();
          await this.uploadWorkOrderFiles();
          if (this.state.currentWorkOrder) {
            const modifiedPayload = {
              ...payload,
              wo_files: {
                wo_images: this.state.wo_images,
                wo_documents: this.state.wo_documents,
              },
            };
            workOrder = await this.updateWorkOrder(modifiedPayload);
          } else {
            const modifiedPayload = {
              ...payload,
              wo_files: {
                wo_images: this.state.wo_images,
                wo_documents: this.state.wo_documents,
              },
            };
            workOrder = await this.createWorkOrder(modifiedPayload);
          }
          resolve(workOrder);
        } catch (error) {
          reject(error);
        }
      });
    });
  };

  formatWorkOrderLines = async (lines) => {
    return await Promise.all(
      lines.map(async (workOrderLine) => {
        const updatingData = {};
        if (workOrderLine.id) {
          updatingData.id = workOrderLine.id;
        }
        // remove empty parts (without a part selected)
        const parts = workOrderLine.parts.filter((p) => p.id_vehicle_part);
        // remove empty labours (without a labour selected)
        const labours = workOrderLine.labours.filter((l) => l.id_labour);

        // if vmrs_sys_code_id is provided, find the vmrs record
        if (workOrderLine.vmrs_sys_code_id) {
          const response = await WorkOrderStore.getVmrsCodeById(
            workOrderLine.vmrs_sys_code_id
          );
          const vmrsSysCode = response?.data;
          if (vmrsSysCode) {
            updatingData.vmrs_sys_text = vmrsSysCode.text;
            updatingData.vmrs_sys_code = vmrsSysCode.sys_text;
          }
        }

        // if vmrs_asm_code_id is provided, find the vmrs record
        if (workOrderLine.vmrs_asm_code_id) {
          const response = await WorkOrderStore.getVmrsCodeById(
            workOrderLine.vmrs_asm_code_id
          );
          const vmrsAsmCode = response?.data;
          if (vmrsAsmCode) {
            updatingData.vmrs_asm_text = vmrsAsmCode.text;
            updatingData.vmrs_asm_code = vmrsAsmCode.asm_text;
          }
        }

        return {
          ...updatingData,
          title:
            workOrderLine.title || `New Work Order Line ${workOrderLine.key}`,
          description: workOrderLine.description,
          vmrs_sys_code_id: workOrderLine.vmrs_sys_code_id,
          vmrs_asm_code_id: workOrderLine.vmrs_asm_code_id,
          labour_cost: parseFloat(workOrderLine.laborTotal),
          parts_cost: parseFloat(
            parseFloat(workOrderLine.partsTotal).toFixed(2)
          ),
          parts: parts.map((p) => ({
            ...p,
            id_vehicle_part: p.id_vehicle_part,
            amount: p.amount ? parseInt(p.amount) : 0,
            originalAmount: p.originalAmount ? parseInt(p.originalAmount) : 0,
            markup: p.markup ? parseFloat(p.markup) : 0,
            cost: p.cost ? parseFloat(p.cost) : 0,
          })),
          labours: labours.map((l) => ({
            ...l,
            id_labour: l.id_labour,
            hours: l.hours || 0,
            cost: parseFloat(l.cost || 0),
          })),
          services: workOrderLine.services.map((i) => ({
            ...i,
            issue: i.issue,
            id_vehicle_service: +i.id_vehicle_service,
          })),
        };
      })
    );
  };

  saveWorkOrderLines = async (workOrder) => {
    // Logger.log('running saveWorkOrderLines');
    try {
      this.setState({ savingWorkOrderLines: true });

      const formattedWorkOrderLines = await this.formatWorkOrderLines(
        this.state.lineItems
      );

      const payloadToSave = {
        workOrderLines: formattedWorkOrderLines,
      };

      const response = await WorkOrderStore.saveWorkOrderLinesOnCustomEndpoint(
        +workOrder.id,
        payloadToSave
      );

      // update parts unit cost on shop
      const lineItem = _.filter(this.state.lineItems, (lineItem) => {
        return lineItem.parts && lineItem.parts.length;
      });
      for (const lineItemWithParts of lineItem) {
        for (const part of lineItemWithParts.parts) {
          await WorkOrderStore.updatePartOnCustomEndpoint({
            id: part.id_vehicle_part,
            cost: part.cost,
            markup: part.markup,
          });
        }
      }

      // update parts stock
      const workOrderLines = _.filter(formattedWorkOrderLines, (lineItem) => {
        return lineItem.parts && lineItem.parts.length;
      });
      for (const lineItemWithParts of workOrderLines) {
        for (const part of lineItemWithParts.parts) {
          await WorkOrderStore.updatePartStock(part);
        }
      }

      return response;
    } catch (error) {
      throw error;
    } finally {
      this.setState({ savingWorkOrderLines: false });
    }
  };

  onClickSave = async () => {
    try {
      // save the work order first...
      const isNewWorkOrder = !this.state.currentWorkOrder;
      this.setState({ savingWorkOrder: true });
      AppStore.addLoading('Saving Work Order...');
      const newWorkOrder = await this.saveWorkOrder();
      AppStore.removeLoading('Saving Work Order...');
      if (!newWorkOrder) {
        return;
      }

      // save the newWorkOrder on current state
      this.setState({
        currentWorkOrder: {
          ...newWorkOrder,
        },
        // lineItems: this.hydrateLineItems(newWorkOrder.work_order_lines),
      });

      // if there's no errors, save the work order lines
      AppStore.addLoading('Saving Work Order Lines...');
      await this.saveWorkOrderLines(newWorkOrder);
      AppStore.removeLoading('Saving Work Order Lines...');

      // WorkOrderStore.addWorkOrderToStore(newWorkOrder.id, newWorkOrder, true);
      PitstopTableCacheStore.deleteKey(`${this.shopId}-work-order-list`);
      WorkOrderStore.clearShopWorkOrdersCache(this.shopId);

      this.setState({ savingWorkOrder: false });

      notification.success({
        message: 'Work order saved',
        key: 'orderUpdated',
        description: (
          <Container>
            You can always click on the work order for more details and to make
            edits
          </Container>
        ),
      });

      if (this.queryAssetId) {
        return this.props.history.push(
          `/trailer/${this.queryAssetId}/work-orders`
        );
      }
      if (isNewWorkOrder) {
        this.props.history.push(`/work-order/${newWorkOrder.id}/edit`);
      } else {
        this.load();
      }
    } catch (error) {
      let friendlyMsg =
        'Sorry, we had some problems to save the Work Order. Try again later';
      Logger.log(error.message);
      if (error && error.message && JSON.parse(error.message)) {
        const errorObj = JSON.parse(error.message);
        friendlyMsg = _.isArray(errorObj.message)
          ? errorObj.message[0]
          : errorObj.message;
      }
      notification.error({
        message: 'Oops!',
        key: 'orderSaveError',
        description: <Container>{friendlyMsg}</Container>,
      });
      AppStore.removeLoading('Saving Work Order...');
      AppStore.removeLoading('Saving Work Order Lines...');
      this.setState({ savingWorkOrder: false });
    }
  };

  uploadWorkOrderImages = async () => {
    const fileUrls = [];
    const uFiles = this.state.uploadedImages;

    for (const file of uFiles) {
      const fd = new FormData();
      fd.append('singleInputFileName', file.file);
      try {
        const data = await webServiceProvider.postFormData(
          'dashboard/v1/uploadimage',
          fd
        );
        if (data.fileUrl) {
          fileUrls.push(data.fileUrl);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (fileUrls.length > 0) {
      this.setState(
        (prevState) => ({
          wo_images: [...prevState.wo_images, ...fileUrls],
        }),
        () => {
          console.log('Updated wo_images:', this.state.wo_images);
        }
      );
    }
  };

  uploadWorkOrderFiles = async () => {
    const fileUrls = [];
    const uFiles = this.state.uploadedFiles;

    for (const file of uFiles) {
      const fd = new FormData();
      fd.append('singleInputFileName', file.file);
      try {
        const data = await webServiceProvider.postFormData(
          'dashboard/v1/uploadimage',
          fd
        );
        if (data.fileUrl) {
          fileUrls.push(data.fileUrl);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (fileUrls.length > 0) {
      this.setState(
        (prevState) => ({
          wo_documents: [...prevState.wo_documents, ...fileUrls],
        }),
        () => {
          console.log('Updated wo_documents:', this.state.wo_documents);
        }
      );
    }
  };

  // show modal to add new Part
  showModalToAddPart = (partName) => {
    this.setState({
      newPart: {
        name: '',
        description: partName,
        number: '',
        cost: 0,
      },
    });
    AppStore.openModals.set(ADD_PART_MODAL, true);
  };

  /**
   * Add a new part to the shop
   * @param {object} payload
   * @param {object} options
   * @return {Promise<object|null>} newPart
   */
  addNewPart = async (
    { description = '', number = '', cost = 0, addToInventory = false },
    { showSuccessAlert = true, hideModal = true, lineItemToAddPart = null }
  ) => {
    const getName = () => (number ? `${number} - ${description}` : description);
    const payload = {
      id_shop: ShopStore.currentShop.id,
      name: getName(),
    };
    const newPart = await WorkOrderStore.createPartOnCustomEndpoint(payload);
    // update the new part with the description and number
    await WorkOrderStore.updatePartOnCustomEndpoint({
      id: newPart.id,
      description,
      number,
      cost,
      addToInventory,
    });
    if (showSuccessAlert) {
      notification.success({
        message: 'Part created',
        key: `newPartCreated${newPart.id}`,
        description: (
          <Container>The new part has been added to your account</Container>
        ),
      });
    }
    if (hideModal) {
      AppStore.openModals.set(ADD_PART_MODAL, false);
    }
    await this.loadShopParts();
    if (lineItemToAddPart) {
      const lineItemRef = this.lineItemsRef.workOrderLinesDetailsRefs[
        lineItemToAddPart
      ];
      if (lineItemRef) {
        lineItemRef.onAddExistingPart({
          amount: 1,
          id_vehicle_part: newPart.id,
          name: getName(),
          markup: 0,
          cost: parseFloat(cost),
        });
      }
    }

    return newPart;
  };

  onSubmitAddPartModal = async () => {
    try {
      this.setState({
        loadingSavingNewPart: true,
      });
      const {
        description,
        number,
        cost,
        addToInventory = false,
      } = this.state.newPart;
      await this.addNewPart(
        {
          description,
          number,
          cost,
          addToInventory,
        },
        {
          showSuccessAlert: true,
          hideModal: true,
          lineItemToAddPart: this.state.currentLinePartIsBeingAdded,
        }
      );
    } catch (error) {
      let friendlyMsg =
        'Sorry, we had some problems to save the Part. Try again later';
      console.log(error.message);
      if (error && error.message && JSON.parse(error.message)) {
        const errorObj = JSON.parse(error.message);
        friendlyMsg = _.isArray(errorObj.message)
          ? errorObj.message[0]
          : errorObj.message;
      }
      notification.error({
        message: 'Oops!',
        key: 'orderSaveError',
        description: <Container>{friendlyMsg}</Container>,
      });
    } finally {
      this.setState({
        loadingSavingNewPart: false,
      });
    }
  };

  onAddNewPart = async (partName = '', lineKey = null) => {
    this.setState({
      currentLinePartIsBeingAdded: lineKey,
    });
    this.showModalToAddPart(partName);
  };

  onUpdateLaborItem = (lineItemKey, laborKey, newVal) => {
    // Logger.log('running onUpdateLaborItem');
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    const labour = lineItem.labours.find((labour) => labour.key === laborKey);
    _.merge(labour, newVal);
    // refresh cost
    const indexOfLineItem = this.state.lineItems.findIndex(
      (item) => item.key === lineItemKey
    );
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    lineItem.laborTotal = _.sumBy(lineItem.labours, (labour) => {
      return parseFloat(labour.cost || 0) * parseFloat(labour.hours || 0);
    });
    newLineItems.splice(indexOfLineItem, 1, {
      ...lineItem,
    });
    this.setState({
      lineItems: newLineItems,
    });
  };

  onUpdateMiscItem = (lineItemKey, miscKey, newVal) => {
    Logger.log('running onUpdateMiscItem');
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    const misc = lineItem.misc.find((misc) => misc.key === miscKey);
    _.merge(misc, newVal);
    // refresh cost
    const indexOfLineItem = this.state.lineItems.findIndex(
      (item) => item.key === lineItemKey
    );
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    lineItem.miscTotal = _.sumBy(lineItem.misc, (misc) => {
      return parseFloat(misc.cost || 0);
    });
    newLineItems.splice(indexOfLineItem, 1, {
      ...lineItem,
    });
    this.setState({
      lineItems: newLineItems,
    });
  };

  onUpdatePartItem = (lineItemKey, partKey, newVal, changedField) => {
    // Logger.log('running onUpdatePartItem');
    if (changedField === 'id_vehicle_part') {
      const shopPart = this.state.shopParts.find(
        (part) => part.id === newVal.id_vehicle_part
      );
      if (shopPart && shopPart.cost !== null && shopPart.cost !== undefined) {
        const partCost = parseFloat(shopPart.cost);
        newVal.cost = partCost;
      }
    }
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    const part = lineItem.parts.find((part) => part.key === partKey);
    _.merge(part, newVal);
    // refresh cost
    lineItem.partsTotal = _.sumBy(lineItem.parts, (part) => {
      const cost = part.cost ? parseFloat(part.cost) : 0;
      const markup = part.markup ? parseFloat(part.markup) : 0;
      const amount = part.amount ? parseInt(part.amount) : 0; // formerly parseInt but front-end allowed and calculated float, so this is just to reflect that
      return (cost + (cost * markup) / 100) * amount;
    });
  };

  onSelectSystemCode = (lineItemKey, systemCode) => {
    // update the current vmrs_sys_code_id on the lineItem
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    lineItem.vmrs_sys_code_id = systemCode;
    lineItem.vmrs_sys_code = null;
    lineItem.vmrs_sys_text = null;
    this.setState({
      lineItems: this.state.lineItems,
    });
  };

  onSelectAsmCode = (lineItemKey, asmCode) => {
    // update the current vmrs_asm_code_id on the lineItem
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    lineItem.vmrs_asm_code_id = asmCode;
    lineItem.vmrs_asm_code = null;
    lineItem.vmrs_asm_text = null;
    this.setState({
      lineItems: this.state.lineItems,
    });
  };

  onUpdateServiceItem = (lineItemKey, serviceKey, newVal) => {
    // Logger.log('running onUpdateServiceItem');
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    const service = lineItem.services.find(
      (service) => service.key === serviceKey
    );
    _.merge(service, newVal);
  };

  onChangeCollapseItems = (activeLineItemsKeys) => {
    const { lineItems } = this.state;
    lineItems.forEach((lineItem) => {
      if (activeLineItemsKeys.includes(`${lineItem.key}`)) {
        lineItem.expanded = true;
      } else {
        lineItem.expanded = false;
      }
    });
    this.forceUpdate();
  };

  onGetCarDetails = (carDetails) => {
    this.setState({
      carDetails,
    });
  };

  handleExportClick = async (type) => {
    const workOrderId = this.props.match.params.id;

    switch (type) {
      case 'print':
        try {
          AppStore.addLoading('Getting Work Order Report');
          await WorkOrderStore.printWorkOrder(workOrderId);
        } catch (error) {
          console.error(error);
          AppStore.addError(
            'Unknown error to generate the Work Order. Please try again later'
          );
        } finally {
          AppStore.removeLoading('Getting Work Order Report');
        }
        break;
      case 'download':
        try {
          AppStore.addLoading('Getting Work Order Report');
          await WorkOrderStore.downloadWorkOrder(workOrderId);
        } catch (error) {
          console.error(error);
          AppStore.addError(
            'Unknown error to generate the Work Order. Please try again later'
          );
        } finally {
          AppStore.removeLoading('Getting Work Order Report');
        }
        break;
      default:
        break;
    }
  };

  handleFileUpload = (file, additionalData) => {
    console.log('Before update wo_documents:', this.state.wo_documents);
    this.setState(
      (prevState) => {
        let updatedDocuments = prevState.uploadedFiles;
        let woDocumentsUpdated = prevState.wo_documents;

        if (additionalData === 'removed') {
          updatedDocuments = prevState.uploadedFiles.filter(
            (item) => item.file.uid !== file.uid || item.file.name !== file.name
          );
          woDocumentsUpdated = prevState.wo_documents.filter(
            (documentUrl) => documentUrl !== file.url
          );
        } else {
          updatedDocuments = [
            ...prevState.uploadedFiles,
            { file, additionalData },
          ];
        }

        return {
          uploadedFiles: updatedDocuments,
          wo_documents: woDocumentsUpdated,
        };
      },
      () => {
        console.log(
          'Updated state after file upload:',
          this.state.uploadedFiles
        );
        console.log('Updated wo_documents:', this.state.wo_documents);
      }
    );

    // console.log('Document uploaded:', file);
    // console.log('Additional data:', additionalData);
    // console.log('State data:', this.state.uploadedFiles);
  };

  handleImageUpload = (file, additionalData) => {
    console.log('Before update wo_images:', this.state.wo_images);
    this.setState(
      (prevState) => {
        let updatedImages = prevState.uploadedImages;
        let woImagesUpdated = prevState.wo_images;

        if (additionalData === 'removed') {
          // Remove file from uploadedImages based on uid and name
          updatedImages = prevState.uploadedImages.filter(
            (item) => item.file.uid !== file.uid || item.file.name !== file.name
          );
          woImagesUpdated = prevState.wo_images.filter(
            (imageUrl) => imageUrl !== file.url
          );
        } else {
          updatedImages = [
            ...prevState.uploadedImages,
            { file, additionalData },
          ];
        }

        return {
          uploadedImages: updatedImages,
          wo_images: woImagesUpdated,
        };
      },
      () => {
        console.log(
          'Updated state after image upload:',
          this.state.uploadedImages
        );
        console.log('Updated wo_images:', this.state.wo_images);
      }
    );

    // console.log('Image uploaded:', file);
    // console.log('Additional data:', additionalData);
  };

  loadWorkOrderWithExtractedInformation = async (data) => {
    this.setState({ importedInformation: JSON.stringify(data, null, 4) });
    const clearStr = (str) => deburr(kebabCase(str)).toLowerCase();
    const isFound = (str) => str && clearStr(str) !== 'not-found';
    try {
      AppStore.addLoading('Loading Work Order');
      const extractedInitialFormValues = {
        woRepairType: 'repair',
      };
      // wo #
      if (isFound(data['Work Order Number'])) {
        extractedInitialFormValues.woInvoiceNumber = data['Work Order Number'];
      }
      // start date
      if (isFound(data['Service Date'])) {
        if (moment(data['Service Date'], 'MM/DD/YYYY').isValid()) {
          extractedInitialFormValues.woStartDate = moment(
            data['Service Date'],
            'MM/DD/YYYY'
          );
        } else if (moment(data['Service Date'], 'YYYY-MM-DD').isValid()) {
          extractedInitialFormValues.woStartDate = moment(
            data['Service Date'],
            'YYYY-MM-DD'
          );
        }
      }
      // vehicle
      if (isFound(data['Vehicle Identifier'])) {
        const { result: vehicle } = await CarStore.getShopCarByVin(
          ShopStore.currentShop.id,
          data['Vehicle Identifier']
        );
        if (vehicle.length) {
          extractedInitialFormValues.woSelectVehicle = vehicle[0].id;
        } else {
          extractedInitialFormValues.extractedVin = data['Vehicle Identifier'];
        }
      }
      // Tax
      if (isFound(data['Tax'])) {
        extractedInitialFormValues.tax = {
          type: 'absolute',
          value: parseFloat(data['Tax']),
        };
      }
      if (
        isArray(data['Service Line Items']) &&
        !isEmpty(data['Service Line Items'])
      ) {
        const serviceLineItems = data['Service Line Items'];
        for (const serviceLineItem of serviceLineItems) {
          if (isFound(serviceLineItem['name'])) {
            const lineKey = this.state.lineItems.length + 1;
            this.onAddLineItem({
              key: lineKey,
              title: serviceLineItem['name'],
              description: serviceLineItem['description'] || '',
              labour_cost: 0,
              parts_cost: 0,
              parts: [],
              labours: [],
            });
            // check if has parts and add it too
            if (serviceLineItem['parts'] && isArray(serviceLineItem['parts'])) {
              // for each part, search that part based on it's name. If not found, create one and get the data back.
              for (const part of serviceLineItem['parts']) {
                if (!isFound(part['name'])) {
                  // if the part name is not found, skip it
                  continue;
                }
                const partName = part['name'];
                const partCost = isFound(part['unit_cost'])
                  ? parseFloat(part['unit_cost'])
                  : 0;
                const partNumber = isFound(part['part_number'])
                  ? part['part_number']
                  : '';
                const partMarkup = isFound(part['mark_up'])
                  ? parseFloat(part['mark_up'])
                  : 0;
                const partQuantity = isFound(part['quantity'])
                  ? parseInt(part['quantity'])
                  : 0;
                // search that part name in the shop/subshop parts
                let foundPart = this.state.shopParts.find((shopPart) => {
                  return (
                    clearStr(shopPart.name) ===
                    clearStr(`${partNumber} - ${partName}`)
                  );
                });
                if (!foundPart) {
                  // if not found, create a new part
                  foundPart = await this.addNewPart(
                    {
                      description: partName,
                      number: partNumber || '',
                      cost: partCost,
                      addToInventory: false,
                    },
                    {
                      showSuccessAlert: false,
                      hideModal: false,
                      lineItemToAddPart: null,
                    }
                  );
                }
                // access the line item using the key and add the part
                const lineItemRef = this.lineItemsRef.workOrderLinesDetailsRefs[
                  lineKey
                ];
                if (lineItemRef) {
                  lineItemRef.onAddExistingPart({
                    amount: partQuantity,
                    id_vehicle_part: foundPart.id,
                    name: foundPart.name,
                    markup: partMarkup,
                    cost: partCost,
                  });
                }
              }
            }
          }
        }
      }
      // const hydratedLineItems = this.hydrateLineItems(lineItems);
      this.setState({
        initialFormValues: {
          ...this.state.initialFormValues,
          ...extractedInitialFormValues,
        },
      });
    } catch (error) {
      Logger.error(error);
    } finally {
      AppStore.removeLoading('Loading Work Order');
    }
  };

  handleWorkOrderUpload = async (fileList) => {
    if (!fileList.length) {
      return;
    }
    try {
      this.setState({ uploading: true, progress: 0 });
      const firstFile = fileList[0].originFileObj;
      // pass the first file to the ImageUploadContainer
      this.imageUploaderContainerRef.handlePreview(firstFile);
      // convert first file to base64
      const reader = new FileReader();
      reader.readAsDataURL(firstFile);
      reader.onload = async (e) => {
        const base64 = e.target.result;
        const withoutHeaders = base64.split(',')[1];
        // upload the base64 to the server
        try {
          this.setState({ isLoadingWorkOrderUpload: true, progress: 75 });
          this.setState({ isLoadingWorkOrderUpload: true });
          const data = await WorkOrderStore.workOrderImgOcr({
            base64: withoutHeaders,
          });
          this.setState(
            {
              progress: 99,
              isUploadWorkOrderModalVisible: false,
              jsonGPT: data,
            },
            () => {
              this.loadWorkOrderWithExtractedInformation(data);
            }
          );
          this.setState({ uploading: false, progress: 100 });
        } catch (error) {
          Logger.log('Error:', error);
        } finally {
          this.setState({
            isLoadingWorkOrderUpload: false,
            uploading: false,
            progress: 0,
          });
        }
      };
    } catch (error) {
      Logger.error(error);
    }
  };

  render() {
    const {
      showUploadButton,
      isUploadWorkOrderModalVisible,
      uploading,
      progress,
    } = this.state;

    const contextValue = {
      state: this.state,
    };

    return this.state.loadingWorkOrder || this.state.loadingInitial ? (
      <LoadingContainer>
        <Spin tip="Loading..." />
      </LoadingContainer>
    ) : (
      <WorkOrderProvider value={contextValue}>
        <DealershipChooser />
        <ActionsWrapper>
          <h1>{this.state.title} Work Order</h1>
          {showUploadButton && (
            <Row gutter={16}>
              <Col span={24}>
                <UploadButton
                  icon="upload"
                  onClick={() => {
                    this.setState({ isUploadWorkOrderModalVisible: true });
                  }}
                >
                  Scan Work Order
                </UploadButton>
                <UploadImageModal
                  isVisible={isUploadWorkOrderModalVisible}
                  isLoading={this.state.isLoadingWorkOrderUpload}
                  onUpload={this.handleWorkOrderUpload}
                  onCancel={() => {
                    this.setState({ isUploadWorkOrderModalVisible: false });
                  }}
                  uploading={uploading}
                  progress={progress}
                />
              </Col>
            </Row>
          )}
          {this.state.currentWorkOrder && (
            <ActionButtonsWrapper>
              <Button
                type="primary"
                ghost
                onClick={() => this.handleExportClick('download')}
              >
                <Icon type="download" />
                Download
              </Button>
              <Button
                type="primary"
                ghost
                onClick={() => this.handleExportClick('print')}
              >
                <Icon type="printer" />
                Print
              </Button>
            </ActionButtonsWrapper>
          )}
        </ActionsWrapper>
        {this.state.importedInformation && (
          <Row style={{ marginTop: '20px' }}>
            <Col span={18} offset={3}>
              <Collapse defaultActiveKey={[]} onChange={() => {}}>
                <Panel header="Imported Information" key="1">
                  <ImportedInformation>
                    <pre>{this.state.importedInformation}</pre>
                  </ImportedInformation>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={18} offset={3}>
            <Wrapper>
              <WorkOrderForm
                childRef={(ref) => (this.workOrderFormRef = ref)}
                workOrder={this.state.initialFormValues}
                shopUsers={this.shopUsers}
                onCarSelect={this.onCarSelect}
                onGetCarDetails={this.onGetCarDetails}
                isDisabled={this.state.isDisabled}
              />
            </Wrapper>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col span={18} offset={3}>
            <Wrapper>
              <WorkOrderLineItems
                childRef={(ref) => (this.lineItemsRef = ref)}
                lineItems={this.state.lineItems}
                shopUsers={this.shopUsers}
                currentCarIssues={this.state.currentCarIssues}
                shopParts={this.state.shopParts}
                onAddLineItem={() => this.onAddLineItem()}
                onRemoveLineItem={this.onRemoveLineItem}
                onChangeLineItemField={this.onChangeLineItemField}
                onAddNewPart={this.onAddNewPart}
                onUpdateLaborItem={this.onUpdateLaborItem}
                onUpdateMiscItem={this.onUpdateMiscItem}
                onUpdatePartItem={this.onUpdatePartItem}
                onUpdateServiceItem={this.onUpdateServiceItem}
                onChangeCollapseItems={this.onChangeCollapseItems}
                onSelectSystemCode={this.onSelectSystemCode}
                onSelectAsmCode={this.onSelectAsmCode}
                isDisabled={this.state.isDisabled}
              />
            </Wrapper>
            <ImageUploader
              childRef={(ref) => {
                this.imageUploaderContainerRef = ref;
              }}
              onImageUploaded={this.handleImageUpload}
              wo_files={
                this.state.currentWorkOrder?.wo_files || {
                  wo_images: [],
                  wo_documents: [],
                }
              }
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '12px' }}>
          <Col span={9} offset={3} style={documentUploaderStyle}>
            <DocumentUploader
              onFileUploaded={this.handleFileUpload}
              wo_files={
                this.state.currentWorkOrder?.wo_files || {
                  wo_images: [],
                  wo_documents: [],
                }
              }
            />
          </Col>
          <Col style={{ paddingLeft: '20px' }} span={9}>
            <Wrapper>
              <WorkOrderCostSummary
                childRef={(ref) => (this.costSummaryRef = ref)}
                lineItems={this.state.lineItems}
                rawData={this.state.jsonGPT}
                tax={this.state.initialFormValues.tax}
                misc={this.state.initialFormValues.misc}
                currentWorkOrder={this.state.currentWorkOrder}
                onChangeTaxType={this.onChangeTaxType}
                onChangeTaxValue={this.onChangeTaxValue}
                onChangeMiscType={this.onChangeMiscType}
                onChangeMiscValue={this.onChangeMiscValue}
              />
            </Wrapper>
          </Col>
        </Row>
        <ActionsWrapper>
          <div></div>
          <ActionButtonsWrapper>
            <PitstopButton type="link" onClick={this.goBack}>
              Cancel
            </PitstopButton>
            <PitstopButton
              loading={this.state.savingWorkOrder}
              onClick={this.onClickSave}
              disabled={this.state.isDisabled}
            >
              Save
            </PitstopButton>
          </ActionButtonsWrapper>
        </ActionsWrapper>

        <StyledModal
          id={ADD_PART_MODAL}
          title={'Add New Part'}
          width={640}
          okText="Save"
          footer={
            <>
              <Button
                key="back"
                onClick={() => {
                  AppStore.openModals.set(ADD_PART_MODAL, false);
                }}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  this.onSubmitAddPartModal();
                }}
                loading={this.state.loadingSavingNewPart}
              >
                Save
              </Button>
            </>
          }
        >
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Text strong>Part Number</Text>
              <Input
                style={{ width: '100%' }}
                value={this.state.newPart.number}
                onChange={(e) => {
                  this.setState({
                    newPart: {
                      ...this.state.newPart,
                      number: e.target.value,
                    },
                  });
                }}
              />
              {String(this.state.newPart.number).trim() &&
                this.state.shopParts.find(
                  (shopPart) =>
                    String(shopPart.number).trim() ===
                    String(this.state.newPart.number).trim()
                ) && (
                  <Typography.Text type="danger">
                    A part with this number already exists in your inventory. To
                    avoid duplicates, increase its stock count instead of adding
                    a new part.{' '}
                    <Link
                      to={`/parts/${
                        this.state.shopParts.find(
                          (shopPart) =>
                            String(shopPart.number).trim() ===
                            String(this.state.newPart.number).trim()
                        ).id
                      }`}
                    >
                      Go to Existing Part
                    </Link>
                  </Typography.Text>
                )}
            </Col>
            <Col span={24}>
              <Text strong>Description</Text>
              <TextArea
                id="description"
                rows={4}
                placeholder="Add Description"
                value={this.state.newPart.description}
                onChange={(e) => {
                  this.setState({
                    newPart: {
                      ...this.state.newPart,
                      description: e.target.value,
                    },
                  });
                }}
              />
            </Col>
            <Col span={24}>
              <Text strong>Unit Cost:</Text>
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                value={this.state.newPart.cost}
                onChange={(value) => {
                  this.setState({
                    newPart: {
                      ...this.state.newPart,
                      cost: value,
                    },
                  });
                }}
              />
            </Col>
            <Col span={24}>
              <Checkbox
                style={{
                  marginTop: '10px',
                }}
                onChange={(e) => {
                  this.setState({
                    newPart: {
                      ...this.state.newPart,
                      addToInventory: e.target.checked,
                    },
                  });
                }}
              >
                Check box to add part to Parts List inventory. Leave unchecked
                to exclude from inventory
              </Checkbox>
            </Col>
          </Row>
        </StyledModal>
      </WorkOrderProvider>
    );
  }
}

decorate(WorkOrderDetails, {
  shopId: observable,
  shopUsers: computed,
});

export default withRouter(observer(WorkOrderDetails));
