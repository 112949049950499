import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { computed, decorate, observable, observe } from 'mobx';
import styled from 'styled-components';
import _ from 'lodash';
import queryString from 'query-string';
import CustomHeaderCell from 'shared/PitstopUI/PitstopTable/CustomHeaderCell';
import moment from 'moment-timezone';
import {
  Typography,
  Tag,
  Button,
  Icon,
  Menu,
  Dropdown,
  message,
  Row,
  Col,
  Input,
  List,
  Spin,
  Table,
  Checkbox,
  Tooltip,
  Popconfirm,
  notification,
  Modal
} from 'antd';

import { TableStore } from 'stores/abstract';

import { Link } from 'react-router-dom';

import { ShareVehicleHealthReportPage } from 'containers/ShareVehicleHealthReport';

import { PitstopModal } from 'shared';

import {
  AppStore,
  CarStore,
  CurrentUserStore,
  IssueStore,
  ShopStore,
  CarStatusStore,
} from 'stores';

import {
  renderIndicator,
  renderVehicleId,
  renderNextPMDueInPercentage,
  renderWorkOrderAssigned,
  renderIssuesWithShowIssuesLink,
  // renderNextPMVisitV2,
} from 'containers/CarListTable/CarListTableColumns';

import { renderEngineLightStatus } from 'containers/CarListTable/CarListTableColumnsV2';

import { IssuesTable } from 'containers/Issues';
import VehicleNoteTextarea from 'components/Vehicles/VehicleNoteTextarea';
import { Logger } from 'stores/Classes';
import AddAssetModal from 'components/Modals/AddAssetModal';
import NotificationBanner from 'components/NotificationBanner';
import ArchiveAssetModal from 'components/Modals/ArchiveAssetModal';

const Text = Typography.Text;
const { Search } = Input;

const TableContainer = styled(Table)`
  .ant-table-pagination.ant-pagination {
    text-align: center;
    float: none;
  }
  .ant-table-pagination.ant-pagination {
    text-align: center;
    float: none;
  }

  .ant-table-tbody > tr:hover > td {
    background-color: #f7f7f7 !important;
  }

  .ant-table tbody > tr > td.hover-effect-cell:hover {
    background-color: #e6f1ff !important;
  }

  .ant-table tbody > tr > td.ant-table-row-expand-icon-cell:hover {
    background-color: #e6f1ff !important;
  }

  .issues-table-wrapper .ant-table-tbody > tr:hover > td {
    background-color: #e6f1ff !important;
  }
`;

const DVIRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tag {
    // cursor: pointer;
    font-size: 16px;
    font-weight: 600;

    .defects-count {
      font-size: 1rem;
      color: #595959;
    }
  }
`;

const IndicatorTableWrapper = styled.div`
  &&& {
    display: grid;

    table th.indicator,
    table td.indicator {
      position: relative;
      padding: 0;
      width: 8px;
    }
  }

  .filter-tag {
    padding: 2px 15px;
    background: #ffffff;
    border-radius: 1rem;
  }

  .ant-table
    .ant-table-thead
    > tr
    > th
    i.anticon-filter.ant-table-filter-selected {
    background-color: #d9d9d9;
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-top: 5px;
  }

  .btn-status {
    color: white;

    :hover {
      border: 1px solid #1890ff;
      color: white;
    }
  }
`;

const FilterDropdown = styled.div`
  .filter-dropdown-list {
    padding: 8px 12px;
    border-bottom: solid 1px #e8e8e8;

    .filter-dropdown-list-item {
      margin-bottom: 5px;
    }
  }

  .filter-dropdown-actions {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
  }
`;

const FlexCol = styled(Col)`
  display: flex;
`;

class VehiclesTable extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object,
    onUpdateCarStatus: PropTypes.func,
    type: PropTypes.string,
    childRef: PropTypes.func,
    loadTotalAssetsCount: PropTypes.func,
  };

  tableStore;

  state = {
    selectedCarId: undefined,
    vehiclesApiUrl: '',
    issueReportStatus: ['new'],
    currentPagination: {},
    issueSources: [
      'dtc',
      'routine',
      'recall',
      'user',
      'algorithm',
      'archive',
      'lightVehicleDTC',
      'heavyVehicleDTC',
    ],
    isPrintHealthReport: false,
    searchKey: null,
    searchValue: null,
    searchTrailerValue: null,
    searchTrailerKey: null,
    connectivityStatus: null,
    maxPriority: _.isNil(
      queryString.parse(this.props.location.search).priorityFilter
    )
      ? 'All'
      : queryString.parse(this.props.location.search).priorityFilter,
    nextPmCars: [],
    isLoadingStatuses: false,
    currentShopStatuses: [],
    newShopCarStatuses: [],
    isLoadingVehicles: false,
    currentVehicles: [],
    paginationMeta: {
      pageSize: 10,
      total: 0,
      current: 1,
    },
    currentFilters: [],
    filterOptions: [],
    filteredInfo: {},
    filterByStatusDropdownVisible: false,
    nextPMFilterDropdownVisible: false,
    dvirFilterDropdownVisible: false,
    trailerDvirFilterDropdownVisible: false,
    trailerNextPMFilterDropdownVisible: false,
    currentTrailerDVIRFilters: [],
    currentTrailersPMFilters: [],
    currentPMFilters: [],
    currentDVIRFilters: [],
    previousFilters: {},
    // trailers
    currentTrailers: [],
    isLoadingTrailers: false,
    paginationMetaTrailer: {
      pageSize: 10,
      total: 0,
      current: 1,
    },
    shouldShowButton: true,
    isArchiveAssetsModalVisible: false,
    expandedCarIds: [],
    selectedAssets: [],
    isArchivingAssets: false,
    archivingAssetType: null,
    isExportingCSV: false,
  };

  get filterOptions() {
    const { currentShopStatuses } = this.state;
    return currentShopStatuses.map((status) => ({
      text: status.description,
      value: status.key,
    }));
  }

  get nextPMFilterOptions() {
    return [
      { text: 'Overdue (100%+)', filterKey: 'Overdue', value: 'overdue' },
      { text: 'Due Soon (80 - 99%)', filterKey: 'Due soon', value: 'dueSoon' },
      /*{ text: 'Upcoming (70 - 79%)', filterKey: 'Upcoming', value: 'upcoming' },*/
      {
        text: 'No action needed (0 - 69%)',
        filterKey: 'No action needed',
        value: 'noActionNeeded',
      },
      {
        text: 'Setup PM',
        filterKey: 'Setup PM',
        value: 'setupPm',
      },
    ];
  }

  get dvirFilterOptions() {
    return [
      {
        text: 'Safe',
        value: 'safe',
      },
      {
        text: 'Unsafe',
        value: 'unsafe',
      },
      {
        text: 'Resolved',
        value: 'resolved',
      },
      {
        text: 'Defects',
        value: 'defects',
      },
    ];
  }

  get combinedCurrentFilters() {
    return [
      ...this.state.currentFilters,
      ...this.state.currentPMFilters,
      ...this.state.currentDVIRFilters,
    ];
  }

  get combinedCurrentTrailerFilters() {
    return [
      ...this.state.currentTrailerDVIRFilters,
      ...this.state.currentTrailersPMFilters,
    ];
  }

  refreshTableOffset = false;

  disposer = observe(ShopStore.currentShop, 'id', () => {
    this.loadShopStatuses();

    if (this.props.type === 'trailers') {
      this.handleSearchChangeAndLoadTrailers();
    } else {
      this.handleSearchChangeAndLoadVehicles();
    }

    this.onClearAllFilters();
    this.onClearAllTrailerFilters();
  });

  get shopId() {
    return ShopStore.currentShop.id;
  }

  searchDebouncer = null;

  handleSearchChangeAndLoadVehicles = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.searchDebouncer);
    this.searchDebouncer = setTimeout(() => {
      this.loadVehicles();
      if (this.props.onUpdateCarStatus) {
        this.props.onUpdateCarStatus();
      }
    }, 500);
  };

  searchDebouncerTrailers = null;

  handleSearchChangeAndLoadTrailers = () => {
    clearTimeout(this.searchDebouncerTrailers);
    this.searchDebouncerTrailers = setTimeout(() => {
      this.loadTrailers();
    }, 500);
  };

  handleCarStatusFilterChange = (selectedKeys, confirm) => {
    this.setState(
      {
        currentFilters: selectedKeys.map((key) => {
          const status = this.state.currentShopStatuses.find(
            (s) => s.key === key
          );
          return {
            key: key,
            label: status?.description || key,
          };
        }),
        // currentPMFilters: [], // clear the PM filters
        filterByStatusDropdownVisible: false,
        selectedAssets: [],
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
      }
    );
  };

  handleNextPMFilterChange = (selectedKeys, confirm, callback) => {
    this.setState(
      {
        currentPMFilters: selectedKeys.map((key) => {
          const status = this.nextPMFilterOptions.find((s) => s.value === key);
          return {
            key: key,
            filterKey: status?.filterKey || key,
            label: status?.text || key,
          };
        }),
        // currentFilters: [], // clear the other filters
        // currentDVIRFilters: [], // clear the other filters
        nextPMFilterDropdownVisible: false,
        selectedAssets: [],
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
        if (callback) callback();
      }
    );
  };

  handleTrailerNextPMFilterChange = (selectedKeys, confirm, callback) => {
    this.setState(
      {
        currentTrailersPMFilters: selectedKeys.map((key) => {
          const status = this.nextPMFilterOptions.find((s) => s.value === key);
          return {
            key: key,
            filterKey: status?.filterKey || key,
            label: status?.text || key,
          };
        }),
        trailerNextPMFilterDropdownVisible: false,
      },
      () => {
        confirm();
        this.onChangeCurrentTrailerFilters();
        if (callback) callback();
      }
    );
  };

  handleDVIRFilterChange = (selectedKeys, confirm) => {
    this.setState(
      {
        currentDVIRFilters: selectedKeys.map((key) => {
          const status = this.dvirFilterOptions.find((s) => s.value === key);
          return {
            key: key,
            label: status?.text || key,
          };
        }),
        // currentPMFilters: [], // clear the PM filters
        dvirFilterDropdownVisible: false,
        selectedAssets: [],
      },
      () => {
        confirm();
        this.onChangeCurrentFilters();
      }
    );
  };

  handleTrailerDVIRFilterChange = (selectedKeys, confirm) => {
    this.setState(
      {
        currentTrailerDVIRFilters: selectedKeys.map((key) => {
          const status = this.dvirFilterOptions.find((s) => s.value === key);
          return {
            key: key,
            label: status?.text || key,
          };
        }),
        trailerDvirFilterDropdownVisible: false,
      },
      () => {
        confirm();
        this.onChangeCurrentTrailerFilters();
      }
    );
  };

  handleReset = (clearFilters, source = 'status') => {
    clearFilters();
    if (source === 'pm') {
      this.setState(
        {
          currentPMFilters: [],
          nextPMFilterDropdownVisible: false,
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
      return;
    } else if (source === 'dvir') {
      this.setState(
        {
          currentDVIRFilters: [],
          dvirFilterDropdownVisible: false,
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
      return;
    }
    this.setState(
      {
        currentFilters: [],
        filterByStatusDropdownVisible: false,
      },
      () => {
        this.onChangeCurrentFilters();
      }
    );
  };

  handleTrailerFilterReset = (clearFilters, source = 'dvir') => {
    clearFilters();
    if (source === 'dvir') {
      this.setState(
        {
          currentTrailerDVIRFilters: [],
          trailerDvirFilterDropdownVisible: false,
        },
        () => {
          this.onChangeCurrentFilters();
        }
      );
      return;
    }
    this.setState(
      {
        currentTrailersPMFilters: [],
        trailerNextPMFilterDropdownVisible: false,
      },
      () => {
        this.onChangeCurrentFilters();
      }
    );
  };

  saveCarNotes = async ({ id, notes }) => {
    try {
      await CarStore.updateCarNotes(id, notes);
    } catch (error) {
      AppStore.addError('Failed to save notes');
    }
  };

  renderCarStatusFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {this.filterOptions.map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button onClick={() => this.handleReset(clearFilters)} size="small">
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() =>
            this.handleCarStatusFilterChange(selectedKeys, confirm)
          }
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  handlePmsCountSectionClick = (sectionKey) => {
    this.setState({ currentPMFilters: [{ key: sectionKey }] }, () => {
      this.handleNextPMFilterChange([sectionKey], () => {});
    });
  };

  handleTrailersPmsCountSectionClick = (sectionKey) => {
    this.setState({ currentTrailersPMFilters: [{ key: sectionKey }] }, () => {
      this.handleTrailerNextPMFilterChange([sectionKey], () => {});
    });
  };

  renderNextPMFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {this.nextPMFilterOptions.map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() => this.handleReset(clearFilters, 'pm')}
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => this.handleNextPMFilterChange(selectedKeys, confirm)}
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  renderTrailerNextPMFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {this.nextPMFilterOptions.map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() => this.handleTrailerFilterReset(clearFilters, 'nextPm')}
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() =>
            this.handleTrailerNextPMFilterChange(selectedKeys, confirm)
          }
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  renderDVIRFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {this.dvirFilterOptions.map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() => this.handleReset(clearFilters, 'dvir')}
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => this.handleDVIRFilterChange(selectedKeys, confirm)}
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  renderTrailerDVIRFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <FilterDropdown>
      {/* For each filterOptions display a checkbox and the name of the filterOptions */}
      <div className="filter-dropdown-list">
        {this.dvirFilterOptions.map((filter) => {
          return (
            <div className="filter-dropdown-list-item" key={filter.value}>
              <Checkbox
                checked={selectedKeys.includes(filter.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedKeys([...selectedKeys, filter.value]);
                  } else {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== filter.value)
                    );
                  }
                }}
              >
                {filter.text}
              </Checkbox>
            </div>
          );
        })}
      </div>
      <div className="filter-dropdown-actions">
        <Button
          onClick={() => this.handleTrailerFilterReset(clearFilters, 'dvir')}
          size="small"
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() =>
            this.handleTrailerDVIRFilterChange(selectedKeys, confirm)
          }
        >
          OK
        </Button>
      </div>
    </FilterDropdown>
  );

  vehicleColumns = [
    {
      key: 'indicator',
      className: 'indicator',
      title: null,
      render: renderIndicator,
    },
    {
      key: 'car_name',
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      title: 'Unit ID - Vehicles',
      sorter: true,
      render: renderVehicleId,
    },
    {
      key: 'engine_light',
      title: 'Engine Light',
      align: 'center',
      render: (car) => {
        if (!car.currentEngineLightStatus) {
          return (
            <DVIRWrapper>
              <Spin />
            </DVIRWrapper>
          );
        } else if (car.currentEngineLightStatus === 'not-found') {
          return (
            <DVIRWrapper>
              <Text>--</Text>
            </DVIRWrapper>
          );
        }
        return renderEngineLightStatus({
          engine_light_status: car?.currentEngineLightStatus?.payload,
        });
      },
      sorter: (car1, car2) => {
        return (
          this.mapEngineLightToValue(car1) - this.mapEngineLightToValue(car2)
        );
      },
    },
    {
      key: 'issues',
      title: 'Issues',
      sorter: true,
      align: 'center',
      defaultSortOrder: 'descend',
      render: (car) =>
        renderIssuesWithShowIssuesLink(
          car,
          this.handleCarIssuesToggle,
          this.state.expandedCarIds
        ),
      width: 200,
    },
    {
      field: 'dvir',
      key: 'dvir',
      title: 'Latest DVIR',
      align: 'center',
      sorter: true,
      filters: this.dvirFilterOptions,
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ dvirFilterDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderDVIRFilterDropdown,
      render: (car) => {
        if (!car.dvir) {
          return (
            <DVIRWrapper>
              <Spin />
            </DVIRWrapper>
          );
        }
        const safetyStatus = _.get(car, 'dvir.record.safetyStatus');
        if (!safetyStatus) {
          return (
            <DVIRWrapper>
              <Text>--</Text>
            </DVIRWrapper>
          );
        }
        let color;
        let label;
        if (safetyStatus === 'safe') {
          color = 'green';
          label = 'Safe';
        } else if (safetyStatus === 'unsafe') {
          color = 'red';
          label = 'Unsafe';
        } else {
          color = 'geekblue';
          label = 'Resolved';
        }
        const vehicleDefects = _.get(
          car,
          'dvir.record.payload.vehicleDefects',
          []
        );
        const trailerDefects = _.get(
          car,
          'dvir.record.payload.trailerDefects',
          []
        );
        const totalDefects = vehicleDefects.length + trailerDefects.length;
        return (
          <DVIRWrapper>
            <div
              className="tag"
              style={{
                color: color,
              }}
              key={car.id}
            >
              {label}
            </div>
            <div className="defects-count">
              Defects: <b>{totalDefects}</b>
            </div>
            <Link
              onClick={(evt) => evt.stopPropagation()}
              to={`/dvir/${car.id}`}
              style={{ marginTop: 5 }}
            >
              View DVIR
            </Link>
          </DVIRWrapper>
        );
      },
      onCell: (record) => {
        if (!record.dvir || !_.get(record, 'dvir.record.safetyStatus')) {
          return {};
        }
        return { className: 'hover-effect-cell' };
      },
    },
    {
      field: 'nextPm',
      key: 'percentage',
      filter: false,
      align: 'center',
      title: (
        <div>
          <span>Next PM</span>
        </div>
      ),
      sorter: true,
      defaultSortOrder: 'descend',
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      filters: this.nextPMFilterOptions,
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ nextPMFilterDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderNextPMFilterDropdown,
      render: (car) => {
        return renderNextPMDueInPercentage(
          car,
          this.handleCarIssuesToggle,
          this.state.expandedCarIds
        );
      },
    },
    {
      field: 'status',
      key: 'car_status',
      title: 'Status',
      align: 'center',
      sorter: true,
      defaultSortOrder: 'descend',
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      filters: this.filterOptions,
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ filterByStatusDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderCarStatusFilterDropdown,
      // filterDropdownVisible: this.state.filterByStatusDropdownVisible,
      render: (car) => {
        const statuses =
          this.state.currentShopStatuses.length === 0
            ? CarStatusStore.getDefaultStatusList
            : this.state.currentShopStatuses;
        let currentCarStatus = statuses.find(
          (status) => status.key === car.carStatus
        );
        if (car.carStatus && !currentCarStatus) {
          currentCarStatus = {
            key: car.carStatus,
            description: 'Status Deleted',
            color: 'grey',
          };
        }
        // console.log(statuses);
        if (!currentCarStatus) {
          // get the default status
          currentCarStatus = statuses.find((status) => status.is_default);
        }
        if (!currentCarStatus) {
          // use the first one as default
          currentCarStatus = statuses[0];
        }
        return (
          <StatusWrapper>
            <Dropdown
              overlay={
                <Menu
                  className="status-menu"
                  onClick={(e) => {
                    if (e.domEvent?.stopPropagation) {
                      e.domEvent.stopPropagation();
                    }
                  }}
                >
                  {// for next version, we should get the user status list from the server
                  statuses.map((status) => {
                    return (
                      <Menu.Item
                        onClick={(e) => this.onClickNewStatus(e, status, car)}
                        className="menu-item"
                        key={status.key}
                      >
                        <Button
                          onClick={(e) => this.onClickNewStatus(e, status, car)}
                          className="btn-status"
                          ghost
                          style={{
                            color: status.color,
                            backgroundColor: status.color,
                            borderColor: status.color,
                          }}
                        >
                          {status.description}
                        </Button>
                      </Menu.Item>
                    );
                  })}
                  <Menu.Divider />
                  <Menu.Item
                    onClick={this.stopPropagation}
                    className="menu-item"
                    key="actions"
                  >
                    <Button
                      block
                      icon="edit"
                      type="primary"
                      onClick={this.openShopStatusesModal}
                    >
                      Edit Statuses
                    </Button>
                  </Menu.Item>
                </Menu>
              }
            >
              {
                <Button
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    backgroundColor: 'white',
                    color: currentCarStatus.color,
                    borderColor: currentCarStatus.color,
                  }}
                  loading={car.isLoadingStatus}
                >
                  {`${currentCarStatus.description}`} <Icon type="down" />
                </Button>
              }
            </Dropdown>
          </StatusWrapper>
        );
      },
      rowClick: false,
    },
    {
      field: 'notes',
      key: 'notes',
      filter: false,
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      align: 'center',
      title: (
        <div>
          <span>Notes</span>
        </div>
      ),
      sorter: false,
      render: (car) => {
        return (
          <VehicleNoteTextarea
            car={car}
            onSave={({ id, notes }) => {
              this.saveCarNotes({ id, notes });
            }}
          />
        );
      },
      rowClick: false,
    },
    {
      field: 'actions',
      key: 'actions',
      filter: false,
      align: 'center',
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      title: 'Health Report',
      defaultSortOrder: 'descend',
      render: (car) => {
        // return <Button shape="circle" icon="share-alt" />;
        const handleShareClick = (car, { key }) => {
          this.setState({ selectedCarId: car.id }, () => {
            if (key === 'download') {
              this.setState(
                {
                  isPrintHealthReport: false,
                },
                () => {
                  this.onDownloadVehicleHealthReport();
                }
              );
            } else if (key === 'print') {
              this.setState(
                {
                  isPrintHealthReport: true,
                },
                () => {
                  this.onPrintVehicleHealthReport();
                }
              );
            } else if (key === 'share') {
              AppStore.openModals.set(
                'SHARE_VEHICLE_HEALTH_REPORT_MODAL',
                true
              );
            }
          });
        };
        const menu = (
          <Menu
            onClick={(data) => {
              handleShareClick(car, data);
            }}
          >
            <Menu.Item
              key="print"
              onClick={(evt) => {
                this.stopPropagation(evt);
              }}
            >
              <Icon type="printer" />
              Print Report
            </Menu.Item>
            <Menu.Item
              key="download"
              onClick={(evt) => {
                this.stopPropagation(evt);
              }}
            >
              <Icon type="download" />
              Download Report
            </Menu.Item>
            <Menu.Item
              key="share"
              onClick={(evt) => {
                this.stopPropagation(evt);
              }}
            >
              <Icon type="share-alt" />
              Share Report
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} placement="bottomRight">
            <Button
              onClick={(evt) => {
                this.stopPropagation(evt);
              }}
              shape="circle"
              icon="file-text"
            />
          </Dropdown>
        );
      },
      rowClick: false,
    },
  ];

  trailerColumns = [
    {
      key: 'indicator',
      className: 'indicator',
      title: null,
      render: renderIndicator,
    },
    {
      key: 'car_name',
      title: 'Unit ID - Trailers',
      sorter: true,
      defaultSortOrder: 'descend',
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      render: renderVehicleId,
    },
    {
      key: 'workOrderAssigned',
      title: 'Work Order Assigned',
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      sorter: false,
      render: (car) => {
        if (car.workOrdersAssigned) {
          return renderWorkOrderAssigned(car.workOrdersAssigned);
        }
        return 'N/A';
      },
    },
    {
      field: 'nextPm',
      key: 'percentage',
      filter: false,
      align: 'center',
      title: (
        <div>
          <span>Next PM</span>
        </div>
      ),
      sorter: true,
      defaultSortOrder: 'descend',
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      filters: this.nextPMFilterOptions,
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ trailerNextPMFilterDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderTrailerNextPMFilterDropdown,
      render: (car) => {
        return renderNextPMDueInPercentage(car);
      },
    },
    {
      field: 'dvir',
      key: 'dvir',
      title: 'DVIR',
      align: 'center',
      sorter: true,
      filters: this.dvirFilterOptions,
      filterIcon: (filtered) => (
        <Icon
          type="filter"
          theme="filled"
          style={{
            color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.45)',
          }}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ trailerDvirFilterDropdownVisible: true });
          }}
        />
      ),
      filterDropdown: this.renderTrailerDVIRFilterDropdown,
      onCell: () => ({
        className: 'hover-effect-cell',
      }),
      render: (car) => {
        if (!car.dvir) {
          return (
            <DVIRWrapper>
              <Spin />
            </DVIRWrapper>
          );
        }
        const safetyStatus = _.get(car, 'dvir.record.safetyStatus');
        if (!safetyStatus) {
          return (
            <DVIRWrapper>
              <Text>--</Text>
            </DVIRWrapper>
          );
        }
        let color;
        let label;
        if (safetyStatus === 'safe') {
          color = 'green';
          label = 'Safe';
        } else if (safetyStatus === 'unsafe') {
          color = 'red';
          label = 'Unsafe';
        } else {
          color = 'geekblue';
          label = 'Resolved';
        }
        const vehicleDefects = _.get(
          car,
          'dvir.record.payload.vehicleDefects',
          []
        );
        const trailerDefects = _.get(
          car,
          'dvir.record.payload.trailerDefects',
          []
        );
        const totalDefects = vehicleDefects.length + trailerDefects.length;
        return (
          <DVIRWrapper>
            <div
              className="tag"
              style={{
                color: color,
              }}
              key={car.id}
            >
              {label}
            </div>
            <div className="defects-count">
              Defects: <b>{totalDefects}</b>
            </div>
            <Link
              onClick={(evt) => evt.stopPropagation()}
              to={`/dvir/${car.id}?trailer=true`}
              style={{ marginTop: 5 }}
            >
              View DVIR
            </Link>
          </DVIRWrapper>
        );
      },
    },
  ];

  get columns() {
    return this.props.type === 'vehicles'
      ? this.vehicleColumns
      : this.trailerColumns;
  }

  mapEngineLightToValue = (car) => {
    const engineLightStatus = _.get(car, 'engine_light_status');
    let engineLightValue = 0;
    if (_.isNil(engineLightStatus)) {
      return engineLightValue;
    }
    if (_.get(engineLightStatus, 'generalVehicleWarningLight')) {
      return 2;
    }
    if (_.get(engineLightStatus, 'redStopLamp')) {
      engineLightValue += 6;
    }
    if (_.get(engineLightStatus, 'malfunctionLamp')) {
      engineLightValue += 3;
    }
    if (_.get(engineLightStatus, 'amberWarningLamp')) {
      engineLightValue += 2;
    }
    if (_.get(engineLightStatus, 'protectWarningLamp')) {
      engineLightValue += 2;
    }

    return engineLightValue;
  };

  loadShopStatuses = async () => {
    const shopId = ShopStore.currentShop.id;
    try {
      this.setState({ isLoadingStatuses: true });
      const shopStatuses = await CarStatusStore.fetchShopCarStatuses(shopId);
      this.setState({ currentShopStatuses: shopStatuses || [] });
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoadingStatuses: false });
    }
  };

  saveNewShopStatuses = async () => {
    const shopId = ShopStore.currentShop.id;
    const statuses = this.state.newShopCarStatuses;
    // check if there's a default status
    if (!_.some(statuses, { is_default: true })) {
      message.error('Please select a default status');
      return;
    }
    try {
      this.setState({ isSavingStatuses: true });
      const newStatuses = await CarStatusStore.updateShopStatuses(
        shopId,
        statuses
      );
      this.setState({ currentShopStatuses: newStatuses || [] });
      message.success('Statuses have been updated successfully!');
      AppStore.openModals.set('SHOP_STATUSES_MODAL', false);
      if (this.props.onUpdateCarStatus) {
        this.props.onUpdateCarStatus();
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isSavingStatuses: false });
    }
  };

  stopPropagation = (e) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (e.domEvent?.stopPropagation) {
      e.domEvent.stopPropagation();
    }
  };

  onClickNewStatus = async (e, status, car) => {
    this.stopPropagation(e);
    const newStatus = status.key;
    const carId = car?.id;
    // update the table with new status
    this.setState({
      currentVehicles: this.state.currentVehicles.map((ccar) => {
        if (ccar.id === carId) {
          ccar.carStatus = newStatus;
          ccar.isLoadingStatus = true;
        }
        return ccar;
      }),
    });

    try {
      // update the car status in the server
      await CarStore.updateCarStatusOnServer(car, newStatus);
      message.info('Status has been updated successfully!');
      if (this.props.onUpdateCarStatus) {
        this.props.onUpdateCarStatus({
          carId,
          newStatus,
        });
      }
      this.setState({
        currentVehicles: this.state.currentVehicles.map((ccar) => {
          if (ccar.id === carId) {
            ccar.isLoadingStatus = false;
          }
          return ccar;
        }),
      });
    } catch (error) {
      Logger.error(error);
    }
  };

  openShopStatusesModal = (e) => {
    this.stopPropagation(e);
    if (this.state.isLoadingStatuses) {
      return;
    }
    this.setState({
      newShopCarStatuses:
        this.state.currentShopStatuses.length > 0
          ? JSON.parse(JSON.stringify(this.state.currentShopStatuses))
          : CarStatusStore.getDefaultStatusList,
    });
    AppStore.openModals.set('SHOP_STATUSES_MODAL', true);
  };

  onChangeCurrentFiltersDebouncer = null;

  handleOnChangeCurrentFiltersDebouncer = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.onChangeCurrentFiltersDebouncer);
    this.onChangeCurrentFiltersDebouncer = setTimeout(() => {
      this.onChangeCurrentFilters();
    }, 500);
  };

  onChangeCurrentTrailersFiltersDebouncer = null;

  handleOnChangeCurrentTrailersFiltersDebouncer = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.onChangeCurrentTrailersFiltersDebouncer);
    this.onChangeCurrentTrailersFiltersDebouncer = setTimeout(() => {
      this.onChangeCurrentTrailerFilters();
    }, 500);
  };

  unlisten = null;

  componentDidMount() {
    if (this.props.childRef) {
      this.props.childRef(this);
    }
    this.loadShopStatuses();
    this.loadVehicles();

    // watch for changes in the query string
    this.unlisten = this.props.history.listen((location, action) => {
      const query = queryString.parse(location.search);
      if (query.dvir) {
        const selectedDVIRFilter = this.dvirFilterOptions.find(
          (filter) => filter.value === query.dvir
        );
        if (selectedDVIRFilter) {
          this.setState(
            {
              currentDVIRFilters: [
                {
                  key: selectedDVIRFilter.value,
                  label: selectedDVIRFilter.text,
                },
              ],
            },
            () => {
              this.handleOnChangeCurrentFiltersDebouncer();
            }
          );
        }
      }
      if (query.dvirTrailer) {
        const selectedDVIRFilter = this.dvirFilterOptions.find(
          (filter) => filter.value === query.dvirTrailer
        );
        if (selectedDVIRFilter) {
          this.setState(
            {
              currentTrailerDVIRFilters: [
                {
                  key: selectedDVIRFilter.value,
                  label: selectedDVIRFilter.text,
                },
              ],
            },
            () => {
              this.handleOnChangeCurrentTrailersFiltersDebouncer();
            }
          );
        }
      }
      if (query.status) {
        const clearString = (str) => _.deburr(_.kebabCase(str));
        const selectedStatusFilter = this.filterOptions.find(
          (filter) => clearString(filter.text) === clearString(query.status)
        );
        if (selectedStatusFilter) {
          this.setState(
            {
              currentFilters: [
                {
                  key: selectedStatusFilter.value,
                  label: selectedStatusFilter.text,
                },
              ],
            },
            () => {
              this.handleOnChangeCurrentFiltersDebouncer();
            }
          );
        }
      }
    });
  }

  componentWillUnmount() {
    this.disposer();
    this.unlisten();
  }

  async componentDidUpdate(prevProps, prevState) {
    let { searchValue, searchTrailerValue, currentShopStatuses } = this.state;
    if (searchValue !== prevState.searchValue) {
      this.handleSearchChangeAndLoadVehicles();
    }

    if (searchTrailerValue !== prevState.searchTrailerValue) {
      this.handleSearchChangeAndLoadTrailers();
    }

    if (currentShopStatuses !== prevState.currentShopStatuses) {
      this.vehicleColumns = this.vehicleColumns.map((column) => {
        if (column.key === 'car_status') {
          return {
            ...column,
            filters: this.filterOptions,
          };
        }
        return column;
      });
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    let column = null;
    let offset = (pagination.current - 1) * pagination.pageSize;

    if (sorter.columnKey) {
      let sortString = sorter.columnKey;
      const sortColumn = _.filter(
        this.columns,
        (el) => sorter.columnKey === (el.sortLabel || el.dataIndex || el.key)
      )[0];

      if (!sortColumn) {
        sortString =
          sortColumn.sortLabel || sortColumn.dataIndex || sortColumn.key;
      }

      if (!sorter.order) {
        column = undefined;
      } else {
        column = sorter.order === 'ascend' ? `${sortString}` : `-${sortString}`;
      }
    }

    this.setState({
      currentPagination: {
        offset,
        limit: pagination.pageSize,
        sort: column,
        filter: {
          exclude: 'issueList',
          ...filters,
        },
      },
    });

    this.loadVehicles({
      offset,
      limit: pagination.pageSize,
      sort: column,
      filter: {
        exclude: 'issueList',
        ...filters,
      },
    });
  };

  handleTableTrailerChange = (pagination, filters, sorter) => {
    let column = null;
    let offset = (pagination.current - 1) * pagination.pageSize;

    if (sorter.columnKey) {
      let sortString = sorter.columnKey;
      const sortColumn = _.filter(
        this.trailerColumns,
        (el) => sorter.columnKey === (el.sortLabel || el.dataIndex || el.key)
      )[0];

      if (sortColumn) {
        sortString =
          sortColumn.sortLabel || sortColumn.dataIndex || sortColumn.key;
      }

      if (!sorter.order) {
        column = undefined;
      } else {
        column = sorter.order === 'ascend' ? `${sortString}` : `-${sortString}`;
      }
    }

    // Update pagination state for trailers
    this.setState({
      paginationMetaTrailer: {
        offset,
        limit: pagination.pageSize,
        sort: column,
        filter: {
          ...filters,
        },
      },
    });

    // Reload trailers based on the new pagination, sorting, and filtering
    this.loadTrailers({
      offset,
      limit: pagination.pageSize,
      sort: column,
      filter: {
        ...filters,
      },
    });
  };

  onChangeCurrentFilters = () => {
    // update table filter based on currentFilters
    const filters = {
      car_status: [],
      nextPmFilter: [],
      dvirStatus: [],
    };
    this.state.currentFilters.forEach((filter) => {
      filters['car_status'].push(filter.key);
    });
    this.state.currentPMFilters.forEach((filter) => {
      filters['nextPmFilter'].push(filter.filterKey);
    });
    this.state.currentDVIRFilters.forEach((filter) => {
      filters['dvirStatus'].push(filter.key);
    });
    this.setState({ filteredInfo: filters });
    // update the columns
    this.vehicleColumns = this.vehicleColumns.map((column) => {
      if (column.key === 'car_status') {
        return {
          ...column,
          filters: this.filterOptions,
          filteredValue: filters['car_status'],
        };
      } else if (column.key === 'percentage') {
        return {
          ...column,
          filteredValue: filters['nextPmFilter'].map((filter) =>
            _.camelCase(filter)
          ),
        };
      } else if (column.key === 'dvir') {
        return {
          ...column,
          filteredValue: filters['dvirStatus'],
        };
      }
      return column;
    });
    // if the nextPM percentage filter is applied, remove the sort
    // const sortOveride = {};
    // if (filters['nextPmFilter'].length > 0) {
    //   sortOveride['sort'] = null;
    // }
    this.loadVehicles({
      // ...this.state.previousFilters,
      // ...this.state.currentPagination,
      filter: filters,
      // ...sortOveride,
    });
  };

  onChangeCurrentTrailerFilters = () => {
    // update table filter based on currentFilters
    const filters = {
      dvirStatus: [],
      nextPmFilter: [],
    };
    this.state.currentTrailerDVIRFilters.forEach((filter) => {
      filters['dvirStatus'].push(filter.key);
    });
    this.state.currentTrailersPMFilters.forEach((filter) => {
      filters['nextPmFilter'].push(filter.filterKey);
    });
    // this.setState({ filteredInfo: filters });
    // update the columns
    this.trailerColumns = this.trailerColumns.map((column) => {
      if (column.key === 'dvir') {
        return {
          ...column,
          filteredValue: filters['dvirStatus'],
        };
      } else if (column.key === 'percentage') {
        return {
          ...column,
          filteredValue: filters['nextPmFilter'].map((filter) =>
            _.camelCase(filter)
          ),
        };
      }
      return column;
    });
    this.loadTrailers({
      filter: filters,
    });
  };

  asyncGetCarsEngineLightStatus = async () => {
    try {
      const engineLightStatuses = await CarStore.fetchEngineLightStatusForCars(
        _.map(this.state.currentVehicles, 'id')
      );
      this.setState({
        currentVehicles: this.state.currentVehicles.map((car) => {
          const carEngineLightStatus = _.find(engineLightStatuses.data, {
            vin: car.vin,
          });
          car.currentEngineLightStatus = carEngineLightStatus || 'not-found';
          return car;
        }),
      });
    } catch (error) {
      this.setState({
        currentVehicles: this.state.currentVehicles.map((car) => {
          car.currentEngineLightStatus = 'not-found';
          return car;
        }),
      });
      Logger.error(error);
    }
  };

  asyncGetCarsDVIRs = async () => {
    try {
      const dvirData = await CarStore.fetchDVIRsForCars(
        _.map(this.state.currentVehicles, 'id').join(',')
      );
      this.setState({
        currentVehicles: this.state.currentVehicles.map((car) => {
          car.dvir = _.find(dvirData, { carId: car.id });
          return car;
        }),
      });
    } catch (error) {
      this.setState({
        currentVehicles: this.state.currentVehicles.map((car) => {
          car.dvir = null;
          return car;
        }),
      });
      Logger.error(error);
    }
  };

  asyncGetTrailerDVIRs = async () => {
    try {
      const dvirData = await CarStore.fetchDVIRsForCars(
        _.map(this.state.currentTrailers, 'id').join(','),
        true
      );
      this.setState({
        currentTrailers: this.state.currentTrailers.map((car) => {
          car.dvir = _.find(dvirData, { carId: car.id });
          return car;
        }),
      });
    } catch (error) {
      this.setState({
        currentTrailers: this.state.currentTrailers.map((car) => {
          car.dvir = null;
          return car;
        }),
      });
      Logger.error(error);
    }
  };

  asyncGetNextPM = async () => {
    try {
      const nextPMData = await CarStore.getPmScheduleSummaries(
        _.map(this.state.currentVehicles, 'id').join(',')
      );
      this.setState({
        currentVehicles: this.state.currentVehicles.map((car) => {
          car.nextPm = _.find(nextPMData, { carId: car.id });
          return car;
        }),
      });
    } catch (error) {
      this.setState({
        currentVehicles: this.state.currentVehicles.map((car) => {
          car.nextPm = null;
          return car;
        }),
      });
      Logger.error(error);
    }
  };

  trailerTableStore = new TableStore();

  loadTrailers = async (filters = null) => {
    try {
      this.setState({
        selectedAssets: [], // Clear selected row keys
      });
      this.setState({ isLoadingTrailers: true });

      if (_.isNil(filters)) {
        filters = {
          offset: 0,
          limit: 10,
          sort: null,
          filter: {},
        };
      }

      if (_.isNil(filters.offset)) {
        filters.offset = 0;
      }

      if (_.isNil(filters.limit)) {
        filters.limit = 10;
      }

      _.set(filters, 'filter.exclude', 'issueList'); // attributes not used
      _.set(filters, 'filter.car_type', 'Trailer');

      const { searchTrailerValue, searchTrailerKey } = this.state;

      if (!_.isNil(searchTrailerValue)) {
        filters.filter = {
          ...filters.filter,
          [searchTrailerKey]: searchTrailerValue,
        };
      }

      const queryString = new URLSearchParams(filters.filter);

      if (filters.sort) {
        queryString.set('sort', filters.sort);
      }
  
      if (filters.offset) {
        queryString.set('offset', filters.offset);
      }
  
      if (filters.limit) {
        queryString.set('limit', filters.limit);
      }

      this.setState({ vehiclesApiUrl: queryString.toString() });

      if (
        filters.filter &&
        filters.filter.nextPmFilter &&
        (!_.isArray(filters.filter.nextPmFilter) ||
          filters.filter.nextPmFilter.length === 0)
      ) {
        // remove nextPmFilter filter if it's empty
        delete filters.filter.nextPmFilter;
      }

      if (
        filters.filter &&
        filters.filter.dvirStatus &&
        (!_.isArray(filters.filter.dvirStatus) ||
          filters.filter.dvirStatus.length === 0)
      ) {
        // remove dvirStatus filter if it's empty
        delete filters.filter.dvirStatus;
      }

      // if filter.sort equals to percentage or -percentage force it to be sortPm and remove the sort key
      if (['percentage', '-percentage'].includes(_.get(filters, 'sort'))) {
        const sortDirection = _.get(filters, 'sort');
        // invert the sorting direction
        filters.sortPm =
          sortDirection.split('')[0] === '-' ? 'percentage' : '-percentage';
        delete filters.sort;
      }

      // force the percentage filter to be nextPmFilter
      if (_.get(filters, 'filter.percentage', null)) {
        filters.filter.nextPmFilter = filters.filter.percentage;
        filters.filter.nextPmFilter = filters.filter.nextPmFilter.map(
          (filter) => {
            if (filter === 'overdue') {
              return 'Overdue';
            } else if (filter === 'dueSoon') {
              return 'Due soon';
            } else if (filter === 'upcoming') {
              return 'Upcoming';
            } else if (filter === 'noActionNeeded') {
              return 'No action needed';
            } else if (filter === 'setupPm') {
              return 'Setup PM';
            }
            return filter;
          }
        );
        delete filters.filter.percentage;
      }

      // force the filter to be dvirStatus
      if (_.get(filters, 'filter.dvir', null)) {
        filters.filter.dvirStatus = filters.filter.dvir;
        delete filters.filter.dvir;
      }

      let response;

      if (CurrentUserStore.user.role === 'customer') {
        response = await CarStore.getUserCarsForTable(
          filters,
          this.trailerTableStore,
          CurrentUserStore.user.id,
          CurrentUserStore.user.shopId,
          {
            isAddNextPmSummary: false,
            isAddWorkOrder: true,
            isAddEngineLightStatus: false,
            isAddDvirData: false,
            isReturnMetaAndData: true,
          }
        );
      } else {
        response = await CarStore.getShopCarsForTable(
          filters,
          this.trailerTableStore,
          this.shopId,
          {
            isAddNextPmSummary: false,
            isAddWorkOrder: true,
            isAddEngineLightStatus: false,
            isAddDvirData: false,
            isReturnMetaAndData: true,
          }
        );
      }

      if (!response) {
        return;
      }

      this.setState(
        {
          currentTrailers: response.data.map((car) => ({
            ...car,
            key: car.id,
          })),
          paginationMetaTrailer: {
            ...this.state.paginationMetaTrailer,
            total: response.meta.count,
            current: filters.offset / 10 + 1,
          },
        },
        () => {
          this.asyncGetTrailerDVIRs();
        }
      );
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoadingTrailers: false });
    }
  };

  loadVehicles = async (filters = null) => {
    try {
      this.setState({
        selectedAssets: [], // Clear selected row keys
      });
      this.setState({ isLoadingVehicles: true });

      if (_.isNil(filters)) {
        filters = {
          offset: 0,
          limit: 10,
          sort: null,
          filter: {},
        };
      }

      _.set(filters, 'filter.exclude', 'issueList'); // attributes not used
      _.set(filters, 'filter.car_type', 'vehicle');

      const { searchValue, searchKey } = this.state;

      if (!_.isNil(searchValue)) {
        filters.filter = {
          ...filters.filter,
          [searchKey]: searchValue,
        };
      }

      const queryString = new URLSearchParams(filters.filter);

      if (filters.sort) {
        queryString.set('sort', filters.sort);
      }
  
      if (filters.offset) {
        queryString.set('offset', filters.offset);
      }
  
      if (filters.limit) {
        queryString.set('limit', filters.limit);
      }

      this.setState({ vehiclesApiUrl: queryString.toString() });

      if (
        filters.filter &&
        filters.filter.car_status &&
        (!_.isArray(filters.filter.car_status) ||
          filters.filter.car_status.length === 0)
      ) {
        // remove car_status filter if it's empty
        delete filters.filter.car_status;
      }

      if (
        filters.filter &&
        filters.filter.nextPmFilter &&
        (!_.isArray(filters.filter.nextPmFilter) ||
          filters.filter.nextPmFilter.length === 0)
      ) {
        // remove nextPmFilter filter if it's empty
        delete filters.filter.nextPmFilter;
      }

      if (
        filters.filter &&
        filters.filter.dvirStatus &&
        (!_.isArray(filters.filter.dvirStatus) ||
          filters.filter.dvirStatus.length === 0)
      ) {
        // remove dvirStatus filter if it's empty
        delete filters.filter.dvirStatus;
      }

      // if filter.sort equals to percentage or -percentage force it to be sortPm and remove the sort key
      if (['percentage', '-percentage'].includes(_.get(filters, 'sort'))) {
        const sortDirection = _.get(filters, 'sort');
        // invert the sorting direction
        filters.sortPm =
          sortDirection.split('')[0] === '-' ? 'percentage' : '-percentage';
        delete filters.sort;
      }

      // force the percentage filter to be nextPmFilter
      if (_.get(filters, 'filter.percentage', null)) {
        filters.filter.nextPmFilter = filters.filter.percentage;
        filters.filter.nextPmFilter = filters.filter.nextPmFilter.map(
          (filter) => {
            if (filter === 'overdue') {
              return 'Overdue';
            } else if (filter === 'dueSoon') {
              return 'Due soon';
            } else if (filter === 'upcoming') {
              return 'Upcoming';
            } else if (filter === 'noActionNeeded') {
              return 'No action needed';
            } else if (filter === 'setupPm') {
              return 'Setup PM';
            }
            return filter;
          }
        );
        delete filters.filter.percentage;
      }

      // force the filter to be dvirStatus
      if (_.get(filters, 'filter.dvir', null)) {
        filters.filter.dvirStatus = filters.filter.dvir;
        delete filters.filter.dvir;
      }

      let response;

      this.setState({ previousFilters: filters });

      if (CurrentUserStore.user.role === 'customer') {
        response = await CarStore.getUserCarsForTable(
          filters,
          new TableStore(),
          CurrentUserStore.user.id,
          CurrentUserStore.user.shopId,
          {
            isAddNextPmSummary: false,
            isAddEngineLightStatus: false,
            isAddDvirData: false,
            isReturnMetaAndData: true,
          }
        );
      } else {
        response = await CarStore.getShopCarsForTable(
          filters,
          new TableStore(),
          this.shopId,
          {
            isAddNextPmSummary: false,
            isAddWorkOrder: false,
            isAddEngineLightStatus: false,
            isAddDvirData: false,
            isReturnMetaAndData: true,
          }
        );
      }

      if (!response) {
        return;
      }

      this.setState(
        {
          currentVehicles: response.data.map((car) => ({
            ...car,
            key: car.id,
          })),
          paginationMeta: {
            ...this.state.paginationMeta,
            total: response.meta.count,
            current: filters.offset / 10 + 1,
          },
        },
        () => {
          this.asyncGetCarsEngineLightStatus();
          this.asyncGetNextPM();
          this.asyncGetCarsDVIRs();
        }
      );
    } catch (error) {
      Logger.error(error);
    } finally {
      this.setState({ isLoadingVehicles: false });
    }
  };

  setFilter = (key, value) => {
    // force to reset table
    this.setState({
      searchKey: key,
      searchValue: value,
    });
    this.refreshTableOffset = true;
  };

  openShopStatusesModal = (e) => {
    this.stopPropagation(e);
    if (this.state.isLoadingStatuses) {
      return;
    }
    this.setState({
      newShopCarStatuses:
        this.state.currentShopStatuses.length > 0
          ? JSON.parse(JSON.stringify(this.state.currentShopStatuses))
          : CarStatusStore.getDefaultStatusList,
    });
    AppStore.openModals.set('SHOP_STATUSES_MODAL', true);
  };

  onDownloadVehicleHealthReport = async () => {
    try {
      AppStore.addLoading('Getting Health Report');
      await CarStore.data
        .get(this.state.selectedCarId)
        .downloadVehicleHealthReport(
          this.state.issueReportStatus,
          1000,
          this.state.issueSources
        );
      AppStore.addSuccess('Report downloaded.');
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      AppStore.removeLoading('Getting report...');
    }
  };

  onPrintVehicleHealthReport = async () => {
    try {
      AppStore.addLoading('Getting Health Report');
      await CarStore.data
        .get(this.state.selectedCarId)
        .printVehicleHealthReport(
          this.state.issueReportStatus,
          1000,
          this.state.issueSources
        );
      AppStore.addSuccess('Report printing.');
    } catch (err) {
      AppStore.addError(err.message);
    } finally {
      AppStore.removeLoading('Getting report...');
    }
  };

  selectStatusAsDefault = (event, key) => {
    if (event.target.checked) {
      return this.setState({
        newShopCarStatuses: this.state.newShopCarStatuses.map((status) => {
          if (status.key === key) {
            status.is_selected = true;
            status.is_default = true;
          } else {
            status.is_selected = false;
            status.is_default = false;
          }
          return status;
        }),
      });
    }
    return this.setState({
      newShopCarStatuses: this.state.newShopCarStatuses.map((status) => {
        if (status.key === key) {
          status.is_selected = false;
          status.is_default = false;
        }
        return status;
      }),
    });
  };

  setQueryToRoutineQueryParams = () => {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    queryParams.set('source', 'routine');
    this.props.history.push({
      search: queryParams.toString(),
    });
  }

  removeQueryToRoutineQueryParams = () => {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    queryParams.delete('source');
    this.props.history.push({
      search: queryParams.toString(),
    });
  }

  clearAllQueryParams = () => {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    queryParams.delete('status');
    queryParams.delete('dvir');
    this.props.history.push({
      search: queryParams.toString(),
    });
  };

  clearSpecificQueryParam = (param) => {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    queryParams.delete(param);
    this.props.history.push({
      search: queryParams.toString(),
    });
  };

  onClearAllFilters = () => {
    // clear all query params
    this.clearAllQueryParams();
    this.setState(
      {
        currentFilters: [],
        currentPMFilters: [],
        currentDVIRFilters: [],
        selectedAssets: [],
      },
      () => {
        this.onChangeCurrentFilters();
      }
    );
  };

  onClearAllTrailerFilters = () => {
    this.setState(
      {
        currentTrailerDVIRFilters: [],
        currentTrailersPMFilters: [],
      },
      () => {
        this.onChangeCurrentTrailerFilters();
      }
    );
  };

  onCloseFilter = (filter) => {
    this.setState(
      {
        currentFilters: this.state.currentFilters.filter(
          (f) => f.key !== filter.key
        ),
        currentPMFilters: this.state.currentPMFilters.filter(
          (f) => f.key !== filter.key
        ),
        currentDVIRFilters: this.state.currentDVIRFilters.filter(
          (f) => f.key !== filter.key
        ),
      },
      () => {
        if (this.state.currentFilters.length === 0) {
          this.clearSpecificQueryParam('status');
        }
        if (this.state.currentDVIRFilters.length === 0) {
          this.clearSpecificQueryParam('dvir');
        }
        if (this.state.currentPMFilters.length === 0) {
          this.clearSpecificQueryParam('percentage');
        }
        this.onChangeCurrentFilters();
      }
    );
  };

  onCloseTrailerFilter = (filter) => {
    this.setState(
      {
        currentTrailerDVIRFilters: this.state.currentTrailerDVIRFilters.filter(
          (f) => f.key !== filter.key
        ),
        currentTrailersPMFilters: this.state.currentTrailersPMFilters.filter(
          (f) => f.key !== filter.key
        ),
      },
      () => {
        this.onChangeCurrentTrailerFilters();
      }
    );
  };

  onAddTrailers = async (trailersToAdd) => {
    AppStore.openModals.set('ADD_ASSET_MODAL', false);
    // save the list of trailers using the trailer create endpoint
    if (trailersToAdd.length) {
      try {
        for (const trailer of trailersToAdd) {
          await CarStore.createTrailer({
            vin: trailer.vin,
            source: 'user',
            shopId: ShopStore.currentShop.id,
            licensePlate: '',
            baseMileage: trailer.mileage | 0,
            unitId: trailer.unitId,
            engineHours: trailer.engineHours | 0,
            make: trailer.make,
            model: trailer.model,
            year: trailer.year,
          });
        }
        AppStore.addSuccess('Trailers added successfully');
        this.props.loadTotalAssetsCount();
      } catch (error) {
        Logger.log(error);
        AppStore.addError('Error adding trailers');
      } finally {
        this.handleSearchChangeAndLoadTrailers();
      }
    }
  };

  // Attribute for the vehicle table
  vehicleRowSelection = {
    onChange: (selectedRowKeys) => {
      this.setState({
        selectedAssets: selectedRowKeys,
      });
    },
    getCheckboxProps: (record) => ({
      // disabled: record.name === 'Disabled User', // Column configuration not to be checked
      // name: record.name,
    }),
  };

  mapIssuesTable = {};

  handleArchiveAssets = async (notes) => {
    try {
      this.setState({ isArchivingAssets: true });
      await CarStore.archiveAssets({
        assetIds: this.state.selectedAssets,
        notes,
        shopId: ShopStore.currentShop.id,
      });
      notification.success({
        message: 'Asset(s) Archived',
        description: 'Assets archived successfully',
        icon: <Icon type="check-circle" style={{ color: 'green' }} />,
      });
      this.setState({ isArchiveAssetsModalVisible: false });
      if (this.state.archivingAssetType === 'vehicles') {
        this.handleSearchChangeAndLoadVehicles();
      } else if (this.state.archivingAssetType === 'trailers') {
        this.handleSearchChangeAndLoadTrailers();
      }
      this.props.loadTotalAssetsCount();
    } catch (error) {
      Logger.error(error);
      notification.error({
        message: 'Error',
        description: 'Error archiving asset(s)',
        icon: <Icon type="close-circle" style={{ color: 'red' }} />,
      });
    } finally {
      this.setState({ isArchivingAssets: false });
    }
  };

  handleCarIssuesToggle = (record, type) => {
    const newExpandedCarIds = [...this.state.expandedCarIds];
    const index = newExpandedCarIds.indexOf(record.id);
    if (index > -1) {
      newExpandedCarIds.splice(index, 1);
    } else {
      newExpandedCarIds.push(record.id);
    }

    if (type === 'pm') {
      const queryParams = new URLSearchParams(this.props.history.location.search);
      const hasRoutineSource = queryParams.get('source');
      if(!hasRoutineSource) {
        if (this.mapIssuesTable[record.id]) {
          this.mapIssuesTable[record.id].setState({
            source: 'routine',
          })
          this.mapIssuesTable[record.id].allIssuesTableStore.reset();
        }
        this.setQueryToRoutineQueryParams();
      }
    } else {
      const queryParams = new URLSearchParams(this.props.history.location.search);
      const hasRoutineSource = queryParams.get('source');
      if(hasRoutineSource) {
        if (this.mapIssuesTable[record.id]) {
          this.mapIssuesTable[record.id].setState({
            source: 'all',
          })
          this.mapIssuesTable[record.id].allIssuesTableStore.reset();
        }
        this.removeQueryToRoutineQueryParams();
      }
    }
    this.setState({ expandedCarIds: newExpandedCarIds });
  };

  convertQueryStringToObject = (queryString) => {
    const params = new URLSearchParams(queryString);
    const shopId = ShopStore.currentShop.id;
    const result = { offset:0, shopId };
  
    for (const [key, value] of params.entries()) {
      if (value) {
        // Convert comma-separated values into arrays, or single values as strings
        result[key] = value.includes(',') ? value.split(',') : value;
      }
    }
  
    if (result.dvirStatus) {
      result.dvirStatus = Array.isArray(result.dvirStatus)
        ? result.dvirStatus
        : [result.dvirStatus];
    }

    if (result.car_status) {
      result.car_status = Array.isArray(result.car_status)
        ? result.car_status
        : [result.car_status];
    }

    if (result.nextPmFilter) {
      result.nextPmFilter = Array.isArray(result.nextPmFilter)
        ? result.nextPmFilter
        : [result.nextPmFilter];
    }
  
    return result;
  };
  
  handleExportCSV = async () => {

    if (this.state.selectedAssets.length === 0) {
      console.log("No items selected");
      return;
    }

    // console.log("Exporting URL:", this.state.vehiclesApiUrl);
    
    
    const { selectedAssets, currentVehicles, paginationMeta } = this.state;
    const pageTotal = paginationMeta.total;
  

    const params = this.convertQueryStringToObject(this.state.vehiclesApiUrl);
    params.limit = pageTotal;

    if (pageTotal > 10) {
      if (selectedAssets.length === 10) {
        try {
          this.setState( { isExportingCSV: true})
          const result = await CarStore.getCarsForCSV(params);
          const csvContent = this.convertToCSV(result.data);
          this.downloadCSV(csvContent);
        } catch(error) {
          console.log(error);
        } finally {
          this.setState( { isExportingCSV: false})
        }

      } else {
        console.log(`Start exporting without calling Api!`);
        this.startExportCSVWithoutCallingAPI(currentVehicles, selectedAssets)
      }
    } else {
      console.log(`Start exporting without calling Api!`);
      this.startExportCSVWithoutCallingAPI(currentVehicles, selectedAssets)
    }
  };

  handleExportTrailerCSV = async () => {
    if (this.state.selectedAssets.length === 0) {
      console.log("No trailers selected");
      return;
    }
  
    console.log(`exporting trailers...`);
    console.log("Exporting URL:", this.state.vehiclesApiUrl);
    
    const { selectedAssets, currentTrailers, paginationMetaTrailer } = this.state;
    const pageTotal = paginationMetaTrailer.total;
  
    const params = this.convertQueryStringToObject(this.state.vehiclesApiUrl);
    params.limit = pageTotal;
  
    if (pageTotal > 10) {
      if (selectedAssets.length === 10) {
        try {
          this.setState({ isExportingCSV: true})
          params.trailers = true;
          const result = await CarStore.getCarsForCSV(params);
          const csvContent = this.convertToTrailerCSV(result.data);
          this.downloadCSV(csvContent);
        } catch (error) {
          console.log(error);
        } finally {
          this.setState({ isExportingCSV: false });
        }
      } else {
        console.log(`Start exporting selected trailers without calling API!`);
        this.startExportCSVWithoutCallingAPITrailers(currentTrailers, selectedAssets, this.convertTrailerToCSV);
      }
    } else {
      console.log(`Start exporting selected trailers without calling API!`);
      this.startExportCSVWithoutCallingAPITrailers(currentTrailers, selectedAssets, this.convertTrailerToCSV);
    }
  };  

  startExportCSVWithoutCallingAPITrailers = (currentVehicles, selectedAssets) => {
    const selectedData = currentVehicles.filter(car => 
      selectedAssets.includes(car.id)
    );
  
    const csvContent = this.convertToTrailerCSV(selectedData);
  
    this.downloadCSV(csvContent);
  }

  startExportCSVWithoutCallingAPI = (currentVehicles, selectedAssets) => {
    const selectedData = currentVehicles.filter(car => 
      selectedAssets.includes(car.id)
    );
  
    const csvContent = this.convertToCSV(selectedData);
  
    this.downloadCSV(csvContent);
  }
  
  convertToTrailerCSV = (data) => {
    const headers = ["Trailer Name | Mileage | Engine Hours", "DVIR Status", "Next PM"];
    // Determine whether to use kilometers or miles
    const userSettings = CurrentUserStore.user?.settings?.odometer === 'km' ? 'km' : 'miles';
    
    const rows = data.map((car) => {
      const totalKmMi = userSettings === 'km' 
        ? `${Math.round(Number(car.totalMileage))} km` 
        : `${(Number(car.totalMileage) * 0.621371).toFixed(2)} mi`;
  
      const engineHours = Math.round((car.engineHours / 3600));
      let carName = car.carName || "--";
      carName = `${carName} | ${totalKmMi} | ${engineHours} engine hours`;
  
      // Handle DVIR status
      let safetyStatus = car.dvir && car.dvir.record 
        ? car.dvir.record.safetyStatus || "Unknown" 
        : "--";
      const totalDefects = car.dvir && car.dvir.record?.payload?.trailerDefects 
        ? car.dvir.record.payload.trailerDefects.length 
        : 0;
  
      if (totalDefects > 0) {
        safetyStatus = `${safetyStatus} | Defects: ${totalDefects}`;
      }
  
      // Handle Next PM
      const { due_engine_hours, due_mileage, due_time_in_s, rule_type, pm_percent, pm_status } = car;
      let nextPM = "--"; // Default value when no PM data is available
  
      if (pm_percent !== null && pm_percent !== undefined) {
        let displayValue;
        let displayText;
  
        if (rule_type === 'time') {
          // Calculate time remaining in days or months
          const now = new Date();
          const dueAt = moment.unix(due_time_in_s);
          const diffInDays = dueAt.diff(now, 'days');
          const diffInMonths = Math.ceil(diffInDays / 30.4375);
  
          if (diffInMonths < 1) {
            displayValue = Math.abs(diffInDays);
            displayText = 'day(s)';
          } else {
            displayValue = Math.abs(diffInMonths);
            displayText = 'month(s)';
          }
        } else {
          // Calculate remaining mileage or engine hours
          const hoursRemainingValue = rule_type === 'engine_hours'
            ? Math.ceil(Math.abs((Number(due_engine_hours) - Number(car.engineHours)) / 3600))
            : undefined;
  
          const kmRemaining = rule_type === 'mileage'
            ? Math.ceil(Math.abs(Number(due_mileage) - Number(car.totalMileage)))
            : undefined;
  
          const milesRemaining = kmRemaining ? Math.ceil(kmRemaining / 1.60934) : undefined;
          const milesOrKm = userSettings === 'km' ? kmRemaining : milesRemaining;
          const milesOrKmText = userSettings === 'km' ? 'km' : 'miles';
  
          displayText = milesOrKm !== undefined ? milesOrKmText : 'engine hours';
          displayValue = milesOrKm !== undefined ? milesOrKm : hoursRemainingValue;
        }
  
        nextPM = `${pm_percent}% (${pm_status} by ${displayValue} ${displayText})`;
      }
  
      return [carName, safetyStatus, nextPM];
    });
  
    const csvArray = [headers, ...rows];
    return csvArray.map((e) => e.join(",")).join("\n");
  };
  

  convertToCSV = (data) => {
    const headers = ["Car Name | Mileage | Engine Hours", "DVIR Status", "Engine Light Status", "Issues", "Next PM", "Car Status, Notes"];
    // Determine whether to use kilometers or miles
    const userSettings = CurrentUserStore.user?.settings?.odometer === 'km' ? 'km' : 'miles';
    
    const rows = data.map((car) => {
      const totalKmMi = userSettings === 'km' ? `${Math.round(Number(car.totalMileage))} km` : `${(Number(car.totalMileage) * 0.621371).toFixed(2)} mi`;
      const engineHours = Math.round((car.engineHours / 3600));
      const notes = car.notes || "--";
      let carName = car.carName || "--";
      carName = `${carName} | ${totalKmMi} | ${engineHours} engine hours`;
  
      // Handle DVIR status
      let safetyStatus = car.dvir && car.dvir.record 
        ? car.dvir.record.safetyStatus || "Unknown" 
        : "--";
      const totalDefects = car.dvir && car.dvir.record && car.dvir.record.payload?.vehicleDefects ? car.dvir.record.payload.vehicleDefects.length : 0;
      if (totalDefects > 0) {
        safetyStatus = `${safetyStatus} | Defects: ${totalDefects}`;
      }
  
      // Handle Engine Light Status
      const engineLightStatus = car.currentEngineLightStatus?.payload
        ? [
            car.currentEngineLightStatus.payload.faultState === "active" ? "Active" : "Inactive",
            car.currentEngineLightStatus.payload.redStopLamp ? "Red Stop Lamp: On" : "",
            car.currentEngineLightStatus.payload.malfunctionLamp ? "Malfunction Lamp: On" : "",
            car.currentEngineLightStatus.payload.amberWarningLamp ? "Amber Warning Lamp: On" : "",
            car.currentEngineLightStatus.payload.protectWarningLamp ? "Protect Warning Lamp: On" : "",
            car.currentEngineLightStatus.payload.generalVehicleWarningLight ? "General Warning: On" : ""
          ].filter((status) => status !== "").join("; ")
        : "--";
  
      // Handle Issues
      const issueCounts = car.issueCounts 
        ? `Critical: ${car.issueCounts.critical || 0} | Major: ${car.issueCounts.major || 0} | Minor: ${car.issueCounts.minor || 0}` 
        : "No Issues";
  
      // Handle Next PM
      let nextPM = "--";
      const { due_engine_hours, due_mileage, due_time_in_s, rule_type, pm_percent, pm_status } = car;
  
      if (pm_percent !== null && pm_percent !== undefined) {
        let displayValue;
        let displayText;
  
        if (rule_type === 'time') {
          const now = new Date();
          const dueAt = moment.unix(due_time_in_s);
          const diffInDays = dueAt.diff(now, 'days');
          const diffInMonths = Math.ceil(diffInDays / 30.4375);
  
          if (diffInMonths < 1) {
            displayValue = Math.abs(diffInDays);
            displayText = 'day(s)';
          } else {
            displayValue = Math.abs(diffInMonths);
            displayText = 'month(s)';
          }
        } else {
          const hoursRemainingValue = rule_type === 'engine_hours'
            ? Math.ceil(Math.abs((Number(due_engine_hours) - Number(car.engineHours)) / 3600))
            : undefined;
  
          const kmRemaining = rule_type === 'mileage'
            ? Math.ceil(Math.abs(Number(due_mileage) - Number(car.totalMileage)))
            : undefined;
  
          const milesRemaining = kmRemaining ? Math.ceil(kmRemaining / 1.60934) : undefined;
          const milesOrKm = userSettings === 'km' ? kmRemaining : milesRemaining;
          const milesOrKmText = userSettings === 'km' ? 'km' : 'miles';
  
          displayText = milesOrKm !== undefined ? milesOrKmText : 'engine hours';
          displayValue = milesOrKm !== undefined ? milesOrKm : hoursRemainingValue;
        }
  
        nextPM = `${pm_percent}% (${pm_status} by ${displayValue} ${displayText})`;
      }
  
      // Handle Car Status
      const carStatus = this.getCarStatus(car.carStatus) || "Running";
  
      return [carName, safetyStatus, engineLightStatus, issueCounts, nextPM, carStatus, notes]; // Include Car Status in the return value
    });
  
    const csvArray = [headers, ...rows];
    return csvArray.map((e) => e.join(",")).join("\n");
  };
  
  getCarStatus= (carStatus) => {
    if(carStatus === 'new-1' || carStatus === 'new') {
      return 'Running';
    }

    return carStatus;
  }
  
  downloadCSV = (csvContent) => {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    const now = new Date();
    const timestamp = now.toISOString().replace(/[-:T]/g, '').split('.')[0];
    
    const filename = `exported_vehicles_data_${timestamp}.csv`;
    
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };
  

  render() {
    const { shouldShowButton } = this.state;
    const statusOptions = [
      { label: 'New', value: 'new' },
      { label: 'Waiting For Invoice', value: 'waitingForApproval' },
      { label: 'Done', value: 'done' },
    ];

    const sourceOptions = [
      { label: 'Engine Codes', value: 'dtc' },
      { label: 'Routine/OEM Recommended Services', value: 'routine' },
      { label: 'Recall', value: 'recall' },
      { label: 'Fleet Manager/Driver Reported', value: 'user' },
      { label: 'Pitstop Algorithm Generated Services', value: 'algorithm' },
      { label: 'Service Records', value: 'archive' },
      { label: 'Light Vehicle DTCs', value: 'lightVehicleDTC' },
      { label: 'Heavy Vehicle DTCs', value: 'heavyVehicleDTC' },
    ];

    const rowSelection = {
      selectedRowKeys: this.state.selectedAssets,
      onChange: (selectedRowKeys) => {
        this.setState({ selectedAssets: selectedRowKeys });
      },
    };

    return (
      <IndicatorTableWrapper>
        {this.props.type === 'vehicles' ? (
          <>
            <Row style={{ marginBottom: 10 }} gutter={[16, 16]}>
              <Col span={6}>
                <Search
                  placeholder="Search Vehicle ID"
                  onChange={(event) => {
                    this.setState({
                      searchValue: event.target.value,
                      searchKey: 'carName',
                    });
                  }}
                />
              </Col>
              {// only show notification if it hasn't been viewed yet
              localStorage.getItem('viewedIssuesNotification') !== 'true' && (
                <Col span={18}>
                  <NotificationBanner
                    message={
                      <>
                        <Text strong>
                          How you{' '}
                          <Text
                            strong
                            style={{ fontWeight: 'bold', color: 'black' }}
                          >
                            view issues
                          </Text>{' '}
                          has changed!
                        </Text>
                        <br />
                        <Text>
                          To view issues associated with an asset, click “Show
                          Issues” drop down in the Issues column. To hide those
                          issues, click “Hide Issues”
                        </Text>
                      </>
                    }
                    onClose={() => {
                      // save to local storage that the notification was already shown
                      localStorage.setItem('viewedIssuesNotification', 'true');
                    }}
                  />
                </Col>
              )}
            </Row>

            {/* Vehicle Filter badges */}
            <Row style={{ marginBottom: 10 }} gutter={[16, 16]}>
              <Col span={24}>
                <Button
                  type="primary"
                  size="small"
                  disabled={this.state.selectedAssets.length === 0}
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    this.setState({
                      isArchiveAssetsModalVisible: true,
                      archivingAssetType: 'vehicles',
                    });
                  }}
                  data-test="archive-vehicles-button"
                >
                  Archive Assets
                </Button>
                <Button
                  type="primary"
                  size="small"
                  disabled={this.state.selectedAssets.length === 0}
                  style={{ marginRight: 10 }}
                  onClick={this.handleExportCSV}
                  data-test="export-csv-button"
                >
                  Export CSV
                </Button>

                <Modal
                  title="Exporting CSV..."
                  visible={this.state.isExportingCSV}
                  footer={null}
                  closable={false}
                  centered
                >
                  <div style={{ textAlign: 'center' }}>
                    <Spin size="large" />
                    <p>Processing your CSV export. Please wait...</p>
                  </div>
                </Modal>

                {this.combinedCurrentFilters.length === 0 ? (
                  <Button disabled size="small" style={{ marginRight: 10 }}>
                    No filters selected
                  </Button>
                ) : (
                  <Button
                    size="small"
                    type="primary"
                    style={{ marginRight: 10 }}
                    onClick={() => this.onClearAllFilters()}
                  >
                    Clear all filters
                  </Button>
                )}
                {/* Add badges with current selected filters here */}
                {this.combinedCurrentFilters.map((filter) => {
                  return (
                    <Tag
                      key={filter.key}
                      className="filter-tag"
                      closable
                      onClose={() => this.onCloseFilter(filter)}
                    >
                      {filter.label}
                    </Tag>
                  );
                })}
              </Col>
            </Row>

            <TableContainer
              rowKey="id"
              data-test="vehicles-table"
              columns={this.vehicleColumns}
              dataSource={this.state.currentVehicles}
              rowSelection={rowSelection}
              loading={this.state.isLoadingVehicles}
              pagination={this.state.paginationMeta}
              onChange={this.handleTableChange}
              expandedRowRender={(car) => (
                <div className="issues-table-wrapper">
                  <IssuesTable
                    refs={(ref) => {
                      this.mapIssuesTable[car.id] = ref;
                    }}
                    isEnabled={!_.isNil(car.id)}
                    id={`car-${car.id}`}
                    fetchData={(params, store) => {
                      IssueStore.getIssuesForCarId(
                        {
                          ...params,
                        },
                        store,
                        car.id
                      );
                    }}
                    onIssuesListChange={() => {
                      this.handleSearchChangeAndLoadVehicles();
                    }}
                    page="carPage"
                  />
                </div>
              )}
              expandedRowKeys={this.state.expandedCarIds}
              expandIconAsCell={false}
              expandIconColumnIndex={-1}
              onExpand={(expanded, record) =>
                this.handleCarIssuesToggle(record)
              }
              components={{
                header: {
                  cell: CustomHeaderCell,
                },
              }}
            />
          </>
        ) : (
          <>
            <Row style={{ marginBottom: 10 }} gutter={[16, 16]}>
              <FlexCol span={8}>
                <Search
                  placeholder="Search Trailer ID"
                  onChange={(event) => {
                    this.setState({
                      searchTrailerValue: event.target.value,
                      searchTrailerKey: 'carName',
                    });
                  }}
                />

                {shouldShowButton && (
                  <Button
                    style={{ marginLeft: '1rem' }}
                    onClick={() => {
                      // open modal to add trailer
                      AppStore.openModals.set('ADD_ASSET_MODAL', true);
                    }}
                  >
                    <Icon type="plus" />
                    Add Trailer
                  </Button>
                )}
              </FlexCol>
            </Row>

            <Row style={{ marginBottom: 10 }} gutter={[16, 16]}>
              <Col span={24}>
                <Button
                  type="primary"
                  size="small"
                  disabled={this.state.selectedAssets.length === 0}
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    this.setState({
                      isArchiveAssetsModalVisible: true,
                      archivingAssetType: 'trailers',
                    });
                  }}
                  data-test="archive-trailers-button"
                >
                  Archive Assets
                </Button>

                <Button
                  type="primary"
                  size="small"
                  disabled={this.state.selectedAssets.length === 0}
                  style={{ marginRight: 10 }}
                  onClick={this.handleExportTrailerCSV}
                  data-test="export-csv-button"
                >
                  Export CSV
                </Button>

                <Modal
                  title="Exporting CSV..."
                  visible={this.state.isExportingCSV}
                  footer={null}
                  closable={false}
                  centered
                >
                  <div style={{ textAlign: 'center' }}>
                    <Spin size="large" />
                    <p>Processing your CSV export. Please wait...</p>
                  </div>
                </Modal>

                {this.combinedCurrentTrailerFilters.length === 0 ? (
                  <Button disabled size="small" style={{ marginRight: 10 }}>
                    No filters selected
                  </Button>
                ) : (
                  <Button
                    size="small"
                    type="primary"
                    style={{ marginRight: 10 }}
                    onClick={() => this.onClearAllTrailerFilters()}
                  >
                    Clear all filters
                  </Button>
                )}
                {/* Add badges with current selected filters here */}
                {this.combinedCurrentTrailerFilters.map((filter) => {
                  return (
                    <Tag
                      key={filter.key}
                      className="filter-tag"
                      closable
                      onClose={() => this.onCloseTrailerFilter(filter)}
                    >
                      {filter.label}
                    </Tag>
                  );
                })}
              </Col>
            </Row>

            <TableContainer
              rowKey="id"
              data-test="trailers-table"
              columns={this.trailerColumns}
              dataSource={this.state.currentTrailers}
              loading={this.state.isLoadingTrailers}
              pagination={this.state.paginationMetaTrailer}
              onChange={this.handleTableTrailerChange}
              rowSelection={this.vehicleRowSelection}
              expandedRowRender={(car) => (
                <div className="issues-table-wrapper">
                  <IssuesTable
                    isEnabled={!_.isNil(car.id)}
                    id={`car-${car.id}`}
                    fetchData={(params, store) => {
                      IssueStore.getIssuesForCarId(
                        {
                          ...params,
                        },
                        store,
                        car.id
                      );
                    }}
                    page="carPage"
                  />
                </div>
              )}
              expandedRowKeys={this.state.expandedCarIds}
              expandIconAsCell={false}
              expandIconColumnIndex={-1}
              onExpand={(expanded, record) =>
                this.handleCarIssuesToggle(record)
              }
              components={{
                header: {
                  cell: CustomHeaderCell,
                },
              }}
            />

            <AddAssetModal
              visible={AppStore.openModals.get('ADD_ASSET_MODAL') || false}
              type="trailer"
              onOk={this.onAddTrailers}
              onCancel={() => {
                AppStore.openModals.set('ADD_ASSET_MODAL', false);
              }}
            />
          </>
        )}

        <PitstopModal
          okButtonProps={{ loading: this.state.isSavingStatuses }}
          onOk={this.saveNewShopStatuses}
          onCancel={() => {
            AppStore.openModals.set('SHOP_STATUSES_MODAL', false);
          }}
          title="Edit Status Options"
          width={800}
          id="SHOP_STATUSES_MODAL"
          style={{ maxWidth: '35%' }}
          isManualClose
        >
          <Text type="secondary" style={{ marginBottom: 5 }}>
            <span
              style={{
                fontWeight: 'bold',
                color: 'red',
                marginRight: 5,
              }}
            >
              *
            </span>
            Changes to these options will adjust all affected fields
          </Text>
          <List
            itemLayout="horizontal"
            dataSource={this.state.newShopCarStatuses}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <input
                      type="color"
                      id={`color-picker-${item.key}`}
                      name={`color-picker-${item.key}`}
                      value={item.color}
                      onChange={(e) => {
                        item.color = e.target.value;
                        this.setState({
                          newShopCarStatuses: this.state.newShopCarStatuses,
                        });
                      }}
                      style={{
                        border: 0,
                        padding: 0,
                      }}
                    />
                  }
                  title={
                    item.description ? (
                      <input
                        placeholder="Insert new status name..."
                        id={`description-${item.key}`}
                        value={item.description}
                        style={{
                          border: 0,
                          width: '100%',
                        }}
                        onChange={(e) => {
                          item.description = e.target.value;
                          this.setState({
                            newShopCarStatuses: this.state.newShopCarStatuses,
                          });
                        }}
                      />
                    ) : (
                      <input
                        id={`description-${item.key}`}
                        placeholder="Insert new status name..."
                        style={{
                          border: 0,
                          width: '100%',
                        }}
                        onChange={(e) => {
                          item.description = e.target.value;
                          this.setState({
                            newShopCarStatuses: this.state.newShopCarStatuses,
                          });
                        }}
                      />
                    )
                  }
                />
                {item.is_default && (
                  <Text style={{ marginRight: '0.5rem' }}>Set as default</Text>
                )}
                <Tooltip
                  title={item.is_default ? 'Remove default' : 'Set as default'}
                >
                  <Checkbox
                    checked={item.is_selected}
                    value={item.key}
                    onChange={(event) => {
                      this.selectStatusAsDefault(event, item.key);
                    }}
                    style={{
                      marginRight: '1rem',
                    }}
                  />
                </Tooltip>
                <Popconfirm
                  placement="right"
                  title={'Are you sure you want to delete this status?'}
                  onConfirm={() => {
                    // remove item from array
                    this.setState(
                      {
                        newShopCarStatuses: this.state.newShopCarStatuses.filter(
                          (status) => status.key !== item.key
                        ),
                      },
                      () => {
                        // if there's no default status, set the first one as default
                        if (
                          !_.some(this.state.newShopCarStatuses, {
                            is_default: true,
                          })
                        ) {
                          this.setState({
                            newShopCarStatuses: this.state.newShopCarStatuses.map(
                              (status, index) => {
                                if (index === 0) {
                                  status.is_selected = true;
                                  status.is_default = true;
                                }
                                return status;
                              }
                            ),
                          });
                        }
                      }
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger" key="1" icon="delete" />
                </Popconfirm>
              </List.Item>
            )}
          />
          <div style={{ marginTop: 10 }}>
            <Button
              icon="plus"
              type="primary"
              ghost
              onClick={() => {
                this.state.newShopCarStatuses.push({
                  key: `new-${this.state.newShopCarStatuses.length + 1}`,
                  color: '#CFCFCF',
                });
                this.setState(
                  { newShopCarStatuses: this.state.newShopCarStatuses },
                  () => {
                    // focus on the last item
                    setTimeout(() => {
                      document
                        .getElementById(
                          `description-new-${this.state.newShopCarStatuses.length}`
                        )
                        .focus();
                    }, 100);
                  }
                );
              }}
            >
              Create New Status
            </Button>
          </div>
        </PitstopModal>

        <PitstopModal
          id={'HEALTH_REPORT_MODAL_ID'}
          title=""
          width={700}
          onOk={() => {
            AppStore.openModals.set('HEALTH_REPORT_MODAL_ID', false);
            if (this.state.isPrintHealthReport) {
              return this.onPrintVehicleHealthReport();
            }
            this.onDownloadVehicleHealthReport();
          }}
          onCancel={() =>
            AppStore.openModals.set('HEALTH_REPORT_MODAL_ID', false)
          }
        >
          <Row>
            <h3>Please select report's format:</h3>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <b>Issues Status</b>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={this.state.issueReportStatus}
                onChange={(values) => {
                  this.setState({ issueReportStatus: values });
                }}
              >
                <Row>
                  {_.map(statusOptions, (status) => (
                    <Col key={status.value} span={8}>
                      <Checkbox value={status.value}>{status.label}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Col>

            <Col span={24}>
              <b>Issues Sources</b>
              <Checkbox.Group
                style={{ width: '100%' }}
                value={this.state.issueSources}
                onChange={(values) => {
                  this.setState({ issueSources: values });
                }}
              >
                <Row>
                  {_.map(sourceOptions, (source) => (
                    <Col key={source.value} span={12}>
                      <Checkbox value={source.value}>{source.label}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Col>
          </Row>
        </PitstopModal>

        <PitstopModal
          okButtonProps={{ style: { display: 'none' } }}
          onOk={() => {}}
          onCancel={() => {
            AppStore.openModals.set('SHARE_VEHICLE_HEALTH_REPORT_MODAL', false);
          }}
          title=""
          width={800}
          id="SHARE_VEHICLE_HEALTH_REPORT_MODAL"
        >
          <ShareVehicleHealthReportPage
            selectedCarId={this.state.selectedCarId}
          />
        </PitstopModal>

        <ArchiveAssetModal
          visible={this.state.isArchiveAssetsModalVisible}
          isArchiving={this.state.isArchivingAssets}
          onCancel={() => {
            this.setState({ isArchiveAssetsModalVisible: false });
          }}
          onOk={(notes) => {
            this.handleArchiveAssets(notes);
          }}
        />
      </IndicatorTableWrapper>
    );
  }
}

decorate(VehiclesTable, {
  tableStore: observable,
  refreshTableOffset: observable,
  shopId: computed,
});

export default withRouter(observer(VehiclesTable));
