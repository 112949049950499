import React from 'react';
import styled from 'styled-components';

const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;

  .legend-line {
    flex-grow: 1;
    height: 1px;
    background-color: #0038b3;

    &.first {
      flex-grow: 0.03;
    }
  }

  .legend-text {
    color: #333;
    font-size: 1rem !important;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

interface IProps {
  children: React.ReactNode;
}

const FormLegend: React.FC<IProps> = ({ children }) => (
  <Legend>
    <div className="legend-line first"></div>
    <div className="legend-text">{children}</div>
    <div className="legend-line"></div>
  </Legend>
);

export default FormLegend;
