import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate } from 'mobx';
import PropTypes from 'prop-types';
import _, { debounce } from 'lodash';

import { WorkOrderStore } from 'stores';
import {
  Col,
  Row,
  Typography,
  Input,
  Form,
  Button,
  Select,
  Spin,
  Popconfirm,
} from 'antd';

import WorkOrderLineItemLabors from '../Labors/WOLineItemLabors';
import WorkOrderLineItemParts from '../Parts/WOLineItemParts';
import WorkOrderLineItemIssues from '../Issues/WOLineItemIssues';
import TotalAmount from 'components/TotalAmount';
import FormLegend from 'components/Forms/FormLegend';
import WorkOrderLineItemContext from './WorkOrderLineItemContext';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

class WorkOrderLineItemDetails extends Component {
  static contextType = WorkOrderLineItemContext;

  constructor(props) {
    super(props);
    this.lastFetchSystemCodeId = 0;
    this.fetchSystemCode = debounce(this.fetchSystemCode, 800);

    this.lastFetchAssemblyCodeId = 0;
    this.fetchAsmCodesForSysCode = debounce(this.fetchAsmCodesForSysCode, 800);
  }

  static propTypes = {
    childRef: PropTypes.func,
    onChangeDescription: PropTypes.func,
    onChangeField: PropTypes.func,
    onAddNewPart: PropTypes.func.isRequired,
    onUpdateLaborItem: PropTypes.func.isRequired,
    onUpdatePartItem: PropTypes.func.isRequired,
    onUpdateServiceItem: PropTypes.func.isRequired,
    onRemoveLineItem: PropTypes.func.isRequired,
    onSelectSystemCode: PropTypes.func,
    onSelectAsmCode: PropTypes.func,
  };

  state = {
    currentServiceTaskOnSearch: '',
    fetchingSystemCode: false,
    currentSystemCodeOptions: [],
    fetchingAssemblyCode: false,
    systemCodeAsms: [],
    currentAssemblyCodeOptions: [],
  };

  shouldDisableLaborCost = false;
  shouldDisablePartsCost = false;

  laborsRef = null;
  partsRef = null;
  issuesRef = null;

  get localLineItem() {
    const { lineItem } = this.context;
    return lineItem;
  }

  get labours() {
    return this.localLineItem?.labours || [];
  }

  get parts() {
    return this.localLineItem?.parts || [];
  }

  get services() {
    return this.localLineItem?.services || [];
  }

  get laborTotal() {
    return _.sumBy(this.labours, (l) => l.hours * l.cost);
  }

  get partsTotal() {
    return _.sumBy(this.parts, (part) => {
      const cost = part.cost || 0;
      const markup = part.markup || 0;
      const amount = part.amount || 0;

      return (cost + (cost * markup) / 100) * amount;
    });
  }

  get lineSubtotal() {
    return (this.laborTotal || 0) + (this.partsTotal || 0);
  }

  get localSystemCode() {
    const vmrsSysCode = this.localLineItem?.vmrs_sys_code;
    const vmrsSysText = this.localLineItem?.vmrs_sys_text;
    if (vmrsSysCode && vmrsSysText) {
      return `${vmrsSysCode} - ${vmrsSysText}`;
    }
    return this.localLineItem?.vmrs_sys_code_id || null;
  }

  get localAssemblyCode() {
    const vmrsAsmCode = this.localLineItem?.vmrs_asm_code;
    const vmrsAsmText = this.localLineItem?.vmrs_asm_text;
    if (vmrsAsmCode && vmrsAsmText) {
      return `${vmrsAsmCode} - ${vmrsAsmText}`;
    }
    return this.localLineItem?.vmrs_asm_code_id || null;
  }

  get vmrsSystemCodeOptions() {
    return this.state.currentSystemCodeOptions.map(
      (systemCodeOption, index) => (
        <Option key={index} value={systemCodeOption.id}>
          {`${systemCodeOption.sys_text} - ${systemCodeOption.text}`}
        </Option>
      )
    );
  }

  get vmrsAssemblyCodeOptions() {
    return this.state.currentAssemblyCodeOptions.map(
      (assemblyCodeOption, index) => (
        <Option key={index} value={assemblyCodeOption.id}>
          {`${assemblyCodeOption.asm_text} - ${assemblyCodeOption.text}`}
        </Option>
      )
    );
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);

    // if parts is empty, add a new part by default
    if (!this.parts.length) {
      this.onAddExistingPart();
    }

    this.fetchSystemCode('', true);
  }

  fetchSystemCode = async (value, searchAll = false) => {
    this.lastFetchSystemCodeId += 1;
    const fetchId = this.lastFetchSystemCodeId;
    this.setState({ fetchingSystemCode: true });
    if (!value && !searchAll) {
      this.setState({ fetchingSystemCode: false });
      return;
    }
    const { data } = await WorkOrderStore.searchVmrs({
      value,
      type: 'system_code',
    });
    if (fetchId !== this.lastFetchSystemCodeId) {
      // for fetch callback order
      return;
    }
    this.setState({
      currentSystemCodeOptions: data,
      fetchingSystemCode: false,
    });
  };

  handleChangeSystemCode = (vmrsKeyId) => {
    this.setState({ fetchingSystemCode: false }, () => {
      this.fetchAsmCodesForSysCode(vmrsKeyId);

      if (this.props.onSelectSystemCode) {
        this.props.onSelectSystemCode(vmrsKeyId);
      }
    });
  };

  fetchAsmCodesForSysCode = async (vmrsKeyId) => {
    this.lastFetchAssemblyCodeId += 1;
    const fetchId = this.lastFetchAssemblyCodeId;
    this.setState({ fetchingAssemblyCode: true });
    if (!vmrsKeyId) {
      this.setState({ fetchingAssemblyCode: false });
      return;
    }
    const { data } = await WorkOrderStore.getAsmCodesForSysCode(vmrsKeyId);
    if (fetchId !== this.lastFetchAssemblyCodeId) {
      // for fetch callback order
      return;
    }
    this.setState({
      currentAssemblyCodeOptions: data,
      systemCodeAsms: data,
      fetchingAssemblyCode: false,
    });
  };

  filterAssemblyCodes = (value) => {
    const { systemCodeAsms } = this.state;
    const filteredAsmCodes = systemCodeAsms.filter((asm) => {
      return asm.text.toLowerCase().includes(value.toLowerCase());
    });
    this.setState({ currentAssemblyCodeOptions: filteredAsmCodes });
  };

  onChangeDescription = (event) => {
    const { value } = event.target;
    this.description = value;
    if (this.props.onChangeDescription) {
      this.props.onChangeDescription(event);
    }
    if (this.props.onChangeField) {
      this.props.onChangeField(value, 'description');
    }
  };

  onAddLabor = () => {
    const { onAddLabor } = this.context;
    onAddLabor(this.localLineItem.key);
  };

  onAddExistingPart = (existingPart = {}) => {
    const { onAddPart } = this.context;
    onAddPart(this.localLineItem.key, existingPart);
  };

  onAddIssue = () => {
    const { onAddIssue } = this.context;
    onAddIssue(this.localLineItem.key);
  };

  onRemoveLabor = (event, itemKey) => {
    const { onRemoveLabor } = this.context;
    onRemoveLabor(itemKey, this.localLineItem.key);
  };

  onRemovePart = (event, itemKey) => {
    const { onRemovePart } = this.context;
    onRemovePart(itemKey, this.localLineItem.key);
  };

  onRemoveIssue = (event, itemKey) => {
    const { onRemoveIssue } = this.context;
    onRemoveIssue(itemKey, this.localLineItem.key);
  };

  onAddItemClick = (itemRef) => {
    switch (itemRef) {
      case 'Labor':
        return this.onAddLabor();
      case 'Part':
        return this.onAddExistingPart();
      case 'Issue':
        return this.onAddIssue();
      default:
        return;
    }
  };

  onChangeField = (newVal, fieldName) => {
    this[fieldName] = newVal;

    if (this.props.onChangeField) {
      this.props.onChangeField(newVal, fieldName);
    }
  };

  onUpdatePartLineTotal = () => {
    if (this.props.onChangeField) {
      this.props.onChangeField(this.partsTotal, 'partsTotal');
    }
  };

  onSearchServiceTask = (value) => {
    this.setState({ currentServiceTaskOnSearch: value });
  };

  render() {
    const { shopUsers, shopParts, currentCarIssues, isDisabled } = this.context;

    return (
      <Form>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <FormLegend>
              {this.localLineItem.header}
              <Popconfirm
                placement="top"
                title={'Are you sure?'}
                onConfirm={() => this.props.onRemoveLineItem()}
                okText="Yes"
                cancelText="No"
              >
                <Button icon="delete" type="link" className="delete-danger" />
              </Popconfirm>
            </FormLegend>
          </Col>
          <Col xs={24} sm={12}>
            <Text strong>VMRs System Code:</Text>
            <Select
              value={this.localSystemCode}
              placeholder="Search"
              notFoundContent={
                this.state.fetchingSystemCode ? <Spin size="small" /> : null
              }
              filterOption={false}
              onSearch={this.fetchSystemCode}
              onChange={this.handleChangeSystemCode}
              showSearch
              disabled={isDisabled}
            >
              {this.vmrsSystemCodeOptions}
            </Select>
          </Col>
          <Col xs={24} sm={12}>
            <Text strong>VMRs Assembly Code:</Text>
            <Select
              value={this.localAssemblyCode}
              onChange={(value) => {
                if (this.props.onSelectAsmCode) {
                  this.props.onSelectAsmCode(value);
                }
              }}
              placeholder="Search"
              notFoundContent={
                this.state.fetchingAssemblyCode ? <Spin size="small" /> : null
              }
              filterOption={false}
              onSearch={this.filterAssemblyCodes}
              showSearch
              disabled={isDisabled}
            >
              {this.vmrsAssemblyCodeOptions}
            </Select>
          </Col>
          <Col xs={8} sm={8}>
            <Text strong>Labor Cost:</Text>
            <TotalAmount amount={this.laborTotal} />
          </Col>
          <Col xs={8} sm={8}>
            <Text strong>Parts Cost:</Text>
            <TotalAmount amount={this.partsTotal} />
          </Col>
          <Col xs={8} sm={8}>
            <Text strong>Subtotal:</Text>
            <TotalAmount amount={this.lineSubtotal} />
          </Col>
          <Col span={24}>
            <Text strong>Description:</Text>
            <TextArea
              rows={4}
              placeholder="Add Description"
              value={this.description}
              onChange={this.onChangeDescription}
              disabled={isDisabled}
            />
          </Col>
          <Col span={24}>
            <Button
              type="link"
              onClick={() => this.onAddIssue()}
              icon="plus-circle"
            >
              Link Existing Issues
            </Button>
            <Button
              type="link"
              onClick={() => this.onAddLabor()}
              icon="plus-circle"
              style={{ marginRight: '10px' }}
            >
              Add labor
            </Button>
            <Button
              type="link"
              onClick={() => this.onAddExistingPart()}
              icon="plus-circle"
              style={{ marginRight: '10px' }}
            >
              Add Existing Part
            </Button>
            <Button
              type="link"
              onClick={() => {
                if (this.props.onAddNewPart) {
                  this.props.onAddNewPart();
                }
              }}
              icon="plus-circle"
              style={{ marginRight: '10px' }}
            >
              Add New Part
            </Button>
          </Col>
          <Col span={24}>
            <WorkOrderLineItemLabors
              title="Labors (Technician)"
              description="Labor"
              items={this.labours}
              shopUsers={shopUsers}
              childRef={(ref) => (this.laborsRef = ref)}
              onAddItem={() => this.onAddItemClick('Labor')}
              onRemoveItem={this.onRemoveLabor}
              // onUpdateLineTotal={this.onUpdateLaborLineTotal}
              onUpdateLocalItem={this.props.onUpdateLaborItem}
              isDisabled={isDisabled}
            />
          </Col>
          <Col span={24}>
            <WorkOrderLineItemParts
              title="Parts"
              description="Parts"
              items={this.parts}
              shopParts={shopParts}
              childRef={(ref) => (this.partsRef = ref)}
              onAddItem={() => this.onAddItemClick('Part')}
              onRemoveItem={this.onRemovePart}
              // onUpdateLineTotal={this.onUpdatePartLineTotal}
              onAddNewPart={this.props.onAddNewPart}
              onUpdateLocalItem={this.props.onUpdatePartItem}
              isDisabled={isDisabled}
            />
          </Col>
          {!isDisabled && (
            <Col span={24}>
              <WorkOrderLineItemIssues
                title="Link issues"
                description="link issues"
                items={this.services}
                currentCarIssues={currentCarIssues}
                childRef={(ref) => (this.issuesRef = ref)}
                onAddItem={() => this.onAddItemClick('Issue')}
                onRemoveItem={this.onRemoveIssue}
                onUpdateLocalItem={this.props.onUpdateServiceItem}
              />
            </Col>
          )}
        </Row>
      </Form>
    );
  }
}

decorate(WorkOrderLineItemDetails, {});

export default observer(WorkOrderLineItemDetails);
